import React from 'react'


import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator   
 from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
 import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';   
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';


export default function Timeline_Demand(props) {
  console.log(props.data);
const [events, setEvents] = React.useState([]);
const [loading, setLoading] = React.useState(true);
if(loading){
    fetch(`https://api.fortesol.com.br/api/timeline/list/${props.data.id_demand}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.message === 'Server Error') return;
        
          setEvents(data);
        })
        setLoading(false);    
      }   
      const formatDate = (creation_date) => {
        const date = new Date(creation_date);
        const time = date.toLocaleTimeString();
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}
                 ${time.substr(0, 5)}`;
      }
        
      function gerarCorAleatoriaPorLetra(letra) {
        // Cria um valor numérico único baseado na letra
        const seed = letra.charCodeAt(0);
      
        // Gera um número aleatório com base no seed
        const random = Math.sin(seed) * 10000;
      
        // Converte o número aleatório em um valor entre 0 e 1
        const normalizedRandom = random - Math.floor(random);
      
        // Gera os componentes RGB da cor
        const r = Math.floor(normalizedRandom * 256);
        const g = Math.floor((normalizedRandom + 0.33) * 256) % 256;
        const b = Math.floor((normalizedRandom + 0.67) * 256) % 265;
      
        // Converte para hexadecimal e retorna
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      }
   
  return (
    <Timeline position="left">
  
    {events?.map((event) => (
      <TimelineItem key={event.id_timeline}>
        <TimelineSeparator>
          <TimelineDot color="primary">
            {/* Ícone do evento */}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Card sx={{ minWidth: 200 }}>
            <CardHeader
              title={formatDate(event.creation_date)}
              subheader={` Perfil: ${event.name_user} ${event.event} `}
              avatar={
                <Avatar sx={{ bgcolor: gerarCorAleatoriaPorLetra(event.name_user[0]) }}>
                  {event.name_user[0]}
                </Avatar>
              }
            />
            <CardContent sx={{ fontSize: 15 }}>
              {event.details}
            </CardContent>
          </Card>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Timeline>
  )
}
