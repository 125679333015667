import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import New_Edit_User from '../new_edit_user_customer';
import DropDialog from '../dropDialog';
import New_Edit_User_employee from '../new_edit_user_employee';
export default function Details_Employee(props) {
 

 




    const [user, setUser] = useState('')

    const formatDataAndTime = (date) => {
            const dateFormated = new Date(date)
            return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
    }


    const [openUser, setOpenUser] = useState(false)
    const [title, setTitle] = useState('')
    const [data, setData] = useState({})
    const [typeUser, setTypeUser] = useState('')
    const handleOpenUser = (title, data,type) => {
        setTitle(title)
        setData(data)
        setTypeUser(type)
        setOpenUser(true)
    }
    const closeUser = () => {
        setOpenUser(false)
    }

    const [openDrop, setOpenDrop] = useState(false)
    const handleOpenDrop = () => {
        setOpenDrop(true)
    }
    const closeDrop = () => {
        setOpenDrop(false)
    }

    const handleDelete = () => {
        try {
            fetch(`https://api.fortesol.com.br/api/users/delete/${user.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },}).then((response) => response.json())
                .then((data) => {
                   
                    if(data.message === 'User deleted successfully'){
                        closeDrop()
                    }
                }).catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }





    useEffect(() => {
        try {
            fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id_employee}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },}).then((response) => response.json())
                .then((data) => {
                    setUser(data[0])
                }).catch((error) => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error)
            }
     
        }
    , [props,openUser,openDrop])
  
  return (


    <Dialog 
    open={props.openDetails}
    onClose={props.closeDetails}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="md"
    fullWidth={true}
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title">Detalhes do Funcionário</DialogTitle>
        <DialogActions>
       {
       user?.id ?
       (
        <> 
        <Button size='small' variant='contained' color='primary' onClick={() => handleOpenUser('Editar Usuário', user, 'customer')}>Editar Usuário</Button>
        <Button size='small' variant='contained' color='error' onClick={handleOpenDrop}>Excluir Usuário</Button>
        </>
       ):
       <Button size='small' variant='contained' color='primary' onClick={() => handleOpenUser('Novo Usuário', props.data, 'customer')}>Adicionar Usuário</Button>
        }</DialogActions>
    <New_Edit_User_employee openUser={openUser} closeUser={closeUser} title={title} data={data} type={typeUser} />
    <DropDialog openDrop={openDrop} closeDrop={closeDrop} title='Excluir Usuário' msg='Deseja realmente excluir este usuário?' delete={handleDelete} />

     
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nome</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CPF</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">RG</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Contato</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Email</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Estado Civil</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nacionalidade</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Cargo</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Departamento</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.name_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cpf_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.rg_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.contact_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.civil_state_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.nationality_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.name_role}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.name_departments}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CEP</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Rua</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Número</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Bairro</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Cidade</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Estado</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Complemento</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cep_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.street_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.number_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.neighborhood_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.city_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.state_employee}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.complement_employee}</TableCell>
                </TableRow>
              </TableBody>
              </Table>
              
        

        <DialogActions>
            <Button onClick={props.closeDetails} autoFocus>
                Fechar
            </Button>
        </DialogActions>
</DialogContent>
    </Dialog>

  )
}
