
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import './style.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
} from '@mui/material';
import { Link } from 'react-router-dom';
import New_Edit_Customer from '../new_edit_customer';


function ListSelectCustomer(props) {


  
  const [customer, setCustomer] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchCustomer = async () => {
      setLoading(true);
      try {
        fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {

          //fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            setCustomer(data[0]);
            setTotalPages(data[1]);
           
          })

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [currentPage, itemsPerPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação





  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#004e64';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#004e64';
      item.style.border = '2px solid #004e64';
    }

  })





  const searchCustomer = () => {

    let value = document.querySelector('.search-demand').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`https://api.fortesol.com.br/api/customers/search?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setCustomer(data[0]);
          setTotalPages(data[1]);

        })
      return
    }
    else {
      fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {
        //fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((data) => {
          setCustomer(data[0]);
          setTotalPages(data[1]);
        })
    }
  }


  const [doc_proxy, setDoc_proxy] = useState(null)


  const [typeCustomer, setTypeCustomer] = useState('')
    const [data, setData] = useState('')
    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const handleClickOpenNewCustomer = () => {
      setData('')
      setTitle('new')
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

  return (


    <Dialog
      open={props.open}
      onClose={props.handleClose}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='1200px'
      sx={{ ml: 0 }}
    >
      <DialogTitle id="alert-dialog-title">{"Lista de Clientes"}</DialogTitle>
      <DialogContent>

        <div style={{ minWidth: '1100px' }}>
          <Grid  >
            <div id='list-select-customer'>
              <div className='project-solar'>
       
  
                <br />
                <div style={{ display: 'flex', padding: '0 00px 0 0px', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', paddingLeft: '0px' }}>
                    <div style={{ display: 'flex' }}>
                      <input type="text" className='search-demand' placeholder='Pesquisar'

                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            searchCustomer()
                          }
                        }
                        }
                        onChange={(e) => {


                          if (e.target.value === '') {
                            fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {
                              //fetch(`https://api.fortesol.com.br/api/customers/list?page=${currentPage}&limit=${itemsPerPage}`, {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            }).then((response) => response.json())
                              .then((data) => {
                                setCustomer(data[0]);
                                setTotalPages(data[1]);
                              })
                          }


                        }} />
                      <button id='bt-search' onClick={searchCustomer}>Pesquisar</button>
                    </div>

                  </div>
                  <button style={{ background: '#218380' }} onClick={handleClickOpenNewCustomer} > Novo Cliente</button>
                </div>
                <New_Edit_Customer open={open} handleClose={handleClose} title={title} data={data} setData={setData} setType={setTypeCustomer} type={typeCustomer} />
                <div id='tableDemands' style={{
                  padding: '0 0px 0 00px',
                }}>
                  {

                    (
                      <>

                        <Paper>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ p: '5px', fontSize: 16 }}align="center">Nome</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 16 }}align="center">CPF/CNPJ</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 16 }}align="center">Email</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 16 }}align="center">Contato</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 16 }}align="center">Endereço</TableCell>
                                

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customer?.map((item, index) => (
                                <TableRow sx={{
                                  cursor:'pointer', 
                                  '&:hover': {
                                    backgroundColor: '#e2dfdf',
                                    transition: 'all 0.2s ease-in-out'
                                  }}}
                                  onClick={() => {
                                    props.setCustomer([item])
                                    props.handleClearFiles()
                                    props.handleClose()
                                  }}
                                  key={index}>
                                  <TableCell sx={{ p: '5px', fontSize: 13 }}align="center">{item.cpf ? item.name : item.corporate_reason}</TableCell>
                                  <TableCell sx={{ p: '5px', fontSize: 13 }}align="center">{item.cpf ? item.cpf : item.cnpj}</TableCell>
                                  <TableCell sx={{ p: '5px', fontSize: 13 }}align="center">{item.email}</TableCell>
                                  <TableCell sx={{ p: '5px', fontSize: 13 }}align="center">{item.contact}</TableCell>
                                  <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{`${item.street} ${item.number} ${item.complement} ${item.neighborhood} ${item.city} ${item.state}`}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>



                        </Paper>

                        <Pagination style={{ marginTop: '10px' }}
                          count={totalPages}
                          page={currentPage}
                          onChange={(event, page) => handlePageChange(page)}
                          showFirstButton
                          showLastButton
                          boundaryCount={1} // Número de páginas antes e depois das ellipses
                          siblingCount={1} // Número de páginas antes e depois da página atual
                        />
                      </>
                    )}


                </div>


              </div>
            </div>

          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>

  )
}

export default ListSelectCustomer
