import { Button, Grid } from '@mui/material'
import React from 'react'

export default function Test() {
    const request = async () => {
        await fetch('https://api.fortesol.com.br/api/installation_data/download', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
        }).catch((error) => {
            console.log(error)
        })
    }
  return (
   <Grid container>
        <Grid item>
        <Button variant="contained" color="primary" onClick={request}
        >download</Button>
        </Grid>
    </Grid>
  )
}
