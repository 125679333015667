import { Alert, Button, FormControlLabel, Grid, ListItemText, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import validator from 'validator';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Customers_Of_Customers_Create(props) {
    

    const handleChange = (event) => {
       props.setTipoPessoa(event.target.value)
        props.clearFields()
    };
    const handleUcUser = (event) => {
        props.setUsuario_uc(event.target.value)
    };




    const civil_states = [
        { label: 'Não Informado', value: 'Não Informado'},
        { label: 'Solteiro(a)', value: 'Solteiro(a)' },
        { label: 'União Estável', value: 'União Estável' },
        { label: 'Casado(a)', value: 'Casado(a)' },
        { label: 'Divorciado(a)', value: 'Divorciado(a)' },
        { label: 'Viúvo(a)', value: 'Viúvo(a)' },
    ];





    const phone_format = (phone) => {
        phone = phone?.replace(/\D/g, "")
        phone = phone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")

        if (phone.length > 15) {
            phone = phone.substring(0, 14)
        }

        return phone
    }
    const cpf_format = (cpf) => {

        cpf = cpf?.replace(/\D/g, "")
        cpf = cpf?.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf?.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf?.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        if (cpf.length > 14) {
            cpf = cpf.substring(0, 13)
        }

        return cpf
    }
    const cep_format = (cep) => {
        cep = cep?.replace(/\D/g, "")
        cep = cep?.replace(/(\d{5})(\d{3})/, "$1-$2")

        if (cep.length > 9) {
            cep = cep.substring(0, 8)
        }

        return cep
    }

    const cnpj_format = (cnpj) => {
        cnpj = cnpj?.replace(/\D/g, "")
        cnpj = cnpj?.replace(/(\d{2})(\d)/, "$1.$2")
        cnpj = cnpj?.replace(/(\d{3})(\d)/, "$1.$2")
        cnpj = cnpj?.replace(/(\d{3})(\d)/, "$1/$2")
        cnpj = cnpj?.replace(/(\d{4})(\d)/, "$1-$2")

        if (cnpj.length > 18) {
            cnpj = cnpj.substring(0, 17)
        }

        return cnpj
    }
    const handleCNPJ = async (cnpj) => {
        if (!cnpj) {
        
            return
        }
        const cnpjCorrect = cnpj?.replace(/[^\d]+/g, '');
        if (cnpjCorrect.length !== 14) {
        
            return
        }
        await fetch(`https://publica.cnpj.ws/cnpj/${cnpjCorrect}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ERROR') {
                  
                    return
                }

                const cnpjformat = data.estabelecimento.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                const formattedCep = data.estabelecimento.cep?.replace(/(\d{5})(\d{3})/, "$1-$2")


   
                props.setFantasy_name(data.estabelecimento.nome_fantasia ? data.estabelecimento.nome_fantasia : '---')
                props.setCorporate_reason(data.razao_social)
                props.setCnpj(cnpjformat)
                props.setEmail(data.estabelecimento.email)
                props.setContact(`${data.estabelecimento.ddd1} ${data.estabelecimento.telefone1}`)
                props.setCep(formattedCep)
                props.setStreet(`${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}`)
                props.setNumber(data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N')
                props.setNeighborhood(data.estabelecimento.bairro)
                props.setCity(data.estabelecimento.cidade.nome)
                props.setState(data.estabelecimento.estado.sigla)
                props.setComplement(data.estabelecimento.complemento ? data.estabelecimento.complemento : '---')




                const _cnpj = [
                    data.razao_social,
                    cnpjformat,
                    data.estabelecimento.email,
                    data.estabelecimento.nome_fantasia,
                    data.estabelecimento.logradouro,
                    data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N',
                    data.estabelecimento.complemento ? data.estabelecimento.complemento : '---',
                    data.estabelecimento.bairro,
                    data.estabelecimento.cidade.nome,
                    data.estabelecimento.estado.sigla,
                    formattedCep

                ]


            }).catch(err => {
                console.log(err)

            })
    }











    return (
        <Grid>
            <Alert severity="info">Preencha os campos abaixo corretamente.</Alert>

      
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props.tipoPessoa}
                name="radio-buttons-group"
                onChange={handleChange}
                sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
            >
                <FormControlLabel value="pf" control={<Radio />} label="Pessoa Física" />
                <FormControlLabel value="pj" control={<Radio />} label="Pessoa Jurídica" />
            </RadioGroup>
          <br />
   {props.tipoPessoa === 'pj'  &&       <>
          <ListItemText sx={{textAlign:'center'}} primary="Titular da Conta de Energia" />
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props.usuario_uc}
                name="radio-buttons-group"
                onChange={handleUcUser}
                sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
            >
                <FormControlLabel value="cliente_terceiro" control={<Radio />} label="Cliente PJ" />
                <FormControlLabel value="representante_legal_terceiro" control={<Radio />} label="Representante Legal" />
            </RadioGroup>
</>}




            {props.tipoPessoa === 'pf' && (
                <Grid sx={{ mt: 1 }} container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id='nome'
                            size="small"
                            label="Nome Completo"
                            variant="outlined"
                            name="nome"
                            value={props.name}
                            onChange={(e) =>{
                                
                                
                                props.setName(e.target.value)
                              }  }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id='rg'
                            size="small"
                            label="RG"
                            variant="outlined"
                            name="RG"
                            value={props.rg}
                            onChange={(e) => {
                                e.target.value = e.target.value?.replace(/\D/g, "")
                                props.setRg(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cpf'
                            label="CPF"
                            variant="outlined"
                            name="cpf"
                            value={props.cpf}
                            onChange={(e) => {
                                e.target.value = e.target.value?.replace(/\D/g, "")
                                e.target.value = cpf_format(e.target.value)
                                props.setCpf(e.target.value)
                              


                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            id='estadoCivil'
                            label="Estado Civil"
                            variant="outlined"
                            name="estadoCivil"
                            value={props.civil_state}
                            onChange={(e) => props.setCivil_state(e.target.value)}
                        >
                            {civil_states.map((option) => (
                                <MenuItem key={option?.value} value={option?.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='nacionalidade'
                            label="Nacionalidade"
                            variant="outlined"
                            name="nacionalidade"
                            value={props.nationality}
                            onChange={(e) => props.setNationality(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='profissao'
                            label="Profissão"
                            variant="outlined"
                            name="profissao"
                            value={props.profession}
                            onChange={(e) => props.setProfession(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='email_pf'
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={props.email}
                            onChange={(e) => {
                                e.target.value = e.target.value.toLowerCase()
                                if (e.target.value.includes(' ')) {
                                    e.target.value = e.target.value?.replace(/\s/g, '')
                                }

                                props.setEmail(e.target.value)
                                if (!e.target.value.includes('@')) {
                                    document.getElementById('email_pf').style.border = '2px solid red'
                                } else {
                                    document.getElementById('email_pf').style.border = '1px solid #ced4da'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='contato_pf'
                            label="Contato"
                            variant="outlined"
                            name="contato"
                            value={props.contact}
                            onChange={(e) => {
                                e.target.value = phone_format(e.target.value)
                                props.setContact(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cep_pf'
                            label="CEP"
                            variant="outlined"
                            name="cep"
                            value={props.cep}
                            onChange={(e) => {
                                e.target.value = cep_format(e.target.value)
                                props.setCep(e.target.value)
                                if (e.target.value.length === 9) {
                                    fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.erro) {
                                               
                                                return
                                            }
                                            props.setStreet(data.logradouro)
                                            props.setNeighborhood(data.bairro)
                                            props.setCity(data.localidade)
                                            props.setState(data.uf)
                                        })
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='logradouro_pf'
                            label="Logradouro"
                            variant="outlined"
                            name="logradouro"
                            value={props.street}
                            onChange={(e) => props.setStreet(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='numero_pf'
                            label="Número"
                            variant="outlined"
                            name="numero"
                            value={props.number}
                            onChange={(e) => props.setNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='complemento_pf'
                            label="Complemento"
                            variant="outlined"
                            name="complemento"
                            value={props.complement}
                            onChange={(e) => props.setComplement(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='bairro_pf'
                            label="Bairro"
                            variant="outlined"
                            name="bairro"
                            value={props.neighborhood}
                            onChange={(e) => props.setNeighborhood(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cidade_pf'
                            label="Cidade"
                            variant="outlined"
                            name="cidade"
                            value={props.city}
                            onChange={(e) => props.setCity(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='estado_pf'
                            label="Estado"
                            variant="outlined"
                            name="estado"
                            value={props.state}
                            onChange={(e) => props.setState(e.target.value)}
                        />
                    </Grid>
                 
                </Grid>
            )}

            {props.tipoPessoa === 'pj' && (
                <>
                    <Grid sx={{ mt: 1 }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cnpj'
                                label="CNPJ"
                                variant="outlined"
                                name="cnpj"
                                value={props.cnpj}
                                onChange={(e) => {
                                    e.target.value = cnpj_format(e.target.value)
                                    props.setCnpj(e.target.value)
                                    if (e.target.value.length === 18) {
                                        handleCNPJ(e.target.value)
                                       
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='razao_social'
                                label="Razão Social"
                                variant="outlined"
                                name="razaoSocial"
                                value={props.corporate_reason}
                                onChange={(e) => props.setCorporate_reason(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nome_fantasia'
                                label="Nome Fantasia"
                                variant="outlined"
                                name="nomeFantasia"
                                value={props.fantasy_name}
                                onChange={(e) => props.setFantasy_name(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='email_pj'
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={props.email}
                                onChange={(e) => {
                                    e.target.value = e.target.value.toLowerCase()
                                    if (e.target.value.includes(' ')) {
                                        e.target.value = e.target.value?.replace(/\s/g, '')
                                    }

                                    props.setEmail(e.target.value)
                                    if (!e.target.value.includes('@')) {
                                        document.getElementById('email_pj').style.border = '2px solid red'
                                    } else {
                                        document.getElementById('email_pj').style.border = '1px solid #ced4da'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='contato_pj'
                                label="Contato"
                                variant="outlined"
                                name="contato"
                                value={props.contact}
                                onChange={(e) => {
                                    e.target.value = phone_format(e.target.value)
                                    props.setContact(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cep_pj'
                                label="CEP"
                                variant="outlined"
                                name="cep"
                                value={props.cep}
                                onChange={(e) => {
                                    e.target.value = cep_format(e.target.value)
                                    props.setCep(e.target.value)
                                    if (e.target.value.length === 9) {
                                        fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.erro) {
                                                  
                                                    return
                                                }
                                                props.setStreet(data.logradouro)
                                                props.setNeighborhood(data.bairro)
                                                props.setCity(data.localidade)
                                                props.setState(data.uf)
                                            })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='logradouro_pj'
                                label="Logradouro"
                                variant="outlined"
                                name="logradouro"
                                value={props.street}
                                onChange={(e) => props.setStreet(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='numero_pj'
                                label="Número"
                                variant="outlined"
                                name="numero"
                                value={props.number}
                                onChange={(e) => {
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    props.setNumber(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='complemento_pj'
                                label="Complemento"
                                variant="outlined"
                                name="complemento"
                                value={props.complement}
                                onChange={(e) => props.setComplement(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='bairro_pj'
                                label="Bairro"
                                variant="outlined"
                                name="bairro"
                                value={props.neighborhood}
                                onChange={(e) => props.setNeighborhood(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cidade_pj'
                                label="Cidade"
                                variant="outlined"
                                name="cidade"
                                value={props.city}
                                onChange={(e) => props.setCity(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='estado_pj'
                                label="Estado"
                                variant="outlined"
                                name="estado"
                                value={props.state}
                                onChange={(e) => props.setState(e.target.value)}
                            />
                        </Grid>
                     
                    </Grid>
                    <br />
                    <Alert severity="info">Representante Legal</Alert>
                    <Grid sx={{ mt: 1 }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nome_representante'
                                label="Nome"
                                variant="outlined"
                                name="nomeRepresentante"
                                value={props.name_representative}
                                onChange={(e) => props.setName_representative(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='rg_representante'
                                label="RG"
                                variant="outlined"
                                name="RG"
                                value={props.rg_representative}
                                onChange={(e) => {
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    props.setRg_representative(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cpf_representante'
                                label="CPF"
                                variant="outlined"
                                name="cpf"
                                value={props.cpf_representative}
                                onChange={(e) => {
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    props.setCpf_representative(cpf_format(e.target.value))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nacionalidade_representante'
                                label="Nacionalidade"
                                variant="outlined"
                                name="nacionalidade"
                                value={props.nationality_representative}
                                onChange={(e) => props.setNationality_representative(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='profissao_representante'
                                label="Profissão"
                                variant="outlined"
                                name="profissao"
                                value={props.profession_representative}
                                onChange={(e) => props.setProfession_representative(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
<br />
                         <Grid item xs={12} sm={4} md={20} 
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Button component="label"
                        variant="contained"
                    >
                        Carregar RG/CNH
                        <input type="file" 
                        accept='image/* , application/pdf'
                        hidden onChange={
                            (e) => {
                                const file = e.target.files[0]
                                const formdata = new FormData()
                                formdata.append('rg_cnh', file)
                                props.setFileRG_CNH(formdata)
                                toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                            }
                        } />
                        &nbsp;
                        <CloudUploadIcon />
                    </Button>
                </Grid>
                <br />
              {props.tipoPessoa === 'pj' &&  <Grid item xs={12} sm={5} md={20} 
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Button component="label"
                        variant="contained"
                    >
                        Carregar Contrato Social
                        <input type="file"
                         accept='image/* , application/pdf'
                        hidden onChange={
                            (e) =>{
                                const file = e.target.files[0]
                                const formdata = new FormData()
                                formdata.append('contract_social', file)
                                props.setFileSocial_Contract(formdata)
                                toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                            }
                        } />
                        &nbsp;
                        <CloudUploadIcon />
                    </Button>
                </Grid>}

        </Grid>
    )
}
