import React, { useState } from 'react';


// Páginas do projeto

import ProjectInformation from './projectInformation.jsx';
import AddressInformation from './addressInformation.jsx';

import Loading from '../loading/index.jsx';

// Importando biblioteca para gerar PDF
import html2pdf from 'html2pdf.js';

// Importando componentes do Material UI
import {  useNavigate } from 'react-router-dom';

import   
 {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@mui/material';
import Sidebar from '../Sidebar/index.jsx';

// Importando componentes de notificação
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderSolProjetos from '../headeSolProjetos/index.jsx';
import Customers_Of_Customers_Create from './customers_of_customers_create.jsx';
import Photovoltaic_Information from './photovoltaic_information.jsx';
import Documentation from './documentation.jsx';
import validator from 'validator';
import { redirect } from 'react-router-dom';



// Função principal do componente

function ServiceProjectSolarCustomer() {
  const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)


    // Pag 1 : Estados para armazenar os dados do cliente
    
    const [name, setName] = useState('')
    const [cpf, setCpf] = useState('')
    const [civil_state, setCivil_state] = useState('')
    const [nationality, setNationality] = useState('')
    const [profession, setProfession] = useState('')
    const [fantasy_name, setFantasy_name] = useState('')
    const [corporate_reason, setCorporate_reason] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [password, setPassword] = useState('')
    const [cep, setCep] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [complement, setComplement] = useState('')
    const [rg, setRg] = useState('')
    const [name_representative, setName_representative] = useState('')
    const [rg_representative, setRg_representative] = useState('')
    const [cpf_representative, setCpf_representative] = useState('')
    const [nationality_representative, setNationality_representative] = useState('')
    const [profession_representative, setProfession_representative] = useState('')

    const [tipoPessoa, setTipoPessoa] = useState('pf')
    const [usuario_uc, setUsuario_uc] = useState('cliente_terceiro')

    const [fileRG_CNH, setFileRG_CNH] = useState('')
    const [fileSocial_Contract, setFileSocial_Contract] = useState('')

    const [selectedCustomer, setSelectedCustomer] = useState('')


    const clearFields = () => {
      setName('')
      setCpf('')
      setCivil_state('')
      setNationality('')
      setProfession('')
      setFantasy_name('')
      setCorporate_reason('')
      setCnpj('')
      setEmail('')
      setContact('')
      setCep('')
      setStreet('')
      setNumber('')
      setNeighborhood('')
      setCity('')
      setState('')
      setComplement('')
      setRg('')
      setName_representative('')
      setRg_representative('')
      setCpf_representative('')
      setNationality_representative('')
      setProfession_representative('')
      setFileRG_CNH('')
      setFileSocial_Contract('')
    }
    // Page 2 : Estados para armazenar os dados do projeto
    const [selectedProjectType, setSelectedProjectType] = useState('');
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const [selectedBranchType, setSelectedBranchType] = useState('aereo');
    const [selectedModalityType, setSelectedModalityType] = useState('geracao_local');
    const [selectedDisjuntor, setSelectedDisjuntor] = useState('');
    const [selectedContractedDemand, setSelectedContractedDemand] = useState('');
    const [selectedPowerRede, setSelectedPowerRede] = useState('');
    const [selectedNumberUC, setSelectedNumberUC] = useState('');
    const [selectedNumberART, setSelectedNumberART] = useState('');
    const [selectedProjectOBS, setSelectedProjectOBS] = useState('');

    const [selectedFile_electricity_bill, setSelectedFile_electricity_bill] = useState('');
    const [selectedFile_disjuntor, setSelectedFile_disjuntor] = useState('');
    const [selectedFile_energy_box, setSelectedFile_energy_box] = useState('');
    const [selectedFile_ART, setSelectedFile_ART] = useState('');


    // Pag 3 : Estados para armazenar os dados de endereço
    const [selectedAddressCep, setSelectedAddressCep] = useState('');
    const [selectedAddressStreet, setSelectedAddressStreet] = useState('');
    const [selectedAddressNumber, setSelectedAddressNumber] = useState('');
    const [selectedAddressDistrict, setSelectedAddressDistrict] = useState('');
    const [selectedAddressCity, setSelectedAddressCity] = useState('');
    const [selectedAddressState, setSelectedAddressState] = useState('');
    const [selectedAddressComplement, setSelectedAddressComplement] = useState('');

    const [selectedLatitudeClick, setSelectedLatitudeClick] = useState('');
    const [selectedLongitudeClick, setSelectedLongitudeClick] = useState('');

    const [checkedAddress, setCheckedAddress] = useState(false);
    // Pag 4 : Estados para armazenar os dados dos módulos e inversores

    const [selectedInvertersArray, setSelectedInvertersArray] = useState([{ marca: '', modelo: '', quantidade: '' }]);
    const [selectedModulesArray, setSelectedModulesArray] = useState([{ marca: '', modelo: '', quantidade: '' }]);
    const [file_data_photovoltaic, setFile_data_photovoltaic] = useState('');
    // Pag 5 : Estados html para salvar dados do sistema

    const [dataRateio, setDataRateio] = useState('');

    const [ucs, setUcs] = useState([]);
    const [kwhValues, setKwhValues] = useState([]);
    const [percentages, setPercentages] = useState([]);
    const [resultPercentages, setResultPercentages] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [checked, setChecked] = useState(false);


    
    //juntando dois arrays em um unico array
    const newArray = ucs.map((item, index) => {
      return { uc: item, kwh: kwhValues[index], percentage: percentages[index] }
    })

   

    //botão para verificar se a procuração foi baixada
    const [botaoClicado, setBotaoClicado] = useState(false);

  const handleClick = () => {
    setBotaoClicado(true);
  };


    //Função para download do memorial descritivo

    const [activeStep, setActiveStep] = useState(0);

  
    const steps = ['Informações do Cliente','Informações do Projeto','Informações de Endereço' ,' Inversores / Módulos','Documentação'];
  
    const handleNextasd_ = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);   
    };

    const handleNext = () => {
      // Verificando as condições para cada etapa antes de avançar
      switch (activeStep) {
        case 0:
  
         
          // Condições para a página de informações do cliente
          if (tipoPessoa === 'pf') {

            if (!name) {
                toast.error('Campo nome vazio')
                document.getElementById('nome')?.focus()
                return
            }
            if (!rg) {
                toast.error('Campo RG vazio')
                document.getElementById('rg')?.focus()
                return
            }
            if (!cpf) {
                toast.error('Campo CPF vazio')
                document.getElementById('cpf')?.focus()
                return
            }
           /* if (!civil_state) {
                toast.error('Campo estado civil vazio')
                document.getElementById('estadoCivil')?.focus()
                return
            }*/
            if (!nationality) {
                toast.error('Campo nacionalidade vazio')
                document.getElementById('nacionalidade')?.focus()
                return
            }
            if (!profession) {
                toast.error('Campo profissão vazio')
                document.getElementById('profissao')?.focus()
                return
            }
           /* if (!email) {
                toast.error('Campo email vazio')
                document.getElementById('email_pf')?.focus()
                return
            }*/
            /*if (!validator.isEmail(email)) {
                toast.error('Email inválido')
                document.getElementById('email_pf')?.focus()
                return
            }*/
           /* if (!contact) {
                toast.error('Campo contato vazio')
                document.getElementById('contato_pf')?.focus()
                return
            }*/
            if (!cep) {
                toast.error('Campo CEP vazio')
                document.getElementById('cep_pf')?.focus()
                return
            }
            if (!street) {
                toast.error('Campo logradouro vazio')
                document.getElementById('logradouro_pf')?.focus()
                return
            }
            if (!number) {
                toast.error('Campo número vazio')
                document.getElementById('numero_pf')?.focus()
                return
            }
            if (!neighborhood) {
                toast.error('Campo bairro vazio')
                document.getElementById('bairro_pf')?.focus()
                return
            }
            if (!city) {
                toast.error('Campo cidade vazio')
                document.getElementById('cidade_pf')?.focus()
                return
            }
            if (!state) {
                toast.error('Campo estado vazio')
                document.getElementById('estado_pf')?.focus()
                return
            }
           /* if (!complement) {
                toast.error('Campo complemento vazio')
                document.getElementById('complemento_pf')?.focus()
                return
            }*/
            if(!fileRG_CNH){
              return toast.error('Envie o arquivo do RG ou CNH');
              }
      
          }
          if (tipoPessoa === 'pj') {
         /*   if (!fantasy_name) {
                toast.error('Campo nome fantasia vazio')
                document.getElementById('nome_fantasia')?.focus()
                return
            }*/
            if (!corporate_reason) {
                toast.error('Campo razão social vazio')
                document.getElementById('razao_social')?.focus()
                return
            }
            if (!cnpj) {
                toast.error('Campo CNPJ vazio')
                document.getElementById('cnpj')?.focus()
                return
            }
            if (!email) {
                toast.error('Campo email vazio')
                document.getElementById('email_pj')?.focus()
                return
            }
            if (!validator.isEmail(email)) {
                toast.error('Email inválido')
                document.getElementById('email_pj')?.focus()
                return
            }
            if (!contact) {
                toast.error('Campo contato vazio')
                document.getElementById('contato_pj')?.focus()
                return
            }
            if (!cep) {
                toast.error('Campo CEP vazio')
                document.getElementById('cep_pj')?.focus()
                return
            }
            if (!street) {
                toast.error('Campo logradouro vazio')
                document.getElementById('logradouro_pj')?.focus()
                return
            }
            if (!number) {
                toast.error('Campo número vazio')
                document.getElementById('numero_pj')?.focus()
                return
            }
            if (!neighborhood) {
                toast.error('Campo bairro vazio')
                document.getElementById('bairro_pj')?.focus()
                return
            }
            if (!city) {
                toast.error('Campo cidade vazio')
                document.getElementById('cidade_pj')?.focus()
                return
            }
            if (!state) {
                toast.error('Campo estado vazio')
                document.getElementById('estado_pj')?.focus()
                return
            }
          /*  if (!complement) {
                toast.error('Campo complemento vazio')
                document.getElementById('complemento_pj')?.focus()
                return
            }
            if (!name_representative) {
                toast.error('Campo nome representante vazio')
                document.getElementById('nome_representante')?.focus()
                return
            }
            if (!rg_representative) {
                toast.error('Campo RG representante vazio')
                document.getElementById('rg_representante')?.focus()
                return
            }
            if (!cpf_representative) {
                toast.error('Campo CPF representante vazio')
                document.getElementById('cpf_representante')?.focus()
                return
            }
            if (!nationality_representative) {
                toast.error('Campo nacionalidade representante vazio')
                document.getElementById('nacionalidade_representante')?.focus()
                return
            }
            if (!profession_representative) {
                toast.error('Campo profissão representante vazio')
                document.getElementById('profissao_representante')?.focus()
                return
            }*/
            if(!fileRG_CNH){
              return toast.error('Envie o arquivo do RG ou CNH');
              }
            if(!fileSocial_Contract){
              return toast.error('Envie o arquivo do contrato social');
              }
          }
                
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                
            
          break;
        case 1:
          // Condições para a página de informações do projeto
        if (
            selectedProjectType !== '' &&
            selectedServiceType !== '' &&
            selectedBranchType !== '' &&
            selectedModalityType !== '' &&
            selectedDisjuntor !== '' &&
            selectedDisjuntor !== '0' &&
            selectedDisjuntor !== 0 &&
            selectedPowerRede !== '' &&
            selectedNumberUC !== '' 
          /*  &&
           selectedProjectOBS !== '' &&
            selectedFile_electricity_bill !== '' &&
            selectedFile_disjuntor !== '' &&
            selectedFile_energy_box !== '' 
    */

          ) {
            if(selectedProjectType === 'a4_horaria_verde' || selectedProjectType === 'a4_horaria_azul'){
              if(selectedContractedDemand !== ''){
               return setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }else{
               return toast.error('Campo Demanda Contratada é obrigatório');
              }
            }
          return  setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }else{
            if(selectedProjectType === ''){
             return toast.error('Selecione o tipo de projeto');
            }
            if(selectedServiceType === ''){
              return toast.error('Selecione o tipo de atendimento');
              }
            if(selectedBranchType === ''){
              return toast.error('Selecione o tipo de ramal');
              }
            if(selectedModalityType === ''){
              return toast.error('Selecione o tipo de modalidade');
              }
            if(selectedDisjuntor === ''){
              return toast.error('Selecione o disjuntor');
              }
            if(selectedDisjuntor === '0'){
              return toast.error('Campo disjuntor não pode ser 0');
              }
            if(selectedDisjuntor === 0){
              return toast.error('Campo disjuntor não pode ser 0');
              }
         
            if(selectedNumberUC === ''){
              return toast.error('Preencha o número da UC');
              }
        
           /* if(selectedProjectOBS === ''){
              return toast.error('Preencha a observação do projeto');
              }*/
          /*  if(selectedFile_electricity_bill === ''){
              return toast.error('Envie uma foto conta de luz');
              }
            if(selectedFile_disjuntor === ''){
              return toast.error('Envie uma foto do disjuntor');
              }
            if(selectedFile_energy_box === ''){
              return toast.error('Envie uma foto da caixa de energia');

          }*/
             
        }
          break;
        case 2:
          // Condições para a página de informações de endereço
          if (
            selectedAddressCep !== '' &&
            selectedAddressStreet !== '' &&
            selectedAddressNumber !== '' &&
            selectedAddressDistrict !== '' &&
            selectedAddressCity !== '' &&
            selectedAddressState !== '' &&
            selectedLatitudeClick !== '' &&
            selectedLongitudeClick !== ''
          ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }else{
            if(selectedAddressCep === ''){
             return toast.error('Preencha o campo CEP');
            }
            if(selectedAddressStreet === ''){
             return toast.error('Preencha o campo Rua');
            }
            if(selectedAddressNumber === ''){
             return toast.error('Preencha o campo Número');
            }
            if(selectedAddressDistrict === ''){
             return toast.error('Preencha o campo Bairro');
            }
            if(selectedAddressCity === ''){
             return toast.error('Preencha o campo Cidade');
            }
            if(selectedAddressState === ''){
             return toast.error('Preencha o campo Estado');
            }
            if(!selectedLatitudeClick && !selectedLongitudeClick ){
             return toast.error('Clique no mapa para definir a localização');
            }
          }
      

          break;
        case 3:
          // Condições para a página de informações de módulos e inversores
          if (!file_data_photovoltaic){

 
            selectedInvertersArray.map((inverter, index) => {
              if (!inverter.marca) {
                  toast.error('Campo marca do inversor vazio')
                  document.getElementById(`brand-inverter-${index}`)
                  return 
              }else{
                document.getElementById(`brand-inverter-${index}`).style.border = '1px solid #ced4da'
              }
              if (!inverter.modelo) {
                  toast.error('Campo modelo do inversor vazio')
                  document.getElementById(`model-inverter-${index}`)
                  return
              }else{
                document.getElementById(`model-inverter-${index}`).style.border = '1px solid #ced4da'
              }
              if (!inverter.quantidade) {
                  toast.error('Campo quantidade do inversor vazio')
                  document.getElementById(`quantity-inverter-${index}`)
                  return
              }else{
                document.getElementById(`quantity-inverter-${index}`).style.border = '1px solid #ced4da'
              }
            })
            selectedModulesArray.map((module, index) => {
              if (!module.marca) {
                  toast.error('Campo marca do módulo vazio')
                  document.getElementById(`brand-module-${index}`)
                  return
              }else{
                document.getElementById(`brand-module-${index}`).style.border = '1px solid #ced4da'
              }
              if (!module.modelo) {
                  toast.error('Campo modelo do módulo vazio')
                  document.getElementById(`model-module-${index}`)
                  return
              }else{
                document.getElementById(`model-module-${index}`).style.border = '1px solid #ced4da'
              }
              if (!module.quantidade) {
                  toast.error('Campo quantidade do módulo vazio')
                  document.getElementById(`quantity-module-${index}`)
                  return
              }else{
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                document.getElementById(`quantity-module-${index}`).style.border = '1px solid #ced4da'
              }
            })

          }
          
         else setActiveStep((prevActiveStep) => prevActiveStep + 1);


          break;
        case 4:
          // Condições para a página de documentação
          if (checked) {
            setBotaoClicado(false);
            ucs?.map((uc, index) => {
              if (!uc) {
                  toast.error('Campo UC vazio')
                  document.getElementById(`uc-${index}`)?.focus()
                  return
              }
            })
            kwhValues?.map((kwh, index) => {
              if (!kwh) {
                  toast.error('Campo Kwh vazio')
                  document.getElementById(`kwh-${index}`)?.focus()
                  return
              }
            })
            percentages?.map((percentage, index) => {
              if (!percentage) {
                  toast.error('Calcule a porcentagem')
                  return
              }
            })
            if (resultPercentages !== 100) {
              toast.error('A soma das porcentagens deve ser 100')
              return
            }
            setBotaoClicado(true);

          }




          break;

        
        default:
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };


//funções do step 0

const searchByCpf = async (cpf) => {
        
  if (!cpf || cpf.length < 14) {
      
      return
  }
  try {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/search/cpf/${cpf}`)
          .then(response => response.json())
          .then(data => {
              if (data.message === 'Customer not found') {
                  
                  return
              }
          
              setSelectedCustomer(data)
           
          })
  } catch (error) {
    
  }
}

const searchByCnpj = async (cnpj) => {
 
  if (!cnpj || cnpj.length < 18) {
      return
  }
  try {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/search/cnpj?cnpj=${cnpj}`)
          .then(response => response.json())
          .then(data => {
              if (data.message === 'Customer not found') {
                  return
              }
            
              setSelectedCustomer(data)
          })
  } catch (error) {
      
  }
}


const handleNewCustomer = async () => {

  if (tipoPessoa === 'pf') {

    


      //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
      const response = await fetch('https://api.fortesol.com.br/api/customers_of_customers/create', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, rg, cpf, civil_state, nationality, profession, email, contact, cep, street, number, neighborhood, city, state, complement })
      }).then((response) => response.json())
          .then((data) => {
              if (data.message === 'Customers of customers created successfully') {
                 searchByCpf(cpf)
                 
              }
              else {
                 
              }
          }).catch((error) => {
              
          })
      return response
  }

  if (tipoPessoa === 'pj') {
    
      //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
      const response = await fetch('https://api.fortesol.com.br/api/customers_of_customers/create', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ fantasy_name, corporate_reason, cnpj, email, contact, cep, street, number, neighborhood, city, state, complement, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative })
      }).then((response) => response.json())
          .then((data) => {
              if (data.message === 'Customers of customers created successfully') {
                  searchByCnpj(cnpj)
              }
              else {
                 
              }
          }).catch((error) => {
             
          })
      return response
  }

}


const handleEditCustomer = async () => {

  if (tipoPessoa === 'pf') {

      //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/customers/${props?.data.id}`, {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/update/${selectedCustomer.id_customers_of_customers}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: name, rg: rg, cpf: cpf, civil_state: civil_state, nationality: nationality, profession: profession, email: email, contact: contact, cep: cep, street: street, number: number, neighborhood: neighborhood, city: city, state: state, complement: complement, fantasy_name, corporate_reason, cnpj, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative })
      }).then((response) => response.json())
          .then((data) => {
             
              if (data.message === 'Customer updated successfully') {

              }
          }).catch((error) => {
              
          })
      return response
  }
  if (tipoPessoa === 'pj') {

 
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/update/${selectedCustomer.id_customers_of_customers}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, rg, cpf, civil_state, nationality, profession, email: email, contact: contact, cep: cep, street: street, number: number, neighborhood: neighborhood, city: city, state: state, complement: complement, fantasy_name: fantasy_name, corporate_reason: corporate_reason, cnpj: cnpj, name_representative: name_representative, rg_representative: rg_representative, cpf_representative: cpf_representative, nationality_representative: nationality_representative, profession_representative: profession_representative })
      }).then((response) => response.json())
          .then((data) => {
          
              if (data.message === 'Customer updated successfully') {

              }
          }).catch((error) => {
             
          })
      return response
  }



}

const handleClickCustomerNewEdit = async () => {
  if(tipoPessoa === 'pf'){
    await searchByCpf(cpf)
  }
  if(tipoPessoa === 'pj'){
    await searchByCnpj(cnpj)
  }
  if(selectedCustomer){
    await handleEditCustomer()
  }else{
    await handleNewCustomer()
  }
}

const sendDemands = async () => {
  setIsLoading(true)
  const token = localStorage.getItem('token')
  const tokenParse = JSON.parse(token)
  const id_customer_integrator = tokenParse.id


  if(!selectedCustomer.id_customers_of_customers) return

  const arrayInverter = JSON.stringify(selectedInvertersArray)
  const arrayModule = JSON.stringify(selectedModulesArray)
  const arrayRateio = JSON.stringify(newArray)
  const dataNow = new Date()  
  await fetch('https://api.fortesol.com.br/api/demand_project_solar/create', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ project_type_demand : selectedProjectType, service_type_demand : selectedServiceType, modality_type_demand : selectedModalityType, power_rede_demand : selectedPowerRede, disjuntor_demand : selectedDisjuntor, number_uc_demand : selectedNumberUC, project_obs_demand : selectedProjectOBS, cep_service_demand : selectedAddressCep, street_service_demand : selectedAddressStreet, district_service_demand : selectedAddressDistrict, city_service_demand : selectedAddressCity, state_service_demand : selectedAddressState, lat_demand : selectedLatitudeClick, log_demand : selectedLongitudeClick, status : 'Solicitado', date_demand : dataNow, date_conclusion_demand : '---', id_customer_integrator : id_customer_integrator, id_customer_of_customers : selectedCustomer.id_customers_of_customers, rg_cnh : '---', electricity_bill : '---', contract_social : '---', branch_type_demand : selectedBranchType, contracted_demand_demand : selectedContractedDemand ? selectedContractedDemand : '---', photos_breaker : '---', energy_box_photos : '---', info_inverter_demand : arrayInverter, info_module_demand : arrayModule, info_data_photovoltaic_demand : '---', info_rateio_demand : arrayRateio, number_service_demand : selectedAddressNumber, doc_proxy_demand : '---', info_proxy_demand : 'send',uc_registered_user : usuario_uc, complement_service_demand : selectedAddressComplement})
  }).then(response => response.json())
      .then(data => {
          const id = data.id
    
          if (fileRG_CNH) {
              fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageRgCnh/${id}`, {
                  method: 'POST',
                  body: fileRG_CNH
              }).then(response => response.json())
                  .then(data => {
                      if (data.message === 'Image uploaded successfully') {
                      }
                  }).catch(err => {
                      toast.error('Erro ao enviar RG/CNH')
                  })
          }
          if (selectedFile_electricity_bill) {
              fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageElectricityBill/${id}`, {
                  method: 'POST',
                  body: selectedFile_electricity_bill
              }).then(response => response.json())
                  .then(data => {
                      if (data.message === 'Image uploaded successfully') {
                      }
                  }).catch(err => {
                      toast.error('Erro ao enviar conta de luz')
                  })
          } 
          if (selectedFile_disjuntor) {
              fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImagePhotosBreaker/${id}`, {
                  method: 'POST',
                  body: selectedFile_disjuntor
              }).then(response => response.json())
                  .then(data => {
                      if (data.message === 'Image uploaded successfully') {
                      }
                  }).catch(err => {
                      toast.error('Erro ao enviar fotos do disjuntor')
                  })
          }
          if (selectedFile_energy_box) {
              fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageEnergyBoxPhotos/${id}`, {
                  method: 'POST',
                  body: selectedFile_energy_box
              }).then(response => response.json())
                  .then(data => {
                      if (data.message === 'Image uploaded successfully') {
                      }
                  }).catch(err => { 
                      toast.error('Erro ao enviar fotos da caixa de energia')
                  })
          }
          if (file_data_photovoltaic) {
              fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageDataPhotovoltaic/${id}`, {
                  method: 'POST',
                  body: file_data_photovoltaic
              }).then(response => response.json())
                  .then(data => {
                      if (data.message === 'Image uploaded successfully') {
                      }
                  }).catch(err => {
                      toast.error('Erro ao enviar arquivo de dados fotovoltaicos')
                  })
          }
          if(fileSocial_Contract){
            fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageContractSocial/${id}`, {
                method: 'POST',
                body: fileSocial_Contract
            }).then(response => response.json())
                .then(data => {
                    if (data.message === 'Image uploaded successfully') {
                    }
                }).catch(err => {
                    toast.error('Erro ao enviar contrato social')
                })
        }

          setIsLoading(false)
          fetch(`https://api.fortesol.com.br/api/timeline/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
        
                event: 'Memorial Descritivo',
                details: 'Memorial Descritivo Solicitado', 
                demand_id: id,
                name_user: tokenParse.name,
            })
        })

          toast.success('Demanda enviada com sucesso')
          navigate('/solar-service')
      }).catch(err => {
          setIsLoading(false)
          toast.error('Erro ao enviar demanda')
      })


}










//Envio de formulário para o servidor








  return (
    <Sidebar>
{isLoading && <Loading />}
 <HeaderSolProjetos/>
<br />
<Container maxWidth="md" >
       <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        zIndex={9999999999}
        /> 
     {window.innerWidth > 800 && <Stepper activeStep={activeStep} orientation='horizontal'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>}
      {window.innerWidth <= 800 && <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>}
      <div >
      <Container maxWidth="md">

    <Stepper activeStep={activeStep}>
    {activeStep === 0 && 
    <Customers_Of_Customers_Create
    
    name={name}
    cpf={cpf}
    civil_state={civil_state}
    nationality={nationality}
    profession={profession}
    fantasy_name={fantasy_name}
    corporate_reason={corporate_reason}
    cnpj={cnpj}
    email={email}
    contact={contact}
    password={password}
    cep={cep}
    street={street}
    number={number}
    neighborhood={neighborhood}
    city={city}
    state={state}
    complement={complement}
    rg={rg}
    name_representative={name_representative}
    rg_representative={rg_representative}
    cpf_representative={cpf_representative}
    nationality_representative={nationality_representative}
    profession_representative={profession_representative}
    setName={setName}
    setCpf={setCpf}
    setCivil_state={setCivil_state}
    setNationality={setNationality}
    setProfession={setProfession}
    setFantasy_name={setFantasy_name}
    setCorporate_reason={setCorporate_reason}
    setCnpj={setCnpj}
    setEmail={setEmail}
    setContact={setContact}
    setPassword={setPassword}
    setCep={setCep}
    setStreet={setStreet}
    setNumber={setNumber}
    setNeighborhood={setNeighborhood}
    setCity={setCity}
    setState={setState}
    setComplement={setComplement}
    setRg={setRg}
    setName_representative={setName_representative}
    setRg_representative={setRg_representative}
    setCpf_representative={setCpf_representative}
    setNationality_representative={setNationality_representative}
    setProfession_representative={setProfession_representative}

    tipoPessoa={tipoPessoa}
    setTipoPessoa={setTipoPessoa}

    usuario_uc={usuario_uc}
    setUsuario_uc={setUsuario_uc}

    fileRG_CNH={fileRG_CNH}
    fileSocial_Contract={fileSocial_Contract}
    setFileRG_CNH={setFileRG_CNH}
    setFileSocial_Contract={setFileSocial_Contract}

    selectedCustomer={selectedCustomer}
    setSelectedCustomer={setSelectedCustomer}

    clearFields={clearFields}
    />
    }
    
    {activeStep === 1 && 
    <ProjectInformation 
    proJectType={selectedProjectType}
    serviceType={selectedServiceType}
    branchType={selectedBranchType}
    modalityType={selectedModalityType}
    disjuntor={selectedDisjuntor}
    contractedDemand={selectedContractedDemand}
    powerRede={selectedPowerRede}
    numberUC={selectedNumberUC}
    numberART={selectedNumberART}
    projectObs={selectedProjectOBS}
    file_electricity_bill={selectedFile_electricity_bill}
    file_disjuntor={selectedFile_disjuntor}
    file_energy_box={selectedFile_energy_box}
    fileArt={selectedFile_ART}
    setProjectType={setSelectedProjectType} 
    setServiceType={setSelectedServiceType} 
    setBranchType={setSelectedBranchType}
    setModalityType={setSelectedModalityType}
    setDisjuntor={setSelectedDisjuntor}
    setContractedDemand={setSelectedContractedDemand}
    setPowerRede={setSelectedPowerRede}
    setNumberUC={setSelectedNumberUC}
    setNumberART={setSelectedNumberART}
    setProjectObs={setSelectedProjectOBS}
    setFileElectricityBill={setSelectedFile_electricity_bill}
    setFileDisjuntor={setSelectedFile_disjuntor}
    setFileEnergyBox={setSelectedFile_energy_box}
    setFileArt={setSelectedFile_ART}

    activeStep={activeStep}
    />}

    {activeStep === 2 && 
    <AddressInformation 

    addressCep={selectedAddressCep}
    addressStreet={selectedAddressStreet}
    addressNumber={selectedAddressNumber}
    addressComplement={selectedAddressComplement}
    addressDistrict={selectedAddressDistrict}
    addressCity={selectedAddressCity}
    addressState={selectedAddressState}
    latitudeClick={selectedLatitudeClick}
    longitudeClick={selectedLongitudeClick}

    setAddressCep={setSelectedAddressCep}
    setAddressStreet={setSelectedAddressStreet}
    setAddressNumber={setSelectedAddressNumber}
    setAddressComplement={setSelectedAddressComplement}
    setAddressDistrict={setSelectedAddressDistrict}
    setAddressCity={setSelectedAddressCity}
    setAddressState={setSelectedAddressState}
    setLatitudeClick={setSelectedLatitudeClick}
    setLongitudeClick={setSelectedLongitudeClick}

    cep={cep}
    street={street}
    number={number}
    complement={complement}
    neighborhood={neighborhood}
    city={city}
    state={state}
    
    checkedAddress={checkedAddress}
    setCheckedAddress={setCheckedAddress}

  
    />}
 {activeStep === 3 &&
 <Photovoltaic_Information
 inverters={selectedInvertersArray}
 modules={selectedModulesArray}
 image={file_data_photovoltaic}

  setInverters={setSelectedInvertersArray}
  setModules={setSelectedModulesArray}
  setImage={setFile_data_photovoltaic}
 />}
    {activeStep === 4 &&
    <Documentation
    name={name}
    cpf={cpf}
    civil_state={civil_state}
    nationality={nationality}
    profession={profession}
    fantasy_name={fantasy_name}
    corporate_reason={corporate_reason}
    cnpj={cnpj}
    email={email}
    contact={contact}
    password={password}
    cep={cep}
    street={street}
    number={number}
    neighborhood={neighborhood}
    city={city}
    state={state}
    complement={complement}
    rg={rg}
    name_representative={name_representative}
    rg_representative={rg_representative}
    cpf_representative={cpf_representative}
    nationality_representative={nationality_representative}
    profession_representative={profession_representative}
    tipoPessoa={tipoPessoa}

      ucs={ucs}
      setUcs={setUcs}
      kwhValues={kwhValues}
      setKwhValues={setKwhValues}
      percentages={percentages}
      setPercentages={setPercentages}
      resultPercentages={resultPercentages}
      setResultPercentages={setResultPercentages}

      expanded={expanded}
      setExpanded={setExpanded}
      checked={checked}
      setChecked={setChecked}

      modalityType={selectedModalityType}

      handleClick={handleClick}
    />}
    </Stepper>
  
  </Container>
<Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 3 }}
        >
          Voltar
        </Button>
 
        <Box sx={{ display: 'flex', justifyContent: 'flex-end',mt:3 }}>
          {activeStep === steps.length - 1  ? (
             
            <Button  disabled={!botaoClicado} id='btn-download' type="button" variant="contained" color="primary" onClick={sendDemands}>
              Solicitar Projeto
            </Button>
          ) : (
            activeStep > 0 ?
            <Button
              id='btn-next'
              type='button'
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Próximo
            </Button>
            :
            <Button
              id='btn-next'
              type='button'
              variant="contained"
              color="primary"
              onClick={()=>{
                handleClickCustomerNewEdit()
                handleNext()
              }}
            >
              Próximo
            </Button>


          )}
        </Box>
        </Grid>
      </div>
          <br />
    </Container>
</Sidebar>
  )
}

export default ServiceProjectSolarCustomer
