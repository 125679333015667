import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import New_Edit_User from '../new_edit_user_customer';
import DropDialog from '../dropDialog';
import New_Edit_User_Customer from '../new_edit_user_customer';
export default function Details_Customers(props) {
    const [typeCustomer, setTypeCustomer] = useState('')
    useEffect(() => {
        if(props.data.cpf)setTypeCustomer('pf')
        else setTypeCustomer('pj')
    }
    , [props])

    const [demand, setDemand] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 3;
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };


    useEffect(() => {
        try {
            fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.data.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },}).then((response) => response.json())
                .then((data) => {
                  
                    setDemand(data[0])
                    setTotalPages(data[1])
                }).catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    , [currentPage, props.data.id])


    const [user, setUser] = useState('')

    const formatDataAndTime = (date) => {
        if(date === '---') return '---'
            const dateFormated = new Date(date)
            return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
    }

    const [openUser, setOpenUser] = useState(false)
    const [title, setTitle] = useState('')
    const [data, setData] = useState({})
    const [typeUser, setTypeUser] = useState('')
    const handleOpenUser = (title, data,type) => {
        setTitle(title)
        setData(data)
        setTypeUser(type)
        setOpenUser(true)
    }
    const closeUser = () => {
        setOpenUser(false)
    }

    const [openDrop, setOpenDrop] = useState(false)
    const handleOpenDrop = () => {
        setOpenDrop(true)
    }
    const closeDrop = () => {
        setOpenDrop(false)
    }

    const handleDelete = () => {
        try {
            fetch(`https://api.fortesol.com.br/api/users/delete/${user.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },}).then((response) => response.json())
                .then((data) => {
                   
                    if(data.message === 'User deleted successfully'){
                        closeDrop()
                    }
                }).catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }





    useEffect(() => {
        try {
            fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },}).then((response) => response.json())
                .then((data) => {
                    setUser(data[0])
                }).catch((error) => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error)
            }
      
        }
    , [props,openUser,openDrop])


  return (


    <Dialog 
    open={props.openDetails}
    onClose={props.closeDetails}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="md"
    fullWidth={true}
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title">Detalhes do Cliente</DialogTitle>
        <DialogActions>
       {
       user?.id ?
       (
        <> 
        <Button size='small' variant='contained' color='primary' onClick={() => handleOpenUser('Editar Usuário', user, 'customer')}>Editar Usuário</Button>
        <Button size='small' variant='contained' color='error' onClick={handleOpenDrop}>Excluir Usuário</Button>
        </>
       ):
       <Button size='small' variant='contained' color='primary' onClick={() => handleOpenUser('Novo Usuário', props.data, 'customer')}>Adicionar Usuário</Button>
        }</DialogActions>
    <New_Edit_User_Customer openUser={openUser} closeUser={closeUser} title={title} data={data} type={typeUser} />
    <DropDialog openDrop={openDrop} closeDrop={closeDrop} title='Excluir Usuário' msg='Deseja realmente excluir este usuário?' delete={handleDelete} />

        {
            typeCustomer === 'pf' && <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nome</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CPF</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">RG</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Contato</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Email</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Estado Civil</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.name}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cpf}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.rg}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.contact}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.civil_state}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CEP</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Rua</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Número</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Bairro</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Cidade</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Estado</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Complemento</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cep}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.street}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.number}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.neighborhood}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.city}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.state}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.complement}</TableCell>
                </TableRow>
              </TableBody>
              </Table>
              </>
        }
        {
            typeCustomer === 'pj' && <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nome Fantasia</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Razão Social</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CNPJ</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Contato</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Email</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.fantasy_name}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.corporate_reason}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cnpj}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.contact}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CEP</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Rua</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Número</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Bairro</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Cidade</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Estado</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Complemento</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cep}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.street}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.number}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.neighborhood}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.city}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.state}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.complement}</TableCell>
                </TableRow>
                </TableBody>
            </Table>
            <br />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nome Representante</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CPF Representante</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">RG Representante</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nacionalidade Representante</TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Profissão Representante</TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
              <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.name_representative}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cpf_representative}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.rg_representative}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.nationality_representative}</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.profession_representative}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </>
        }
        <br />
  {demand.length > 0 && <>
        <DialogTitle id="alert-dialog-title">Projetos</DialogTitle>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">N° UC</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">NOME</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Status</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Data de Solicitação</TableCell>
                    <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Opções</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    demand.map((item, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.number_uc_demand}</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 9 }} align="center">{item.name_customers_of_customers ? item.name_customers_of_customers : item.corporate_reason_customers_of_customers}</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.status}</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{formatDataAndTime(item.date_demand)}</TableCell>
                                <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">EM CONSTRUÇÃO</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
            <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
        </Table></> }

        <DialogActions>
            <Button onClick={props.closeDetails} autoFocus>
                Fechar
            </Button>
        </DialogActions>
</DialogContent>
    </Dialog>

  )
}
