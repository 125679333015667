import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import { Label } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';   
import { toast } from 'react-toastify';

function ProjectInformation(props) {
 
  const options1 = [
    { label: 'B1 - Residencial', value: 'b1_residencial' },
    { label: 'B2 - Rural', value: 'b2_rural' },
    { label: 'B2 - Cooperativa', value: 'b2_cooperativa' },
    { label: 'B2 - Serviço Público de Irrigação', value: 'b2_servico_publico_de_irrigacao' },
    { label: 'B3 - Demais Classes', value: 'b3_demais_classes' },
    { label: 'A4 - Horária Verde', value: 'a4_horaria_verde' },
    { label: 'A4 - Horária Azul', value: 'a4_horaria_azul' },
  ];

  const options2 = [
    { label: 'Trifásico', value: 'trifasico' },
    { label: 'Bifásico', value: 'bifasico' },
    { label: 'Monofásico', value: 'monofasico' },
  ];
  
  const options3 = [
    { label: 'Áereo', value: 'aereo' },
    { label: 'Subterrâneo', value: 'subterraneo' },
  ];

  const options4 = [
    { label: 'Geração Local', value: 'geracao_local' },
    { label: 'Autoconsumo Remoto', value: 'autoconsumo_remoto' },
  ];



  const disjuntorTrifasico = [
    { label: '0', value: '0' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
    { label: '50', value: '50' },
    { label: '63', value: '63' },
    { label: '80', value: '80' },
    { label: '100', value: '100' },
  ];

  const disjuntorBifasico = [
    { label: '0', value: '0' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
  ];

  const disjuntorMonofasico = [
    { label: '0', value: '0' },
    { label: '16', value: '16' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },

  ];


  const handleChangeServiceType = (e)=>{
    if(e.target.value === 'trifasico') props.setPowerRede('220V/380V')  
     if(e.target.value === 'bifasico')   props.setPowerRede('220V/380V')  
    if(e.target.value === 'monofasico')   props.setPowerRede('220V') 
    props.setServiceType(e.target.value)
  }

  const handleChangeRateio = (e) => {
    props.setModalityType(e.target.value)
  }

  const handleChangeBranchType = (e) => {
    props.setBranchType(e.target.value)
  }



  return (
<>
    <Grid sx={{mt:1}} container spacing={2}>
    <Grid sx={{mt:1}} container spacing={2}>
    <Grid item xs={12} sm={6}>
            <RadioGroup
               aria-labelledby="demo-radio-buttons-group-label"
                name="rateio"
                defaultValue={'geracao_local'}
                value={props.modalityType}
                onChange={handleChangeRateio}
                row={true}
              ><Typography sx={{mt:1,mr:1}}>Rateio:</Typography>
                <FormControlLabel value="geracao_local" control={<Radio />} label="Não" />
                <FormControlLabel value="autoconsumo_remoto" control={<Radio />} label="Sim" />
              </RadioGroup>
          </Grid>

      {/*<Grid item xs={12} sm={6}>
        <TextField
        id='modality-type'
          select
          label="Modalidade:"
          value={props.modalityType}
          onChange={(e) => props.setModalityType(e.target.value)}
          fullWidth
           size="small"
        >
          {options4.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>*/}
    <Grid item xs={12} sm={6}>
        <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="branch"
                defaultValue={'aereo'}
                value={props.branchType}
                onChange={handleChangeBranchType}
                row={true}
              ><Typography sx={{mt:1,mr:1}}>Tipo de Ramal:</Typography>
                <FormControlLabel value="aereo" control={<Radio />} label="Aéreo" />
                <FormControlLabel value="subterraneo" control={<Radio />} label="Subterrâneo" />
              </RadioGroup>
      </Grid>


     { /*<Grid item xs={12} sm={6}>
        <TextField
        id='branch-type'
          select
          label="Tipo de Ramal:"
          value={props.branchType}
          onChange={(e) => props.setBranchType(e.target.value)}
          fullWidth
           size="small"
        >
          {options3.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>*/}
         
      <Grid item xs={12} sm={6}>
        
        <TextField
          id='project-type'
          select
          label="Tipo de Projeto:"
          value={props.proJectType}
          onChange={(e) => props.setProjectType(e.target.value)}
          fullWidth
           size="small"
        >
          {options1.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
     </Grid>
   {/*       <Grid item xs={12} sm={6}>
        <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="service"
                defaultValue={'monofasico'}
                value={props.serviceType}
                onChange={handleChangeServiceType}
                row={true}
              ><Typography sx={{mt:1,mr:1}}>Atendimento:</Typography>
                <FormControlLabel value="monofasico" control={<Radio />} label="Monofásico" />
                <FormControlLabel value="bifasico" control={<Radio />} label="Bifásico" />
                <FormControlLabel value="trifasico" control={<Radio />} label="Trifásico" />  
              </RadioGroup>
      </Grid>*/}


{
      <Grid item xs={12} sm={6}>
        <TextField
        id='service-type'
          select
          label="Atendimento:"
          value={props.serviceType}
          onChange={(e) => {
            props.setServiceType(e.target.value)
            if(e.target.value === 'trifasico') props.setPowerRede('220V/380V')  
            if(e.target.value === 'bifasico')   props.setPowerRede('220V/380V')  
            if(e.target.value === 'monofasico')   props.setPowerRede('220V') 

          }}
          fullWidth
           size="small"
        >
          {options2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
}

      <Grid item xs={12} sm={6}>
        <TextField
        id='disjuntor'
          select
          label="Disjuntor:"
          value={props.disjuntor}
          onChange={(e) => props.setDisjuntor(e.target.value)}
          fullWidth
           size="small"
        >
          { props.serviceType === 'trifasico' ? disjuntorTrifasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
          : null
        }
          {props.serviceType === 'bifasico' && disjuntorBifasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {props.serviceType === 'monofasico' && disjuntorMonofasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    {props.proJectType === 'a4_horaria_azul'  &&
     <Grid item xs={12} sm={6}>
      <TextField
        id='contracted-demand'
        label="Demanda Contratada"
        fullWidth
        value={props.contractedDemand}
         size="small"
        onChange={(e) => { e.target.value = e.target.value.replace(/\D/g, "")
        props.setContractedDemand(e.target.value)
        }
      }
      />
      </Grid>}
    {props.proJectType === 'a4_horaria_verde'  &&
     <Grid item xs={12} sm={6}>
      <TextField
        id='contracted-demand'
        label="Demanda Contratada"
        value={props.contractedDemand}
        fullWidth
         size="small"
         onChange={(e) => { e.target.value = e.target.value.replace(/\D/g, "")
          props.setContractedDemand(e.target.value)
          }
        }
      />
      </Grid>}

      <Grid item xs={12} sm={6}>
        <TextField
        disabled
        id='power-rede'

          fullWidth
           size="small"
          value={
            props.serviceType === 'trifasico' ? '220V/380V' : 
            props.serviceType === 'bifasico' ? '220V/380V' : 
            props.serviceType === 'monofasico' ? '220V' : 'Tenção da Rede'
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='number-uc'
        label="Número de UC"
        value={props.numberUC}
          fullWidth
           size="small"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, "")
            props.setNumberUC(e.target.value)
          }
          }
        />
    </Grid>
      </Grid>
      <Grid sx={{mt:1}} container spacing={2}>
  
  
    <Grid item xs={12} sm={12} >
      <TextField
    
        id='project-obs'
        label="Observações"
        value={props.projectObs}
          fullWidth
           size="small"
          onChange={(e) => props.setProjectObs(e.target.value)}
        />
    </Grid>
    </Grid>
    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Conta de Luz
        <input type="file"
         accept='image/* , application/pdf'
        hidden onChange={
          (e) => {
            const file = e.target.files[0]
            const formdata = new FormData()
            formdata.append('electricity_bill', file)
            props.setFileElectricityBill(formdata)
            toast.success(`Arquivo ${file.name} selecionado com sucesso!`)}
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>

    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Foto Disjuntor
        <input type="file" 
         accept='image/* , application/pdf'
        hidden onChange={
          (e) => {
            const file = e.target.files[0]
            const formdata = new FormData()
            formdata.append('photos_breaker', file)
          
            
            props.setFileDisjuntor(formdata)
            toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
          }
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>

    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Foto Caixa de Energia
        <input type="file" 
         accept='image/* , application/pdf'
        hidden onChange={
          (e) => {
            const file = e.target.files[0]
            const formdata = new FormData()
            formdata.append('energy_box_photos', file)
            props.setFileEnergyBox(formdata)
            toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
          }
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>
    </Grid>

  </>
  
  )
}

export default ProjectInformation
