import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material';

function EditConfigSystemProject(props) {
    const [valid, setValid] = useState(false)

    const config_system_element = useRef(null)

    //função para mostrar mensagens de alerta sem repetição
    const message = (msg) => {
        
        setTimeout(() => {
            document.getElementById('message').style.display = 'block'
             document.getElementById('message').innerHTML = `<strong>Atenção! </strong>${msg}`
             }, 100)
        setTimeout(() => {
            if (document.getElementById('message')) document.getElementById('message').style.display = 'none'
        }, 10000)
    }
 

    //variaveis de estado global

    const [work_around, setWork_around] = useState(0)
    const [sumStr, setSumStr] = useState([0, 0])
   

    
    const getValuesDB = () => {
        const checkMppt = props.P_checkMppt
        const selectModules = props.P_selectModules
        const checkString = props.P_checkString
        const unidades = props.P_unidades
        const p_unidades = props.P_p_unidades
        const azimute = props.P_azimute
        const p_azimute = props.P_p_azimute
        const inclincao = props.P_inclincao
        const p_inclincao = props.P_p_inclincao
        const potenciaM = props.P_potenciaM
        const potenciaMppt = props.P_potenciaMppt
        const correnteStr = props.P_correnteStr
        const correnteMppt = props.P_correnteMppt
        const tensao = props.P_tensao
        const secaoN = props.P_secaoN
        const secaoP = props.P_secaoP
        const comprimento = props.P_comprimento
        const tensaoPorce = props.P_tensaoPorce
        const PerdasCabos = props.P_perdasCabos
        const compriemntoFinal = props.P_comprimentoFinal
        const compriemntoQuadro = props.P_comprimentoQuadro
        const structureType = props.P_structureType





        const checkMppt_All = document.querySelectorAll('#checkMppt')
        const selectModules_All = document.querySelectorAll('.select_modules')
        const checkString_All = document.querySelectorAll('.checkStr')
        const unidades_All = document.querySelectorAll('.unidade')
        const p_unidades_All = document.querySelectorAll('.p-unidade')
        const azimute_All = document.querySelectorAll('#azimute')
        const p_azimute_All = document.querySelectorAll('.p-azimute')
        const inclincao_All = document.querySelectorAll('.inclinacao')
        const p_inclincao_All = document.querySelectorAll('.p-inclinacao')
        const potenciaM_All = document.querySelectorAll('.potenciaM')
        const potenciaMppt_All = document.querySelectorAll('.potenciaMppt')
        const correnteStr_All = document.querySelectorAll('.corrente')
        const correnteMppt_All = document.querySelectorAll('.correnteMppt')
        const tensao_All = document.querySelectorAll('.tensao')
        const secaoN_All = document.querySelectorAll('.secaoN')
        const secaoP_All = document.querySelectorAll('.p-secaoN')
        const comprimento_All = document.querySelectorAll('#comprimento')
        const tensaoPorce_All = document.querySelectorAll('.tensaoPorce')
        const PerdasCabos_All = document.querySelectorAll('#perdas_cabos')
        const compriemntoFinal_All = document.querySelectorAll('.comprimentoFinal')
        const compriemntoQuadro_All = document.querySelectorAll('.comprimento-quadro')
        const structureType_All = document.querySelectorAll('.structure-type')


if(props.P_checkMppt.length > 0){

        for (let i = 0; i < checkMppt_All.length; i++) {
            checkMppt_All[i].checked = checkMppt[i].checked
        }
        for (let i = 0; i < selectModules_All.length; i++) {
            selectModules_All[i].click()
            selectModules_All[i].value = selectModules[i].value
        }
        for (let i = 0; i < checkString_All.length; i++) {
            if (checkString[i].checked) checkString_All[i].click()
                checkString_All[i].checked = checkString[i].checked
        }
        for (let i = 0; i < compriemntoFinal_All.length; i++) {
            compriemntoFinal_All[i].value = compriemntoFinal[i].value
        }
        for (let i = 0; i < compriemntoQuadro_All.length; i++) {
            compriemntoQuadro_All[i].value = compriemntoQuadro[i].value
        }
        for (let i = 0; i < unidades_All.length; i++) {
             unidades_All[i].innerHTML = `<option value="${unidades[i].value}">${unidades[i].value}</option>`
           unidades_All[i].click()
            unidades_All[i].value = unidades[i]?.value
        }
        for (let i = 0; i < p_unidades_All.length; i++) {
            p_unidades_All[i].textContent = p_unidades[i]?.textContent
        }
        for (let i = 0; i < azimute_All.length; i++) {
            azimute_All[i].value = azimute[i]?.value
        }
        for (let i = 0; i < p_azimute_All.length; i++) {
            p_azimute_All[i].textContent = p_azimute[i]?.textContent
        }
        for (let i = 0; i < inclincao_All.length; i++) {
            inclincao_All[i].value = inclincao[i]?.value ? inclincao[i]?.value : ''
        }
        for (let i = 0; i < p_inclincao_All.length; i++) {
            p_inclincao_All[i].textContent = p_inclincao[i]?.textContent
        }
        for (let i = 0; i < potenciaM_All.length; i++) {
            potenciaM_All[i].textContent = potenciaM[i]?.textContent
        }
        for (let i = 0; i < potenciaMppt_All.length; i++) {
            potenciaMppt_All[i].textContent = potenciaMppt[i]?.textContent
        }
        for (let i = 0; i < correnteStr_All.length; i++) {
            correnteStr_All[i].textContent = correnteStr[i]?.textContent
        }
        for (let i = 0; i < correnteMppt_All.length; i++) {
            correnteMppt_All[i].textContent = correnteMppt[i]?.textContent
        }
        for (let i = 0; i < tensao_All.length; i++) {
            tensao_All[i].textContent = tensao[i]?.textContent
        }
        for (let i = 0; i < secaoN_All.length; i++) {
            secaoN_All[i].value = secaoN[i]?.value
        }
        for (let i = 0; i < secaoP_All.length; i++) {
            secaoP_All[i].textContent = secaoP[i]?.textContent
        }
        for (let i = 0; i < comprimento_All.length; i++) {
            comprimento_All[i].value = comprimento[i]?.value
        }
        for (let i = 0; i < tensaoPorce_All.length; i++) {
            tensaoPorce_All[i].textContent = tensaoPorce[i]?.textContent
        }
        for (let i = 0; i < PerdasCabos_All.length; i++) {
            PerdasCabos_All[i].textContent = PerdasCabos[i]?.textContent
        }
        for (let i = 0; i < structureType_All.length; i++) {
            structureType_All[i].value = structureType[i]?.value
        }}
    }



    const setValuesDB = () => {

        //Pegando Elemento completo para setar valores nas funções do memorial descritivo
        props.setElementConfigSystem(config_system_element.current)

        //pegando elementos para setar valores na vaiaveis de estado
    /*    props.P_setCheckMppt(document.querySelectorAll('#checkMppt'))
        props.P_setSelectModules(document.querySelectorAll('.select_modules'))
        props.P_setCheckString(document.querySelectorAll('.checkStr'))
        props.P_setUnidades(document.querySelectorAll('.unidade'))
        props.P_setP_unidades(document.querySelectorAll('.p-unidade'))
        props.P_setAzimute(document.querySelectorAll('#azimute'))
        props.P_setP_azimute(document.querySelectorAll('.p-azimute'))
        props.P_setInclincao(document.querySelectorAll('.inclinacao'))
        props.P_setP_inclincao(document.querySelectorAll('.p-inclinacao'))
        props.P_setPotenciaM(document.querySelectorAll('.potenciaM'))
        props.P_setPotenciaMppt(document.querySelectorAll('.potenciaMppt'))
        props.P_setCorrenteStr(document.querySelectorAll('.corrente'))
        props.P_setCorrenteMppt(document.querySelectorAll('.correnteMppt'))
        props.P_setTensao(document.querySelectorAll('.tensao'))
        props.P_setSecaoN(document.querySelectorAll('.secaoN'))
        props.P_setSecaoP(document.querySelectorAll('.p-secaoN'))
        props.P_setComprimento(document.querySelectorAll('#comprimento'))
        props.P_setTensaoPorce(document.querySelectorAll('.tensaoPorce'))
        props.P_setPerdasCabos(document.querySelectorAll('#perdas_cabos'))
        props.P_setComprimentoFinal(document.querySelectorAll('.comprimentoFinal'))
        props.P_setComprimentoQuadro(document.querySelectorAll('.comprimento-quadro'))
        props.P_setStructureType(document.querySelectorAll('.structure-type'))

*/
        //passando valores para array
       const CheckMppt = document.querySelectorAll('#checkMppt')
       const SelectModules = document.querySelectorAll('.select_modules')
       const CheckString = document.querySelectorAll('.checkStr')
       const Unidades = document.querySelectorAll('.unidade')
       const P_unidades = document.querySelectorAll('.p-unidade')
       const Azimute = document.querySelectorAll('#azimute')
       const P_azimute = document.querySelectorAll('.p-azimute')
       const Inclincao = document.querySelectorAll('.inclinacao')
       const P_inclincao = document.querySelectorAll('.p-inclinacao')
       const PotenciaM = document.querySelectorAll('.potenciaM')
       const PotenciaMppt = document.querySelectorAll('.potenciaMppt')
       const CorrenteStr = document.querySelectorAll('.corrente')
       const CorrenteMppt = document.querySelectorAll('.correnteMppt')
       const Tensao = document.querySelectorAll('.tensao')
       const SecaoN = document.querySelectorAll('.secaoN')
       const SecaoP = document.querySelectorAll('.p-secaoN')
       const Comprimento = document.querySelectorAll('#comprimento')
       const TensaoPorce = document.querySelectorAll('.tensaoPorce')
       const PerdasCabos = document.querySelectorAll('#perdas_cabos')
       const ComprimentoFinal = document.querySelectorAll('.comprimentoFinal')
       const ComprimentoQuadro = document.querySelectorAll('.comprimento-quadro')
       const StructureType = document.querySelectorAll('.structure-type')


        //passando valores para array 

        const arrayCheckMppt = []
        const arraySelectModules = []
        const arrayCheckString = []
        const arrayUnidades = []
        const arrayP_unidades = []
        const arrayAzimute = []
        const arrayP_azimute = []
        const arrayInclincao = []
        const arrayP_inclincao = []
        const arrayPotenciaM = []
        const arrayPotenciaMppt = []
        const arrayCorrenteStr = []
        const arrayCorrenteMppt = []
        const arrayTensao = []
        const arraySecaoN = []
        const arraySecaoP = []
        const arrayComprimento = []
        const arrayTensaoPorce = []
        const arrayPerdasCabos = []
        const arrayComprimentoFinal = []
        const arrayComprimentoQuadro = []
        const arrayStructureType = []

        for (let i = 0; i < CheckMppt.length; i++) {
            arrayCheckMppt.push({ checked: CheckMppt[i].checked })
        }
        for (let i = 0; i < SelectModules.length; i++) {
            arraySelectModules.push({ value: SelectModules[i].value })
        }
        for (let i = 0; i < CheckString.length; i++) {
            arrayCheckString.push({ checked: CheckString[i].checked })
        }
        for (let i = 0; i < Unidades.length; i++) {
            arrayUnidades.push({ value: Unidades[i].value })
        }
        for (let i = 0; i < P_unidades.length; i++) {
            arrayP_unidades.push({ textContent: P_unidades[i].textContent })
        }
        for (let i = 0; i < Azimute.length; i++) {
            arrayAzimute.push({ value: Azimute[i].value })
        }
        for (let i = 0; i < P_azimute.length; i++) {
            arrayP_azimute.push({ textContent: P_azimute[i].textContent })
        }
        for (let i = 0; i < Inclincao.length; i++) {
            arrayInclincao.push({ value: Inclincao[i].value })
        }
        for (let i = 0; i < P_inclincao.length; i++) {
            arrayP_inclincao.push({ textContent: P_inclincao[i].textContent })
        }
        for (let i = 0; i < PotenciaM.length; i++) {
            arrayPotenciaM.push({ textContent: PotenciaM[i].textContent })
        }
        for (let i = 0; i < PotenciaMppt.length; i++) {
            arrayPotenciaMppt.push({ textContent: PotenciaMppt[i].textContent })
        }
        for (let i = 0; i < CorrenteStr.length; i++) {
            arrayCorrenteStr.push({ textContent: CorrenteStr[i].textContent })
        }
        for (let i = 0; i < CorrenteMppt.length; i++) {
            arrayCorrenteMppt.push({ textContent: CorrenteMppt[i].textContent })
        }
        for (let i = 0; i < Tensao.length; i++) {
            arrayTensao.push({ textContent: Tensao[i].textContent })
        }
        for (let i = 0; i < SecaoN.length; i++) {
            arraySecaoN.push({ value: SecaoN[i].value })
        }
        for (let i = 0; i < SecaoP.length; i++) {
            arraySecaoP.push({ textContent: SecaoP[i].textContent })
        }
        for (let i = 0; i < Comprimento.length; i++) {
            arrayComprimento.push({ value: Comprimento[i].value })
        }
        for (let i = 0; i < TensaoPorce.length; i++) {
            arrayTensaoPorce.push({ textContent: TensaoPorce[i].textContent })
        }
        for (let i = 0; i < PerdasCabos.length; i++) {
            arrayPerdasCabos.push({ textContent: PerdasCabos[i].textContent })
        }
        for (let i = 0; i < ComprimentoFinal.length; i++) {
            arrayComprimentoFinal.push({ value: ComprimentoFinal[i].value })
        }
        for (let i = 0; i < ComprimentoQuadro.length; i++) {
            arrayComprimentoQuadro.push({ value: ComprimentoQuadro[i].value })
        }
        for (let i = 0; i < StructureType.length; i++) {
            arrayStructureType.push({ value: StructureType[i].value })
        }

        //setando valores nas variaveis de estado
        props.P_setCheckMppt(arrayCheckMppt)
        props.P_setSelectModules(arraySelectModules)
        props.P_setCheckString(arrayCheckString)
        props.P_setUnidades(arrayUnidades)
        props.P_setP_unidades(arrayP_unidades)
        props.P_setAzimute(arrayAzimute)
        props.P_setP_azimute(arrayP_azimute)
        props.P_setInclincao(arrayInclincao)
        props.P_setP_inclincao(arrayP_inclincao)
        props.P_setPotenciaM(arrayPotenciaM)
        props.P_setPotenciaMppt(arrayPotenciaMppt)
        props.P_setCorrenteStr(arrayCorrenteStr)
        props.P_setCorrenteMppt(arrayCorrenteMppt)
        props.P_setTensao(arrayTensao)
        props.P_setSecaoN(arraySecaoN)
        props.P_setSecaoP(arraySecaoP)
        props.P_setComprimento(arrayComprimento)
        props.P_setTensaoPorce(arrayTensaoPorce)
        props.P_setPerdasCabos(arrayPerdasCabos)
        props.P_setComprimentoFinal(arrayComprimentoFinal)
        props.P_setComprimentoQuadro(arrayComprimentoQuadro)
        props.P_setStructureType(arrayStructureType)





    }

    const [state, setState] = useState(true)
    if (state) {

        setTimeout(() => {

           getValuesDB()
            //função para calcular a potência do padrão de entrada
            potenciaInstaladaQuadroPadrao(
                (
                    props.data.service_type_demand === 'trifasico' ?
                        parseInt(props.data.disjuntor_demand)
                        :
                        ''
                            ||
                            props.data.service_type_demand === 'bifasico' ?
                            parseInt(props.data.disjuntor_demand)
                            :
                            ''
                                ||
                                props.data.service_type_demand === 'monofasico' ?
                                parseInt(props.data.disjuntor_demand)
                                :
                                ''
                )
                , props.data.service_type_demand
            )
            document.querySelector('.unidade').click()
            setState(false)

        }, 5);
    }





    //funções de manipulação de dados OBS: Algumas funções estão em português para melhor entendimento

    //função para tranforma string em número de forma eficiente
    const onlyNumbers = (string) => {
        if (string === undefined || string === null || string === '') {
            return 0
        }
        if (typeof string === 'number') {
            return string
        }
        var numbers = string.replace(',', '.')
        return parseFloat(numbers)
    }


    //função para determinar a quantidade de módulos por string
    const handleMaxModules = (faixaTensaoMPPT, tensaoMaximaSuportada, tensao_inicializacao, id_mod, index_inv, iMppt, iStr, value,) => {
        if (props.array_modules.length > 0) {
            var cont = document.querySelectorAll(`#unidade${index_inv}`).length
            if (!props.array_modules || id_mod === 0 || id_mod === '0') {
                for (let i = 0; i < cont; i++) {
                    // document.querySelectorAll(`#potenciaM${index_inv}`)[i].innerHTML = '0.000'
                    document.querySelector(`.unidade${index_inv}${iMppt}${0}`).innerHTML = `<option value="0">0</option>`
                }
                return
            }
            var modulo = props.array_modules.filter((data) => { return data.id_modules === id_mod })
            const newselectedInverters = props.array_inverters.map((data, i) => {
                if (i === index_inv) {
                    return {
                        ...data, id_modules: id_mod, module_m_o_v: modulo[0].maximum_operating_voltage, module_m_o_c: modulo[0].maximum_operating_current, module_maximum_power: modulo[0].maximum_power
                    }
                }
                return data
            })
            props.P_setSelectedInverter(newselectedInverters)

            if (faixaTensaoMPPT === undefined || faixaTensaoMPPT === '' || faixaTensaoMPPT === 0) return
            let Tmin = parseInt(faixaTensaoMPPT.split('-')[0])
            let Tmax = parseInt(faixaTensaoMPPT.split('-')[1])
            let TMS = parseInt(tensaoMaximaSuportada)
            let TI = parseInt(tensao_inicializacao)

            let TCA = onlyNumbers(modulo[0].open_circuit_voltage_modules)
            let TOP = onlyNumbers(modulo[0].maximum_operating_voltage)
            let arrayModulos = [0]

            if (!Tmin || !Tmax || !TCA || Tmin === 0 || Tmax === 0 || TCA === 0 || Tmin === undefined || Tmax === undefined || TCA === undefined || Tmin === '' || Tmax === '' || TCA === '') {
                return arrayModulos = [0]
            }
            let maxModulosOP = Math.floor(Tmax / TOP)
            let maxModulosCA = Math.floor(TMS / TCA)

            let minModulosOP = Math.round(Tmin / TOP)
            let minModulosCA = Math.round(TI / TCA)

            let maxModulos = Math.min(maxModulosOP, maxModulosCA)
            let minModulos = Math.max(minModulosOP, minModulosCA)

            for (let i = minModulos; i <= maxModulos; i++) {
                arrayModulos.push(i)
            }

            arrayModulos = arrayModulos.map((data) => { return `<option value="${data}">${data}</option>` })
            for (let i = 0; i < cont; i++) {
                document.querySelector(`.unidade${index_inv}${iMppt}${0}`).innerHTML = arrayModulos
            }
            document.querySelector(`.unidade${index_inv}${iMppt}${0}`).value = value
        }
        else {
            // ainda sem necessidade de implementação
        }
    }

    //função para comparar a quantidade de módulos por string e ajustar a soma de módulos
    const compararArrayModulos = (index, id_mod) => {

        if (id_mod === 0 || id_mod === '0') {
            let newCountModules = props.array_count_modules.map((mod, index) => {
                if (mod.id_modules === sumStr[1]) {
                    let soma = sumStr[0]
                    if (isNaN(sumStr[0])) soma = 0
                    let sub = mod.qtd_selecionada - soma
                    return { ...mod, qtd_selecionada: sub }
                }
                return mod
            })
            props.P_setCountMod(newCountModules)
            return
        }

        //if (index === undefined) return
        let array = []
        props.array_modules.map((mod) => {
            array.push(mod.id_modules)
        })
        let arrayId = document.querySelectorAll(`#select_modules${index}`)
        let newArray = []
        for (let i = 0; i < arrayId.length; i++) {
            newArray.push(arrayId[i].value)
        }

        let resultFilter = []
        let resultArray = newArray.filter((data) => {
            return array.filter((data2) => {
                if (parseInt(data) === data2) {
                    if (resultFilter.includes(data)) return
                    resultFilter.push(parseInt(data))
                    return parseInt(data)
                }
            })

        })
        if (resultFilter.length === 0) {
            let newCountModules = props.array_count_modules.map((mod, index) => {

                if (mod.id_modules === array[index]) {
                    return { ...mod, qtd_selecionada: 0 }
                }
                return mod
            })
            props.P_setCountMod(newCountModules)
        }
        resultFilter.map((data) => {
            if (!array.includes(data)) {

                props.array_count_modules.map((mod, index) => {
                    if (mod.id_modules === data) {
                        let newCountModules = props.array_count_modules.map((mod, index) => {
                            if (mod.id_modules === data) {
                                return { ...mod, qtd_selecionada: 0 }
                            }
                            return mod
                        })
                        props.P_setCountMod(newCountModules)

                    }
                })



            }
        })



    }

    const handleCountModules = (id_mod, value, op, index) => {
        compararArrayModulos(index, id_mod)

        if (id_mod) {
            const newCountModules = props.array_count_modules.map((mod, index) => {
                if (mod.id_modules === id_mod) {
                    let unidades_mppt = document.querySelectorAll(`.unidades_mppt${id_mod}`)
                    let p_unidades_mppt = document.querySelectorAll(`.p-unidades_mppt${id_mod}`)
                    let cont1 = 0
                    let cont2 = 0
                    let result
                    if (!value) cont2 = 0
                    for (let i = 0; i < unidades_mppt.length; i++) {

                        cont1 = cont1 + (isNaN(parseInt(unidades_mppt[i].value)) ? 0 : parseInt(unidades_mppt[i].value))


                    }
                    for (let i = 0; i < p_unidades_mppt.length; i++) {
                        cont2 = cont2 + (isNaN(parseInt(p_unidades_mppt[i].innerHTML)) ? 0 : parseInt(p_unidades_mppt[i].innerHTML))
                    }

                    if (op) {
                        let sub = value + (-value * 2)
                        cont2 = cont2 + (sub)
                    } else {
                        if (value) {
                            cont2 = cont2 + value
                        }
                    }
                    if (id_mod === 0 || id_mod === '0') {
                        cont1 = 0
                        cont2 = 0
                    }
                    result = cont1 + cont2

                    return { ...mod, qtd_selecionada: result }
                }
                return mod
            })
            props.P_setCountMod(newCountModules)
        }
    }

    //função para limitar a corrente de entrada por string do inversor
    const limiteDeCorrente = (c_mppt, m_i_c, m_s_c_c, i_mppt) => {
        let m_i_c_ = parseInt(m_i_c.split(' ')[i_mppt])
        let m_s_c_c_ = parseInt(m_s_c_c.split(' ')[i_mppt])

        if (m_i_c_ === undefined || m_i_c_ === '' || m_i_c_ === 0 && !isNaN(c_mppt)) return `${c_mppt}`
        if (m_s_c_c_ === undefined || m_s_c_c_ === '' || m_s_c_c_ === 0 && !isNaN(c_mppt)) return `${c_mppt}`
        if (c_mppt === undefined || c_mppt === '' || c_mppt === 0 || isNaN(c_mppt)) return `<p style="font-size:8pt"><span style="color:green; font-size:8pt"> 0.00 </span> / ${m_i_c_} - ${m_s_c_c_}</p>`
        if (m_i_c === undefined || m_i_c === '' || m_i_c === 0 && c_mppt !== undefined) return c_mppt

        if (c_mppt < m_i_c_ && c_mppt < m_s_c_c_) return `<p style="font-size:8pt"><span style="color:green; font-size:8pt">${c_mppt}</span> / ${m_i_c_} - ${m_s_c_c_}</p>`
        if (c_mppt > m_i_c_ && c_mppt < m_s_c_c_) {
            message('Corrente máxima de entrada do inversor atingida!')
            return `<p style="font-size:8pt"><span style="color:orange; font-size:8pt">${c_mppt}</span> / ${m_i_c_} - ${m_s_c_c_}</p>`
        }
        if (c_mppt > m_i_c_ && c_mppt > m_s_c_c_ - 4 && c_mppt < m_s_c_c_) {
            message('Você está próximo da corrente de curto circuito do inversor!')
            return `<p style="font-size:8pt"><span style="color:red; font-size:8pt">${c_mppt}</span> / ${m_i_c_} - ${m_s_c_c_}</p>`
        }
        if (c_mppt > m_s_c_c_) {
            message('Você ultrapassou a corrente de curto circuito do inversor!')

            return `<p style="font-size:8pt"><span style="color:red; font-size:8pt">${c_mppt}</span> / ${m_i_c_} - ${m_s_c_c_}</p>`
        }
    }


    //função para contar a quantidade de módulos por string
    const valoresPorModulos = (index) => {
        let unidades = document.querySelectorAll(`#unidade${index}`)
        let p_unidades = document.querySelectorAll(`.p-unidade${index}`)
        let selectModules = document.querySelectorAll(`#select_modules${index}`)
        

    //Separando Modulos
    let array = []
    for (let i = 0; i < selectModules.length; i++) {
      array.push(selectModules[i].value)
    }
    let resultIdMod = [...new Set(array)]

    //Separando valores por modulo
    let arrayMod = []
    let arrayPuni = []
    for (let i = 0; i < selectModules.length; i++) {
      if(selectModules[i]?.classList[0].slice(-2) === unidades[i]?.classList[1].slice(-2) && selectModules[i].value !== '0'&& !isNaN(parseInt(unidades[i].value)) ){
        arrayMod.push({id: selectModules[i].value, valueUni: parseInt(unidades[i].value)})
    }
    for (let j = 0; j < p_unidades.length; j++) {
      if(p_unidades[j]?.classList[1].slice(-2) === unidades[i]?.classList[1].slice(-2) ){
        arrayPuni.push({id: p_unidades[j]?.classList[5].replace(/[^0-9]/g, ''), valueUni: parseInt(p_unidades[j].textContent)})
    }
    }
}



let arrayConcat = arrayMod.concat(arrayPuni)

  let resultadoFinal = arrayConcat.reduce((acc, item) => {
    // Se o ID já existe no acumulador, soma o valor
    if (acc[item.id]) {
      acc[item.id] += item.valueUni;
    } else {
      // Se não existe, cria uma nova entrada no acumulador
      acc[item.id] = item.valueUni;
    }
    return acc;
  }, {});

  let arrayFinal = []
  for(let i = 0; i< resultIdMod.length;i++){
    arrayFinal.push({id:resultIdMod[i],valor:resultadoFinal[resultIdMod[i]]})
  }

  /*
  let usinaFotovoltaica = []
  props.array_modules.map((data) => {
    arrayFinal.map((data2) => {
      if (data.id_modules === parseInt(data2.id)) {
        usinaFotovoltaica.push({id_module:data.id_modules ,brand_modules:data.brand_modules, model:data.model_modules, maximum_power:parseInt(data.maximum_power), qtd:data2.valor})
      }
    })
  })*/


    let usinaFotovoltaica = [];

    props.array_modules.forEach(data => {
  
      const matchingData2 = arrayFinal.find(data2 => data.id_modules === parseInt(data2.id));
   
   //   document.getElementById(`qtd-mod-${matchingData2.id}`).innerHTML = matchingData2.valor
      if (matchingData2) {
        usinaFotovoltaica.push({
          id_module: data.id_modules,
          brand_modules: data.brand_modules,
          model: data.model_modules,
          maximum_power: parseInt(data.maximum_power),
          qtd: matchingData2.valor,
          module_dimensions: data.module_dimensions,

        });
      }
    });
 

if(document.querySelector(`.fullQTD${index}`)) document.querySelector(`.fullQTD${index}`).innerHTML = arrayFinal.reduce((acc, item) => acc + item.valor,0)
if(document.querySelector(`.fullPower${index}`)) document.querySelector(`.fullPower${index}`).innerHTML = usinaFotovoltaica.reduce((acc, item) => acc + item.maximum_power,0)
  let newSystemValues = props.array_inverters?.map((inv, i) => {
    if (  i === index ) {
      return { ...inv, usinaFotovoltaica}
    }
    return inv
  })

  props.P_setSelectedInverter(newSystemValues)
return usinaFotovoltaica
    }


    //função para calcular a queda de tensão
    const tensaoPorce = (secaoNominal, comprimento, unidade, corrente, id_mod, type_connection) => {
        if (secaoNominal === undefined || comprimento === undefined || unidade === undefined || corrente === undefined || id_mod === undefined) return '0.000'
        if (secaoNominal === 0 || comprimento === 0 || unidade === 0 || corrente === 0 || id_mod === 0) return '0.000'
        return props.array_modules.map((modulos) => {
            if (modulos.id_modules === parseInt(id_mod)) {
                let m_o_v = modulos.maximum_operating_voltage

                secaoNominal = secaoNominal ? parseInt(secaoNominal) : 6
                comprimento = comprimento ? parseInt(comprimento) : 1
                unidade = unidade ? parseInt(unidade) : 0
                corrente = corrente ? onlyNumbers(corrente) : 0
                m_o_v = m_o_v ? m_o_v : 0

                var tensao = unidade * onlyNumbers(m_o_v)
                var ohm = 0
                if (secaoNominal === 1.5) ohm = comprimento * 0.00133
                if (secaoNominal === 2.5) ohm = comprimento * 0.00798
                if (secaoNominal === 4) ohm = comprimento * 0.00495
                if (secaoNominal === 6) ohm = comprimento * 0.00330
                if (secaoNominal === 10) ohm = comprimento * 0.00191
                if (secaoNominal === 16) ohm = comprimento * 0.00121
                if (secaoNominal === 25) ohm = comprimento * 0.00078
                if (secaoNominal === 35) ohm = comprimento * 0.000554
                if (secaoNominal === 50) ohm = comprimento * 0.000396
                if (secaoNominal === 70) ohm = comprimento * 0.000272
                if (secaoNominal === 95) ohm = comprimento * 0.000206
                if (secaoNominal === 120) ohm = comprimento * 0.000161
                if (secaoNominal === 150) ohm = comprimento * 0.000129
                if (secaoNominal === 185) ohm = comprimento * 0.000106
                if (secaoNominal === 240) ohm = comprimento * 0.0000801
                if (secaoNominal === 300) ohm = comprimento * 0.0000641
                if (secaoNominal === 400) ohm = comprimento * 0.0000486
                if (secaoNominal === 500) ohm = comprimento * 0.0000384

                var tensaoQ = ohm.toFixed(4) * corrente
                tensaoQ.toFixed(5)
                var tensaoP = (tensaoQ / tensao.toFixed(0)) * 100

                if (tensaoP === Infinity || tensaoP === 'Infinity' || isNaN(tensaoP)) return '0.000'
                return tensaoP?.toFixed(4)
            }
        })

    }

    //função para calcular as perdas nos cabos
    const perdasCabos = (secaoNominal, comprimento, corrente, type_connection) => {

        secaoNominal = secaoNominal ? parseInt(secaoNominal) : 6
        comprimento = comprimento ? parseInt(comprimento) : 1
        corrente = corrente ? onlyNumbers(corrente) : 1

        var ohm = 0
        if (secaoNominal === 1.5) ohm = comprimento * 0.00133
        if (secaoNominal === 2.5) ohm = comprimento * 0.00798
        if (secaoNominal === 4) ohm = comprimento * 0.00495
        if (secaoNominal === 6) ohm = comprimento * 0.00330
        if (secaoNominal === 10) ohm = comprimento * 0.00191
        if (secaoNominal === 16) ohm = comprimento * 0.00121
        if (secaoNominal === 25) ohm = comprimento * 0.00078
        if (secaoNominal === 35) ohm = comprimento * 0.000554
        if (secaoNominal === 50) ohm = comprimento * 0.000396
        if (secaoNominal === 70) ohm = comprimento * 0.000272
        if (secaoNominal === 95) ohm = comprimento * 0.000206
        if (secaoNominal === 120) ohm = comprimento * 0.000161
        if (secaoNominal === 150) ohm = comprimento * 0.000129
        if (secaoNominal === 185) ohm = comprimento * 0.000106
        if (secaoNominal === 240) ohm = comprimento * 0.0000801
        if (secaoNominal === 300) ohm = comprimento * 0.0000641
        if (secaoNominal === 400) ohm = comprimento * 0.0000486
        if (secaoNominal === 500) ohm = comprimento * 0.0000384

        var tensaoQ = ohm.toFixed(4) * corrente
        tensaoQ.toFixed(5)
        var perdas = corrente * tensaoQ
        if (perdas === Infinity || perdas === 'Infinity' || isNaN(perdas)) return '0.000'
        return perdas.toFixed(4)
    }

    //função para calcular a potência final dos inversores para o quadro
    const potenciaFinalInversoresQuadro = (inv, potencia_total_inv) => {
        console.log('potencia_total_inv', potencia_total_inv)
        if (!inv) return 0
        let output_power = parseInt(inv.output_power) / 1000
        let perdas_cabos = document.querySelectorAll('#perdas_cabos')
        let somaPerdasCabos = 0
        perdas_cabos.forEach((perda) => {
            if (perda.textContent === 'Infinity') perda.textContent = 0
            if (perda.textContent === '0.0036') perda.textContent = 0
            if (perda.textContent === '0.0000') perda.textContent = 0
            somaPerdasCabos += parseFloat(perda.textContent)
        })

        let resultPotencia = parseFloat(potencia_total_inv) - (somaPerdasCabos / 1000)
        let result = Math.min(resultPotencia, output_power)
        if (result === Infinity || isNaN(result)) return 0.00
        console.log('result', result)
        return result.toFixed(2)
    }

    //Somando a potência dos módulos por string do inversor
    const somaDePotencia = (index, m_p_p) => {

        let potencia = document.querySelectorAll(`.potenciaMppt${index}`)
        let total = 0
        for (let i = 0; i < potencia.length; i++) {
            if (!isNaN(parseFloat(potencia[i].innerHTML))) total = total + parseFloat(potencia[i].innerHTML)
        }

        if (total > m_p_p) {
            /*toast.error('Você ultrapassou o limite máximo do inversor!'
                , {
                    autoClose: 3000,
                    position: 'bottom-right'
                })*/

            message('Você ultrapassou o limite máximo do inversor!')
            return <span style={{ color: 'red' }}>{total.toFixed(3)}</span>

        }
        if (total > m_p_p - 3 && total < m_p_p) {
            /* toast.error('Você está próximo do limite máximo do inversor!'
                 , {
                     autoClose: 2500,
                     position: 'bottom-right'
                 })*/
            message('Você está próximo do limite máximo do inversor!')
            return <span style={{ color: 'red' }}>{total.toFixed(3)}</span>
        }
        if (total > m_p_p - 5 && total < m_p_p - 2) {
            /* message('Você está próximo da potência máxima do inversor!'
                 , {
                     autoClose: 2500,
                     position: 'bottom-right'
                 })*/
            message('Você está próximo da potência máxima do inversor!')
            return <span style={{ color: 'orange' }}>{total.toFixed(3)}</span>
        }
        if (total < m_p_p - 5) {

            return <span style={{ color: 'green' }}>{total.toFixed(3)}</span>
        }
    }

    //Retornando a corrente dos inversores para o quadro
    const correnteInversoresQuadro = (inversor, potencia_total_inv) => {
        if (!inversor) return 0
        if (inversor.type_of_network_connection === 'monofásico' || inversor.type_of_network_connection === 'monofasico') {
            return ((potenciaFinalInversoresQuadro(inversor, potencia_total_inv) * 1000) / 220).toFixed(2)
        }
        if (inversor.type_of_network_connection === 'bifásico' || inversor.type_of_network_connection === 'bifasico') {
            return ((potenciaFinalInversoresQuadro(inversor, potencia_total_inv) * 1000) / 660).toFixed(2)
        }
        if (inversor.type_of_network_connection === 'trifásico' || inversor.type_of_network_connection === 'trifasico') {
            return ((potenciaFinalInversoresQuadro(inversor, potencia_total_inv) * 1000) / 660).toFixed(2)
        }
    }

    //Retornando a tensão dos inversores
    const tipoDeConexaoInversor = (inversor) => {
        if (!inversor) return 0
        if (inversor.type_of_network_connection === 'monofásico'
            || inversor.type_of_network_connection === 'monofasico') return '220V'

        if (inversor.type_of_network_connection === 'bifásico'
            || inversor.type_of_network_connection === 'bifasico') return '220V'

        if (inversor.type_of_network_connection === 'trifásico'
            || inversor.type_of_network_connection === 'trifasico') return '220V/380V'

    }

    //Retornando a seção nominal dos inversores para o quadro
    const secaoNominalInversoresQuadro = (inversor, potencia_total_inv) => {
        console.log(potencia_total_inv)
        if (!inversor) return 0
        let corrente = parseFloat(correnteInversoresQuadro(inversor, potencia_total_inv))
        let correnteDisjuntorCA = corrente * 1.3
        let secaoNominal = 0
        let disjuntor = 0
        if (inversor.type_of_network_connection === 'trifásico' || inversor.type_of_network_connection === 'trifasico') {

            if (correnteDisjuntorCA <= 25) disjuntor = 25
            if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) disjuntor = 32
            if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) disjuntor = 40
            if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) disjuntor = 50
            if (correnteDisjuntorCA > 50 && correnteDisjuntorCA <= 63) disjuntor = 63
            if (correnteDisjuntorCA > 63 && correnteDisjuntorCA <= 70) disjuntor = 70
            if (correnteDisjuntorCA > 70 && correnteDisjuntorCA <= 80) disjuntor = 80
            if (correnteDisjuntorCA > 80 && correnteDisjuntorCA <= 90) disjuntor = 90
            if (correnteDisjuntorCA > 90 && correnteDisjuntorCA <= 100) disjuntor = 100
            if (correnteDisjuntorCA > 100 && correnteDisjuntorCA <= 125) disjuntor = 125
            if (correnteDisjuntorCA > 125 && correnteDisjuntorCA <= 150) disjuntor = 150
            if (correnteDisjuntorCA > 150 && correnteDisjuntorCA <= 175) disjuntor = 175
            if (correnteDisjuntorCA > 175 && correnteDisjuntorCA <= 200) disjuntor = 200
            if (correnteDisjuntorCA > 200 && correnteDisjuntorCA <= 225) disjuntor = 225
            if (correnteDisjuntorCA > 225 && correnteDisjuntorCA <= 250) disjuntor = 250
            if (correnteDisjuntorCA > 250 && correnteDisjuntorCA <= 300) disjuntor = 300
            if (correnteDisjuntorCA > 300 && correnteDisjuntorCA <= 350) disjuntor = 350
            if (correnteDisjuntorCA > 350 && correnteDisjuntorCA <= 400) disjuntor = 400
            if (correnteDisjuntorCA > 400 && correnteDisjuntorCA <= 450) disjuntor = 450
            if (correnteDisjuntorCA > 450 && correnteDisjuntorCA <= 500) disjuntor = 500

            if (disjuntor > 21 && disjuntor <= 28) secaoNominal = 4
            if (disjuntor > 28 && disjuntor <= 36) secaoNominal = 6
            if (disjuntor > 36 && disjuntor <= 50) secaoNominal = 10
            if (disjuntor > 50 && disjuntor <= 68) secaoNominal = 16
            if (disjuntor > 68 && disjuntor <= 89) secaoNominal = 25
            if (disjuntor > 89 && disjuntor <= 110) secaoNominal = 35
            if (disjuntor > 110 && disjuntor <= 134) secaoNominal = 50
            if (disjuntor > 134 && disjuntor <= 171) secaoNominal = 70
            if (disjuntor > 171 && disjuntor <= 207) secaoNominal = 95
            if (disjuntor > 207 && disjuntor <= 239) secaoNominal = 120
            if (disjuntor > 239 && disjuntor <= 275) secaoNominal = 150
            if (disjuntor > 275 && disjuntor <= 314) secaoNominal = 185
            if (disjuntor > 314 && disjuntor <= 370) secaoNominal = 240
            if (disjuntor > 370 && disjuntor <= 426) secaoNominal = 300
            if (disjuntor > 426 && disjuntor <= 510) secaoNominal = 400
            if (disjuntor > 510 && disjuntor <= 587) secaoNominal = 500

        }
        if (inversor.type_of_network_connection === 'monofásico' || inversor.type_of_network_connection === 'monofasico') {

            if (correnteDisjuntorCA <= 10) disjuntor = 10
            if (correnteDisjuntorCA > 10 && correnteDisjuntorCA <= 16) disjuntor = 16
            if (correnteDisjuntorCA > 16 && correnteDisjuntorCA <= 20) disjuntor = 20
            if (correnteDisjuntorCA > 20 && correnteDisjuntorCA <= 25) disjuntor = 25
            if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) disjuntor = 32
            if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) disjuntor = 40
            if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) disjuntor = 50

            if (disjuntor > 9 && disjuntor <= 24) secaoNominal = 2.5
            if (disjuntor > 24 && disjuntor <= 32) secaoNominal = 4
            if (disjuntor > 32 && disjuntor <= 41) secaoNominal = 6
            if (disjuntor > 41 && disjuntor <= 57) secaoNominal = 10

        }
        return secaoNominal

    }

    //Retornando a queda de tensão dos inversores para o quadro
    const quedaPorcentagemInversoresQuadro = (inversor, comprimento, secaoNominal, corrente) => {


        comprimento = comprimento ? parseInt(comprimento) : 0

        var ohm = 0.001

        if (secaoNominal === 1.5) ohm = comprimento * 0.00133
        if (secaoNominal === 2.5) ohm = comprimento * 0.00798
        if (secaoNominal === 4) ohm = comprimento * 0.00495
        if (secaoNominal === 6) ohm = comprimento * 0.00330
        if (secaoNominal === 10) ohm = comprimento * 0.00191
        if (secaoNominal === 16) ohm = comprimento * 0.00121
        if (secaoNominal === 25) ohm = comprimento * 0.00078
        if (secaoNominal === 35) ohm = comprimento * 0.000554
        if (secaoNominal === 50) ohm = comprimento * 0.000396
        if (secaoNominal === 70) ohm = comprimento * 0.000272
        if (secaoNominal === 95) ohm = comprimento * 0.000206
        if (secaoNominal === 120) ohm = comprimento * 0.000161
        if (secaoNominal === 150) ohm = comprimento * 0.000129
        if (secaoNominal === 185) ohm = comprimento * 0.000106
        if (secaoNominal === 240) ohm = comprimento * 0.0000801
        if (secaoNominal === 300) ohm = comprimento * 0.0000641
        if (secaoNominal === 400) ohm = comprimento * 0.0000486
        if (secaoNominal === 500) ohm = comprimento * 0.0000384




        var tensaoQ = ohm.toFixed(6) * corrente
        if (inversor.type_of_network_connection === 'monofásico'
            || inversor.type_of_network_connection === 'monofasico'
        ) var tensaoP = ((tensaoQ * 100)) / 220

        if (inversor.type_of_network_connection === 'bifásico'
            || inversor.type_of_network_connection === 'bifasico'
        ) var tensaoP = ((tensaoQ * 100)) / 380

        if (inversor.type_of_network_connection === 'trifásico'
            || inversor.type_of_network_connection === 'trifasico'
        ) var tensaoP = ((tensaoQ * 100)) / 380

        return tensaoP?.toFixed(4)
    }


    //Retornando as perdas nos cabos dos inversores para o quadro
    const perdasDeCabosInversoresQuadro = (inversor, comprimento, secaoNominal, corrente) => {

        comprimento = comprimento ? parseInt(comprimento) : 1
        var ohm = 0.001
        if (secaoNominal === 1.5) ohm = comprimento * 0.00133
        if (secaoNominal === 2.5) ohm = comprimento * 0.00798
        if (secaoNominal === 4) ohm = comprimento * 0.00495
        if (secaoNominal === 6) ohm = comprimento * 0.00330
        if (secaoNominal === 10) ohm = comprimento * 0.00191
        if (secaoNominal === 16) ohm = comprimento * 0.00121
        if (secaoNominal === 25) ohm = comprimento * 0.00078
        if (secaoNominal === 35) ohm = comprimento * 0.000554
        if (secaoNominal === 50) ohm = comprimento * 0.000396
        if (secaoNominal === 70) ohm = comprimento * 0.000272
        if (secaoNominal === 95) ohm = comprimento * 0.000206
        if (secaoNominal === 120) ohm = comprimento * 0.000161
        if (secaoNominal === 150) ohm = comprimento * 0.000129
        if (secaoNominal === 185) ohm = comprimento * 0.000106
        if (secaoNominal === 240) ohm = comprimento * 0.0000801
        if (secaoNominal === 300) ohm = comprimento * 0.0000641
        if (secaoNominal === 400) ohm = comprimento * 0.0000486
        if (secaoNominal === 500) ohm = comprimento * 0.0000384


        var tensaoQ = ohm.toFixed(6) * corrente
        var perda = tensaoQ * corrente
        if (perda === Infinity || perda === 'Infinity' || isNaN(perda)) return '0.000'
        return perda?.toFixed(4)
    }

    //Retornando a potência máxima 
    const potenciaMaximaQuadrosQuadro = () => {

        let potenciaFinal = document.querySelectorAll('.potenciaFinalInversoresQuadroCont')
        let perdasFinal = document.querySelectorAll('.perdasDeCabosInversoresQuadroCont')
        /* let check = document.querySelectorAll('.checkStr')
        check.forEach((check) => {
          check.addEventListener('click', () => {
            toast.success('Clique em calcular para atualizar o valor da potência máxima')
            }
            )})*/
        let somaPotencia = 0
        let somaPerdas = 0

        potenciaFinal.forEach((potencia) => {

            if (potencia.innerHTML === 'Infinity') potencia.innerHTML = 0
            if (potencia.innerHTML === '0.000') potencia.innerHTML = 0
            somaPotencia += parseFloat(potencia.innerHTML)
        })

        perdasFinal.forEach((perda) => {
            if (perda.innerHTML === 'Infinity') perda.innerHTML = 0
            if (perda.innerHTML === '0.000') perda.innerHTML = 0
            somaPerdas += parseFloat(perda.innerHTML)
        })

        let resultPotencia = somaPotencia - (somaPerdas / 1000)
        if (resultPotencia === Infinity || isNaN(resultPotencia)) return 0.00
        return resultPotencia.toFixed(2)

    }



    //Retornando a corrente dos quadros
    const correnteQuadrosQuadroGeral = (potencia, inversor) => {
        let contMono = 0
        let contTri = 0
        inversor.filter((inv) => {
            if (inv.type_of_network_connection === 'monofásico' || inv.type_of_network_connection === 'monofasico') {
                contMono++
            }
            if (inv.type_of_network_connection === 'trifásico' || inv.type_of_network_connection === 'trifasico') {
                contTri++
            }
        })
        let connection = ''
        if (contTri > 0) connection = 'trifásico'
        if (contTri === 0 && contMono > 0) connection = 'monofásico'

        if (connection === 'monofásico' || connection === 'monofasico') {
            return ((potencia * 1000) / 220).toFixed(2)
        }

        if (connection === 'trifásico' || connection === 'trifasico') {
            return ((potencia * 1000) / 660).toFixed(2)
        }
    }

    //Retornando a tensão dos quadros
    const tipoDeConexaoQuadrosQuadroGeral = (inversor) => {
        let contMono = 0
        let contTri = 0
        inversor.filter((inv) => {
            if (inv.type_of_network_connection === 'monofásico' || inv.type_of_network_connection === 'monofasico') {
                contMono++
            }
            if (inv.type_of_network_connection === 'trifásico' || inv.type_of_network_connection === 'trifasico') {
                contTri++
            }
        })
        let connection = ''
        if (contTri > 0) connection = 'trifásico'
        if (contTri === 0 && contMono > 0) connection = 'monofásico'

        if (connection === 'monofásico'
            || connection === 'monofasico') return '220V'

        if (connection === 'bifásico'
            || connection === 'bifasico') return '220V'

        if (connection === 'trifásico'
            || connection === 'trifasico') return '220V/380V'

    }


    //Retornando a seção nominal dos quadros
    const secaoNominalQuadrosQuadroGeral = (inversor, corrente) => {

        if (!inversor) return 0
        let contTri = 0
        inversor.filter((inv) => {

            if (inv.type_of_network_connection === 'trifásico' || inv.type_of_network_connection === 'trifasico') {
                contTri++
            }
        })


        let correnteDisjuntorCA = corrente * 1.3
        let secaoNominal = 0
        let disjuntor = 0
        if (contTri > 0) {

            if (correnteDisjuntorCA <= 25) disjuntor = 25
            if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) disjuntor = 32
            if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) disjuntor = 40
            if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) disjuntor = 50
            if (correnteDisjuntorCA > 50 && correnteDisjuntorCA <= 63) disjuntor = 63
            if (correnteDisjuntorCA > 63 && correnteDisjuntorCA <= 70) disjuntor = 70
            if (correnteDisjuntorCA > 70 && correnteDisjuntorCA <= 80) disjuntor = 80
            if (correnteDisjuntorCA > 80 && correnteDisjuntorCA <= 90) disjuntor = 90
            if (correnteDisjuntorCA > 90 && correnteDisjuntorCA <= 100) disjuntor = 100
            if (correnteDisjuntorCA > 100 && correnteDisjuntorCA <= 125) disjuntor = 125
            if (correnteDisjuntorCA > 125 && correnteDisjuntorCA <= 150) disjuntor = 150
            if (correnteDisjuntorCA > 150 && correnteDisjuntorCA <= 175) disjuntor = 175
            if (correnteDisjuntorCA > 175 && correnteDisjuntorCA <= 200) disjuntor = 200
            if (correnteDisjuntorCA > 200 && correnteDisjuntorCA <= 225) disjuntor = 225
            if (correnteDisjuntorCA > 225 && correnteDisjuntorCA <= 250) disjuntor = 250
            if (correnteDisjuntorCA > 250 && correnteDisjuntorCA <= 300) disjuntor = 300
            if (correnteDisjuntorCA > 300 && correnteDisjuntorCA <= 350) disjuntor = 350
            if (correnteDisjuntorCA > 350 && correnteDisjuntorCA <= 400) disjuntor = 400
            if (correnteDisjuntorCA > 400 && correnteDisjuntorCA <= 450) disjuntor = 450
            if (correnteDisjuntorCA > 450 && correnteDisjuntorCA <= 500) disjuntor = 500

            if (disjuntor > 21 && disjuntor <= 28) secaoNominal = 4
            if (disjuntor > 28 && disjuntor <= 36) secaoNominal = 6
            if (disjuntor > 36 && disjuntor <= 50) secaoNominal = 10
            if (disjuntor > 50 && disjuntor <= 68) secaoNominal = 16
            if (disjuntor > 68 && disjuntor <= 89) secaoNominal = 25
            if (disjuntor > 89 && disjuntor <= 110) secaoNominal = 35
            if (disjuntor > 110 && disjuntor <= 134) secaoNominal = 50
            if (disjuntor > 134 && disjuntor <= 171) secaoNominal = 70
            if (disjuntor > 171 && disjuntor <= 207) secaoNominal = 95
            if (disjuntor > 207 && disjuntor <= 239) secaoNominal = 120
            if (disjuntor > 239 && disjuntor <= 275) secaoNominal = 150
            if (disjuntor > 275 && disjuntor <= 314) secaoNominal = 185
            if (disjuntor > 314 && disjuntor <= 370) secaoNominal = 240
            if (disjuntor > 370 && disjuntor <= 426) secaoNominal = 300
            if (disjuntor > 426 && disjuntor <= 510) secaoNominal = 400
            if (disjuntor > 510 && disjuntor <= 587) secaoNominal = 500

            let potencia_intalada = (disjuntor * 660 * 0.92) / 1000
            if (document.querySelector('.potencia_instalada')) document.querySelector('.potencia_instalada').innerHTML = potencia_intalada.toFixed(2)

        }
        else {

            if (correnteDisjuntorCA <= 10) disjuntor = 10
            if (correnteDisjuntorCA > 10 && correnteDisjuntorCA <= 16) disjuntor = 16
            if (correnteDisjuntorCA > 16 && correnteDisjuntorCA <= 20) disjuntor = 20
            if (correnteDisjuntorCA > 20 && correnteDisjuntorCA <= 25) disjuntor = 25
            if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) disjuntor = 32
            if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) disjuntor = 40
            if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) disjuntor = 50



            if (disjuntor > 9 && disjuntor <= 24) secaoNominal = 2.5
            if (disjuntor > 24 && disjuntor <= 32) secaoNominal = 4
            if (disjuntor > 32 && disjuntor <= 41) secaoNominal = 6
            if (disjuntor > 41 && disjuntor <= 57) secaoNominal = 10
            let potencia_intalada = (disjuntor * 220 * 0.92) / 1000
            if (document.querySelector('.potencia_instalada')) document.querySelector('.potencia_instalada').innerHTML = potencia_intalada.toFixed(2)


        }
        return secaoNominal


    }


    //Retornando a queda de tensão dos quadros
    const quedaPorcentagemQuadro = (corrente, comprimento, secaoNominal, type_connection) => {


        comprimento = comprimento ? parseInt(comprimento) : 1
        var ohm = 0.001

        if (secaoNominal === 1.5) ohm = comprimento * 0.00133
        if (secaoNominal === 2.5) ohm = comprimento * 0.00798
        if (secaoNominal === 4) ohm = comprimento * 0.00495
        if (secaoNominal === 6) ohm = comprimento * 0.00330
        if (secaoNominal === 10) ohm = comprimento * 0.00191
        if (secaoNominal === 16) ohm = comprimento * 0.00121
        if (secaoNominal === 25) ohm = comprimento * 0.00078
        if (secaoNominal === 35) ohm = comprimento * 0.000554
        if (secaoNominal === 50) ohm = comprimento * 0.000396
        if (secaoNominal === 70) ohm = comprimento * 0.000272
        if (secaoNominal === 95) ohm = comprimento * 0.000206
        if (secaoNominal === 120) ohm = comprimento * 0.000161
        if (secaoNominal === 150) ohm = comprimento * 0.000129
        if (secaoNominal === 185) ohm = comprimento * 0.000106
        if (secaoNominal === 240) ohm = comprimento * 0.0000801
        if (secaoNominal === 300) ohm = comprimento * 0.0000641
        if (secaoNominal === 400) ohm = comprimento * 0.0000486
        if (secaoNominal === 500) ohm = comprimento * 0.0000384



        var tensaoQ = ohm.toFixed(6) * corrente
        if (type_connection === '220V') var tensaoP = ((tensaoQ * 100)) / 220

        if (type_connection === '220V/380V') var tensaoP = ((tensaoQ * 100)) / 380


        return tensaoP?.toFixed(4)
    }


    //Retornando as perdas nos cabos do quadro
    const perdasDeCabosQuadro = (comprimento, secaoNominal, corrente) => {

        comprimento = comprimento ? parseInt(comprimento) : 1
        var ohm = 0.001
        if (secaoNominal === 1.5) ohm = comprimento * 0.00133
        if (secaoNominal === 2.5) ohm = comprimento * 0.00798
        if (secaoNominal === 4) ohm = comprimento * 0.00495
        if (secaoNominal === 6) ohm = comprimento * 0.00330
        if (secaoNominal === 10) ohm = comprimento * 0.00191
        if (secaoNominal === 16) ohm = comprimento * 0.00121
        if (secaoNominal === 25) ohm = comprimento * 0.00078
        if (secaoNominal === 35) ohm = comprimento * 0.000554
        if (secaoNominal === 50) ohm = comprimento * 0.000396
        if (secaoNominal === 70) ohm = comprimento * 0.000272
        if (secaoNominal === 95) ohm = comprimento * 0.000206
        if (secaoNominal === 120) ohm = comprimento * 0.000161
        if (secaoNominal === 150) ohm = comprimento * 0.000129
        if (secaoNominal === 185) ohm = comprimento * 0.000106
        if (secaoNominal === 240) ohm = comprimento * 0.0000801
        if (secaoNominal === 300) ohm = comprimento * 0.0000641
        if (secaoNominal === 400) ohm = comprimento * 0.0000486
        if (secaoNominal === 500) ohm = comprimento * 0.0000384

        var tensaoQ = ohm.toFixed(6) * corrente
        var perda = tensaoQ * corrente
        if (perda === Infinity || perda === 'Infinity' || isNaN(perda)) return '0.000'
        return perda?.toFixed(4)
    }


    //Retornando a potência máxima do quadro para o padrão de entrada
    const potenciaInstaladaQuadroPadrao = (corrente, connection) => {
       
        if (corrente === 0 && document.querySelector('.secao-nominal-padrao') && document.querySelector('.potencia-kva') && document.querySelector('.potencia-kw')) {
            document.querySelector('.secao-nominal-padrao').innerHTML = 0
            document.querySelector('.potencia-kva').innerHTML = 0.00
            document.querySelector('.potencia-kw').innerHTML = 0.00
        }


        let correnteDisjuntorCA = corrente

        if (connection === 'trifásico' || connection === 'trifasico') {

            if (correnteDisjuntorCA === 25) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 16.45
                // document.querySelector('.potencia-kw').innerHTML = 19.05
                document.querySelector('.potencia-kw').innerHTML = (16.45 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 32) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 6
                document.querySelector('.potencia-kva').innerHTML = 21.06
                //document.querySelector('.potencia-kw').innerHTML = 24.00
                document.querySelector('.potencia-kw').innerHTML = (21.06 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 40) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 10
                document.querySelector('.potencia-kva').innerHTML = 26.30
                // document.querySelector('.potencia-kw').innerHTML = 30.00
                document.querySelector('.potencia-kw').innerHTML = (26.33 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 50) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 10
                document.querySelector('.potencia-kva').innerHTML = 32.90
                // document.querySelector('.potencia-kw').innerHTML = 37.00
                document.querySelector('.potencia-kw').innerHTML = (32.91 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 63) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 16
                document.querySelector('.potencia-kva').innerHTML = 41.40
                // document.querySelector('.potencia-kw').innerHTML = 47.00
                document.querySelector('.potencia-kw').innerHTML = (41.46 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 80) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 25
                document.querySelector('.potencia-kva').innerHTML = 52.60
                // document.querySelector('.potencia-kw').innerHTML = 60.00
                document.querySelector('.potencia-kw').innerHTML = (52.65 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 100) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 32
                document.querySelector('.potencia-kva').innerHTML = 65.80
                //  document.querySelector('.potencia-kw').innerHTML = 75.00
                document.querySelector('.potencia-kw').innerHTML = (65.82 * 0.92).toFixed(2)

            }

        }

        if (connection === 'bifásico' || connection === 'bifasico') {

            if (correnteDisjuntorCA === 20) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 8.80
                // document.querySelector('.potencia-kw').innerHTML = 10.00
                document.querySelector('.potencia-kw').innerHTML = (8.8 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 25) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 11.00
                //  document.querySelector('.potencia-kw').innerHTML = 12.50
                document.querySelector('.potencia-kw').innerHTML = (11.00 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 32) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 14.08
                //  document.querySelector('.potencia-kw').innerHTML = 16.00
                document.querySelector('.potencia-kw').innerHTML = (14.08 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 40) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 6
                document.querySelector('.potencia-kva').innerHTML = 17.60
                //  document.querySelector('.potencia-kw').innerHTML = 20.00
                document.querySelector('.potencia-kw').innerHTML = (17.60 * 0.92).toFixed(2)
            }


        }
        if (connection === 'monofásico' || connection === 'monofasico') {

            if (correnteDisjuntorCA === 16) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 2.5
                document.querySelector('.potencia-kva').innerHTML = 3.52
                //  document.querySelector('.potencia-kw').innerHTML = 4.00
                document.querySelector('.potencia-kw').innerHTML = (3.52 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 20) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 2.5
                document.querySelector('.potencia-kva').innerHTML = 4.40
                //  document.querySelector('.potencia-kw').innerHTML = 5.00
                document.querySelector('.potencia-kw').innerHTML = (4.40 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 25) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 5.50
                //  document.querySelector('.potencia-kw').innerHTML = 6.25
                document.querySelector('.potencia-kw').innerHTML = (5.50 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 32) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 4
                document.querySelector('.potencia-kva').innerHTML = 7.04
                //  document.querySelector('.potencia-kw').innerHTML = 8.00
                document.querySelector('.potencia-kw').innerHTML = (7.04 * 0.92).toFixed(2)

            }
            if (correnteDisjuntorCA === 40) {
                document.querySelector('.secao-nominal-padrao').innerHTML = 6
                document.querySelector('.potencia-kva').innerHTML = 8.80
                //  document.querySelector('.potencia-kw').innerHTML = 10.00
                document.querySelector('.potencia-kw').innerHTML = (8.80 * 0.92).toFixed(2)

            }
         

        }


    }





    

    //Validando os campos 
    const validateFields = () => {
        let status = []
        const comprimento = document.querySelectorAll('#comprimento')
        const azimute = document.querySelectorAll('#azimute')
        const inclinacao = document.querySelectorAll('#inclinacao')
        const comprimentoFinal = document.querySelectorAll('.comprimentoFinal')
        const comprimentoQuadro = document.querySelectorAll('.comprimento-quadro')
       
        let filterComprimento = Array.from(comprimento)?.filter((comp) => {
   
        return !comp?.className?.includes('disabled')
        }
        )
        filterComprimento?.map((comp) => {      
                if (parseInt(comp.value) < 1 || comp.value === '') {
                    message('Preencha os campos destacados')
                   comp.style.border = '1px solid red'
                   status.push(false)
                }else{
                    status.push(true)
                    comp.style.border = '1px solid #000'
                }
        })
        let filterAzimute = Array.from(azimute)?.filter((azi) => {
            return !azi?.className?.includes('disabled')
        }
        )
        filterAzimute?.map((azi) => {
            if (parseInt(azi.value) === 0 || azi.value === '') {
                message('Preencha os campos destacados')  
                azi.style.border = '1px solid red'
                status.push(false)
            }else{
                status.push(true)
                azi.style.border = '1px solid #000'
            }
        })
        let filterInclinacao = Array.from(inclinacao)?.filter((incl) => {
            return !incl?.className?.includes('disabled')
        }
        )
        filterInclinacao?.map((incl) => {
            if (parseInt(incl.value) === 0 || incl.value === '') {
                message('Preencha os campos destacados')
                incl.style.border = '1px solid red'
                status.push(false)
            }else{
                incl.style.border = '1px solid #000'
                status.push(true)
            }
        })

        let filterComprimentoDisabled = Array.from(comprimento)?.filter((comp) => {
   
        return comp?.className?.includes('disabled')
        }
        )
        filterComprimentoDisabled?.map((comp) => {       

                    comp.style.border = '1px solid #000'     
        })
        let filterAzimuteDisabled = Array.from(azimute)?.filter((azi) => {
            return azi?.className?.includes('disabled')
            
        }
        )
        filterAzimuteDisabled?.map((azi) => {
            azi.style.border = '1px solid #000'
        })
        let filterInclinacaoDisabled = Array.from(inclinacao)?.filter((incl) => {
            return incl?.className?.includes('disabled')
        }
        )
        filterInclinacaoDisabled?.map((incl) => {
            incl.style.border = '1px solid #000'
        })
 
        Array.from(comprimentoFinal)?.filter((comp) => {
            if(comp.value === '0' || comp.value ==='' ){
                comp.style.border = '1px solid red'
                status.push(false)
                
            }else{
                comp.style.border = '1px solid #000'
                status.push(true)
            }
    })
    Array.from(comprimentoQuadro)?.filter((comp) => {
        if(comp.value === '0' || comp.value ==='' ){
            comp.style.border = '1px solid red'
            status.push(false)
        }else{
            comp.style.border = '1px solid #000'
            status.push(true)   
        }
}
)

props.array_count_modules.map((count, index) => {
        if(count.qtd === count.qtd_selecionada ){
            status.push(true)
          if(document.querySelector(`.countModResult${index}`)) document.querySelector(`.countModResult${index}`).style.color = '#000'
        }else{
            status.push(false)
           if(document.querySelector(`.countModResult${index}`)) document.querySelector(`.countModResult${index}`).style.color = 'red'
        }
        
    })

   
    let resultStauts = new Set([...status])
    resultStauts = Array.from(resultStauts)
    resultStauts = resultStauts.filter((res) => res === false)
    if(resultStauts.length === 0) resultStauts = true
    if(resultStauts.length > 0) resultStauts = false
    if(resultStauts) setValid(true)
    }
setTimeout(() => {
    validateFields()
}, 10)
document.querySelector('#btn-next')?.addEventListener('click', () => {
    if(valid){
       
        props.handleTestDataConfigSystem(valid)
        setValuesDB()
        setValid(false)
    }
    })
    
    return (
        <div ref={config_system_element} id='config-system' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>

            <div style={{ minHeight: 35 }}>
                <Alert id='message' severity="warning" style={{ width: '100%', display: 'none' }} />
            </div>


            <div style={{ background: 'rgb(0, 24, 73)', color: '#fff' }}>
                <th style={{ minWidth: '40px', textAlign: 'center', fontSize: '8pt', paddingTop: '10px' }}>ID</th>
                <th style={{ minWidth: '100px', textAlign: 'center', fontSize: '8pt' }}>Inversor</th>
                <th style={{ minWidth: '100px', textAlign: 'center', fontSize: '8pt' }}>Modulo</th>
                <th style={{ minWidth: '50px', textAlign: 'center', fontSize: '8pt' }}>MPPT</th>
                <th style={{ minWidth: '80px', textAlign: 'right', fontSize: '8pt' }}>String</th>
                <th style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }}>Módulos <br /> QTD(Uni.)</th>
                <th style={{ minWidth: '70px', textAlign: 'center', fontSize: '8pt' }}>Azimute°</th>
                <th style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }}>Inclinação°</th>
                <th style={{ minWidth: '140px', textAlign: 'center', fontSize: '8pt' }}>Potência(kW) <br /> String&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mppt</th>
                <th style={{ minWidth: '140px', textAlign: 'center', fontSize: '8pt', paddingRight: '40px' }}>Corrente(Ah)<br /> <span style={{ minWidth: '50px', paddingRight: '70px' }}>String</span> <span style={{ minWidth: '80px', marginLeft: '-30px' }}>Mppt</span></th>
                <th style={{ minWidth: '80px', textAlign: 'left', fontSize: '8pt' }}>Tensão(V)</th>
                <th style={{ minWidth: '60px', textAlign: 'left', fontSize: '8pt', }}>Seção <br /> Nominal <br /> (mm&#x00B2;)</th>
                <th style={{ minWidth: '60px', textAlign: 'left', fontSize: '8pt', paddingRight: '0px' }}>Comp.(m)</th>
                <th style={{ minWidth: '50px', textAlign: 'center', fontSize: '8pt' }}>Queda <br />Tensão(%)&nbsp;</th>
                <th style={{ minWidth: '50px', textAlign: 'center', fontSize: '8pt' }}>&nbsp;Perdas<br />Cabos(W)</th>

                {/*<th><MdDelete /></th>*/}
            </div>
            <div className='table-system'
                style={{
                    minHeight: '350px',
                    maxHeight: '350px',
                    minWidth: '1210px',

                    maxWidth: '1210px',
                    margin: '0px 0px 20px 0px',
                    boxShadow: '0 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '15px',


                }}>

                <div  >


                    {
                        props.array_inverters?.map((inv, index) => {
                            return (

                                <tr style={{ borderBottom: '1px solid #000' }} key={index}>
                                    <td style={{ paddingLeft: '12px', minWidth: '40px', maxWidth: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '10px', fontSize: '8pt' }}>
                                        {index + 1}
                                    </td>
                                    <td style={{ paddingLeft: '12px', minWidth: '120px', maxWidth: '120px', textAlign: 'left', fontSize: '8pt', paddingRight: '30px' }}>
                                        <p style={{ minHeight: '50px', fontSize: '8pt' }}>{inv.brand} <br /> {inv.model}</p>

                                    </td>
                                    <ul>
                                        {

props.array_mppt?.map((inverter, indexInverter) => {

                                                return (
                                                    <div key={indexInverter + 1}>
                                                        {indexInverter === index && inverter.mppt?.map((mppt, indexMppt) => {

                                                            return (

                                                                <div key={index + indexMppt}>

                                                                    {mppt?.map((str, indexStr) => {

                                                                        return (

                                                                            <li key={index + indexMppt + indexStr}  >
                                                                                <div key={index + indexMppt + indexStr} id='line' className={`line${index}${indexMppt}${indexStr}`}>
                                                                                    <td style={{ fontSize: '7pt', maxHeight: '10px' }}>
                                                                                        {/*Centralizando os selects modulos e para os demais campos não existirem */}
                                                                                        {
                                                                                            //str.string !== Math.round(mppt.length / 2) 
                                                                                            str.string !== 1
                                                                                                ||
                                                                                                indexMppt === 0
                                                                                                ? ''
                                                                                                :
                                                                                                <div
                                                                                                    className={`div-check${index}${indexMppt}${indexStr}`}
                                                                                                    style={{ display: 'flex', marginLeft: '-19px' }}>
                                                                                                    {
                                                                                                        //checkbox para selecionar para ativar ou desativar o mppt
                                                                                                    }
                                                                                                    <input
                                                                                                        type='checkbox'
                                                                                                        id={`checkMppt`}
                                                                                                        className={`checkMppt${index}${indexMppt}${0} checkMppt${index} checkMppt${index}${indexMppt}`}
                                                                                                        onClick={(e) => {
                                                                                                            setTimeout(()=>{
                                                                                                                valoresPorModulos(index)
                                                                                                                }, 100)
                                                                                                            if (e.target.checked) {

                                                                                                                document.querySelector(`.unidade${index}${indexMppt}`).value = 0
                                                                                                                let checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                                for (let i = 0; i < checkStr.length; i++) {
                                                                                                                    checkStr[i].checked = false
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i}`)) document.querySelector(`.corrente${index}${indexMppt}${i}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${i}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i}`)) document.querySelector(`.corrente${index}${indexMppt}${i}`).innerHTML = '0'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${i}`)) document.querySelector(`.tensao${index}${indexMppt}${i}`).innerHTML = `0V`
                                                                                                                    if (document.querySelector(`.azimute${index}${indexMppt}${i}`)) document.querySelector(`.azimute${index}${indexMppt}${i}`).value = ''
                                                                                                                    if (document.querySelector(`.inclinacao${index}${indexMppt}${i}`)) document.querySelector(`.inclinacao${index}${indexMppt}${i}`).value = ''
                                                                                                                    if (document.querySelector(`.p-unidade${index}${indexMppt}${i}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-azimute${index}${indexMppt}${i}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i}`).innerHTML = 0
                                                                                                                }
                                                                                                                let newcountModules = props.array_count_modules.map((mod, index) => {
                                                                                                                    if (
                                                                                                                        mod.id_modules === sumStr[1]
                                                                                                                    ) {
                                                                                                                        return { ...mod, qtd_selecionada: 0 }
                                                                                                                    }
                                                                                                                    return mod
                                                                                                                })
                                                                                                                props.P_setCountMod(newcountModules)
                                                                                                            }
                                                                                                        }}
                                                                                                        onChange={(e) => {

                                                                                                            setWork_around(e.target.value)
                                                                                                            if (e.target.checked && indexStr !== 0) {
                                                                                                                handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), false)
                                                                                                            } else {
                                                                                                                if (!e.target.checked && indexStr !== 0) {
                                                                                                                    handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), true)
                                                                                                                }
                                                                                                            }

                                                                                                            if (e.target.checked === false) {

                                                                                                                //ajuste de valores dos modulos
                                                                                                                let unidades = document.querySelectorAll(`.unidade${index}${indexMppt}`)
                                                                                                                let p_unidades = document.querySelectorAll(`.p-unidade${index}${indexMppt}`)
                                                                                                                let soma = 0
                                                                                                                for (let i = 0; i < unidades.length; i++) {
                                                                                                                    if (isNaN(parseInt(unidades[i]?.value)) || parseInt(unidades[i].value === '')) unidades[i].value = 0

                                                                                                                    soma += parseInt(unidades[i].value)
                                                                                                                }
                                                                                                                for (let i = 0; i < p_unidades.length; i++) {
                                                                                                                    if (isNaN(parseInt(p_unidades[i].value) || parseInt(p_unidades[i].value === ''))) p_unidades[i].value = 0

                                                                                                                    soma += parseInt(p_unidades[i].textContent)
                                                                                                                }
                                                                                                                if (isNaN(soma)) soma = 0

                                                                                                                let newcountModules = props.array_count_modules.map((mod, index) => {
                                                                                                                    if (mod.id_modules === sumStr[1]) {
                                                                                                                        if (mod.qtd_selecionada === 0) soma = 0
                                                                                                                        let sub = mod.qtd_selecionada - soma
                                                                                                                        return { ...mod, qtd_selecionada: sub }
                                                                                                                    }
                                                                                                                    return mod
                                                                                                                })
                                                                                                                props.P_setCountMod(newcountModules)


                                                                                                                if (props.array_modules.length > 1) {
                                                                                                                    document.querySelector(`.select_modules${index}${indexMppt}`).value = 0
                                                                                                                    document.querySelector(`.unidade${index}${indexMppt}`).innerHTML = ``
                                                                                                                    let checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                                    for (let i = 0; i < checkStr.length - 1; i++) {
                                                                                                                        if (document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i + 1].checked = false
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                        if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                        }
                                                                                                                    }
                                                                                                                    document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                    document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)

                                                                                                                } else {
                                                                                                                    document.querySelector(`.unidade${index}${indexMppt}`).innerHTML = ``
                                                                                                                    let checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                                    for (let i = 0; i < checkStr.length - 1; i++) {
                                                                                                                        if (document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i + 1].checked = false
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                        if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                        }
                                                                                                                    }
                                                                                                                    document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                    document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)

                                                                                                                }
                                                                                                            }

                                                                                                        }}
                                                                                                    />&nbsp;&nbsp;

                                                                                                    {
                                                                                                        //select para selecionar que será usado no mppt
                                                                                                    }
                                                                                                    <select style={{ maxWidth: '80px', fontSize: '7pt' }}

                                                                                                        disabled={
                                                                                                            // str.string === 1 
                                                                                                            str.string !== 1
                                                                                                                ?
                                                                                                                ''
                                                                                                                :
                                                                                                                (
                                                                                                                    document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                        && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                        ? true : false)
                                                                                                        }
                                                                                                        id={`select_modules${index}`}
                                                                                                        className={`select_modules${index}${indexMppt} select_modules`}
                                                                                                        onClick={(e) => {
                                                                                                            setTimeout(()=>{
                                                                                                                valoresPorModulos(index)
                                                                                                                }, 100)
                                                                                                            setWork_around(e.target.value)

                                                                                                            //ajuste dos valores de modulos
                                                                                                            if (sumStr[1] !== parseInt(e.target.value)) {
                                                                                                                let newcountModules = props.array_count_modules.map((mod, index) => {
                                                                                                                    if (mod.id_modules === sumStr[1]) {
                                                                                                                        let soma = sumStr[0]
                                                                                                                        if (isNaN(sumStr[0])) soma = 0
                                                                                                                        if (mod.qtd_selecionada === 0) soma = 0
                                                                                                                        let sub = mod.qtd_selecionada - soma
                                                                                                                        return { ...mod, qtd_selecionada: sub }
                                                                                                                    }
                                                                                                                    return mod
                                                                                                                })
                                                                                                                props.P_setCountMod(newcountModules)
                                                                                                            }

                                                                                                            let unidades = document.querySelectorAll(`.unidade${index}${indexMppt}`)
                                                                                                            let p_unidades = document.querySelectorAll(`.p-unidade${index}${indexMppt}`)
                                                                                                            let soma = 0
                                                                                                            for (let i = 0; i < unidades.length; i++) {
                                                                                                                if (isNaN(parseInt(unidades[i].value)) || parseInt(unidades[i].value === '')) unidades[i].value = 0

                                                                                                                soma += parseInt(unidades[i].value)
                                                                                                            }
                                                                                                            for (let i = 0; i < p_unidades.length; i++) {
                                                                                                                if (isNaN(parseInt(p_unidades[i].value) || parseInt(p_unidades[i].value === ''))) p_unidades[i].value = 0

                                                                                                                soma += parseInt(p_unidades[i].textContent)
                                                                                                            }
                                                                                                            setSumStr([soma, parseInt(e.target.value)])
                                                                                                        }}

                                                                                                        onChange={(e) => {

                                                                                                            setWork_around(e.target.value)

                                                                                                            handleMaxModules(inv.mppt_voltage_range, inv.maximum_supported_voltage, inv.start_up_voltage, parseInt(e.target.value), index, indexMppt, indexStr)

                                                                                                            handleCountModules(parseInt(e.target.value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), false, index)


                                                                                                            var checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                            if (parseInt(e.target.value) === 0) {
                                                                                                                for (let i = 0; i < checkStr.length; i++) {
                                                                                                                    document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                    if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''

                                                                                                                    if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            if (indexMppt === 0) {
                                                                                                                document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`).checked = false
                                                                                                                for (let i = 0; i < checkStr.length; i++) {
                                                                                                                    if (checkStr[i].checked) {
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                        if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''

                                                                                                                        if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                        }
                                                                                                                    }
                                                                                                                    document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                    document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                                }
                                                                                                            } else {
                                                                                                                if (indexMppt !== 0) {

                                                                                                                    for (let i = 0; i < checkStr.length - 1; i++) {
                                                                                                                        if (document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0].checked = false
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i + 1].checked = false
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                        if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                        if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                        if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                        if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                        if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''

                                                                                                                        if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                        }
                                                                                                                    }
                                                                                                                    document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                    document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                                }
                                                                                                            }

                                                                                                        }}>
                                                                                                        {props.array_modules.length !== 1 ? <option value="0">Selecione</option> : ''}
                                                                                                        {
                                                                                                            props.array_modules?.map((mod, index) => (
                                                                                                                <option key={index} value={mod.id_modules}>  {mod.brand_modules} - {mod.model_modules}</option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </select>
                                                                                                </div>}


                                                                                        {/*Primeiro mppt não possue checkbox */}
                                                                                        {
                                                                                            //indexMppt === 0 && str.string === Math.round(mppt.length / 2)
                                                                                            indexMppt === 0 && str.string === 1
                                                                                                ?
                                                                                                <select

                                                                                                    style={{ maxWidth: '80px', fontSize: '7pt' }}
                                                                                                    id={`select_modules${index}`}
                                                                                                    className={`select_modules${index}${indexMppt} select_modules `}

                                                                                                    onClick={(e) => {
                                                                                                        setTimeout(()=>{
                                                                                                            valoresPorModulos(index)
                                                                                                            }, 100)
                                                                                                        setWork_around(e.target.value)

                                                                                                        //ajuste dos valores de modulos
                                                                                                        if (sumStr[1] !== parseInt(e.target.value)) {
                                                                                                            let newcountModules = props.array_count_modules.map((mod, index) => {
                                                                                                                if (mod.id_modules === sumStr[1]) {
                                                                                                                    let soma = sumStr[0]
                                                                                                                    if (mod.qtd_selecionada === 0) soma = 0
                                                                                                                    if (isNaN(sumStr[0])) soma = 0
                                                                                                                    let sub = mod.qtd_selecionada - soma
                                                                                                                    return { ...mod, qtd_selecionada: sub }
                                                                                                                }
                                                                                                                return mod
                                                                                                            })
                                                                                                            props.P_setCountMod(newcountModules)
                                                                                                        }

                                                                                                        let unidades = document.querySelectorAll(`.unidade${index}${indexMppt}`)
                                                                                                        let p_unidades = document.querySelectorAll(`.p-unidade${index}${indexMppt}`)
                                                                                                        let soma = 0
                                                                                                        for (let i = 0; i < unidades.length; i++) {
                                                                                                            if (isNaN(parseInt(unidades[i].value)) || parseInt(unidades[i].value === '')) unidades[i].value = 0
                                                                                                            soma += parseInt(unidades[i].value)
                                                                                                        }
                                                                                                        for (let i = 0; i < p_unidades.length; i++) {
                                                                                                            if (isNaN(parseInt(p_unidades[i].value) || parseInt(p_unidades[i].value === ''))) p_unidades[i].value = 0
                                                                                                            soma += parseInt(p_unidades[i].textContent)
                                                                                                        }

                                                                                                        if (isNaN(soma)) soma = 0
                                                                                                        setSumStr([soma, parseInt(e.target.value)])
                                                                                                    }}

                                                                                                    onChange={(e) => {

                                                                                                        setWork_around(e.target.value)
                                                                                                        handleMaxModules(inv.mppt_voltage_range, inv.maximum_supported_voltage, inv.start_up_voltage, parseInt(e.target.value), index, indexMppt, indexStr)
                                                                                                        handleCountModules(parseInt(e.target.value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), false, index)
                                                                                                        var checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                        if (parseInt(e.target.value) === 0) {
                                                                                                            for (let i = 0; i < checkStr.length; i++) {
                                                                                                                document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0.00'
                                                                                                                if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''

                                                                                                                if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                }
                                                                                                            }
                                                                                                            document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                            document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                        }

                                                                                                        if (indexMppt === 0) {
                                                                                                            document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`).checked = false
                                                                                                            for (let i = 0; i < checkStr.length; i++) {
                                                                                                                if (checkStr[i].checked) {
                                                                                                                    document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0.00'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                    if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                    }
                                                                                                                }
                                                                                                                document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (indexMppt !== 0) {


                                                                                                                for (let i = 0; i < checkStr.length - 1; i++) {
                                                                                                                    if (document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0].checked = false
                                                                                                                    document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0].checked = false
                                                                                                                    document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i + 1].checked = false
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${0}`)) document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${0}`)) document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0.00'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${0}`)) document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                                    if (document.querySelector(`.azimute${index}${indexMppt}${0}`)) document.querySelector(`.azimute${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.inclinacao${index}${indexMppt}${0}`)) document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = ''
                                                                                                                    if (document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-unidade${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                    if (document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`)) document.querySelector(`.potenciaM${index}${indexMppt}${i + 1}`).innerHTML = '0.000'
                                                                                                                    if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = '0'
                                                                                                                    if (document.querySelector(`.tensao${index}${indexMppt}${i + 1}`)) document.querySelector(`.tensao${index}${indexMppt}${i + 1}`).innerHTML = `0V`
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${0}`).value = ''

                                                                                                                    if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                        document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                    }
                                                                                                                }
                                                                                                                document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                            }
                                                                                                        }
                                                                                                    }}>

                                                                                                    {props.array_modules.length !== 1 ? <option value="0">Selecione</option> : ''}

                                                                                                    {
                                                                                                        props.array_modules?.map((mod, index) => (
                                                                                                            <option key={index} value={mod.id_modules}>  {mod.brand_modules} - {mod.model_modules}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select> : <p style={{ width: '80px', maxHeight: '10px' }}></p>}
                                                                                    </td>
                                                                                    {
                                                                                        //Identificação por numeração do mppt
                                                                                    }

                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', paddingRight: '30px', fontSize: '8pt', maxHeight: '10px' }}>
                                                                                        {
                                                                                            //str.string !== Math.round(mppt.length / 2) ? '' : indexMppt + 1
                                                                                        }
                                                                                        {str.string !== 1 ? '' : indexMppt + 1}
                                                                                    </td>
                                                                                    {
                                                                                        //Checkbox para pegar valores da string 1 do mppt 
                                                                                    }

                                                                                    <td style={{ maxWidth: '50px', textAlign: 'center', fontSize: '8pt', maxHeight: '10px' }}>
                                                                                        {str.string === 1 && indexMppt === 0 ? ''
                                                                                            : <div className={`div-check${index}${indexMppt}${indexStr} `}
                                                                                                style={{ display: 'flex', textAlign: 'center', width: '100px', marginLeft: '-10px' }}>

                                                                                                <input
                                                                                                    disabled=
                                                                                                    {
                                                                                                        str.string === 1
                                                                                                            ?
                                                                                                            (
                                                                                                                indexMppt === 0 ? ''
                                                                                                                    :
                                                                                                                    (
                                                                                                                        document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                            && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                            ? true : false
                                                                                                                    )
                                                                                                            )
                                                                                                            :
                                                                                                            (
                                                                                                                document.querySelector(`.unidade${index}${indexMppt}${0}`)
                                                                                                                    && parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value) !== 0
                                                                                                                    && document.querySelector(`.unidade${index}${indexMppt}${0}`).value !== ''
                                                                                                                    ? false : true
                                                                                                            )
                                                                                                    }

                                                                                                    type='checkbox'
                                                                                                    onClick={e =>{
                                                                                                        setTimeout(()=>{
                                                                                                        valoresPorModulos(index)
                                                                                                        }, 100)
                                                                                                        setWork_around(e.target.value)}}
                                                                                                    id={`checkStr${index}${indexMppt}`}

                                                                                                    className=
                                                                                                    {
                                                                                                        `checkStr${index}${indexMppt}${indexStr}
                                                                                                             checkStr                                                                   
                                                                                                            ${str.string === 1 ? `leftStr1 checkLeft${index}${indexMppt}` : ''}
                                                                                                            ${str.string === 1 ?
                                                                                                            (
                                                                                                                indexMppt === 0 ? '' : (
                                                                                                                    document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                        && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                        ? 'disabled' : '')
                                                                                                            )

                                                                                                            :

                                                                                                            (
                                                                                                                document.querySelector(`.unidade${index}${indexMppt}${0}`)
                                                                                                                    && parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value) !== 0
                                                                                                                    && document.querySelector(`.unidade${index}${indexMppt}${0}`).value !== ''
                                                                                                                    ?
                                                                                                                    ''
                                                                                                                    : 'disabled'
                                                                                                            )}
                                                      checkStr
                                                        `}


                                                                                                    onChange={e => {

                                                                                                        setWork_around(e.target.value)
                                                                                                        if (e.target.checked && indexStr !== 0) {
                                                                                                            handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), false)
                                                                                                        } else {
                                                                                                            if (!e.target.checked && indexStr !== 0) {
                                                                                                                handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt}${0}`).value), true)
                                                                                                            }
                                                                                                        }
                                                                                                        if (e.target.checked && indexStr !== 0) {

                                                                                                            document.querySelector(`.p-unidade${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.unidade${index}${indexMppt}${0}`).value
                                                                                                            document.querySelector(`.p-azimute${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.azimute${index}${indexMppt}${0}`).value
                                                                                                            document.querySelector(`.p-inclinacao${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value
                                                                                                            // document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.potenciaM${index}${indexMppt}${0}`).textContent
                                                                                                            document.querySelector(`.corrente${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.corrente${index}${indexMppt}${0}`).textContent
                                                                                                            document.querySelector(`.tensao${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.tensao${index}${indexMppt}${0}`).textContent
                                                                                                            document.querySelector(`.p-secao_nominal${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.secao_nominal${index}${indexMppt}${0}`).value
                                                                                                            document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.potenciaM${index}${indexMppt}${0}`).textContent
                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`).disabled = false
                                                                                                            document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`).classList.remove('disabled')

                                                                                                            let potencia = []
                                                                                                            for (let i = 0; i < mppt.length; i++) {
                                                                                                                potencia.push(document.querySelectorAll(`.potenciaM${index}${indexMppt}${i}`)[0].innerHTML)
                                                                                                            }
                                                                                                            let cont = potencia.reduce((acc, item) => acc + parseFloat(item), 0)
                                                                                                            document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = cont.toFixed(3)

                                                                                                            let corrente = []
                                                                                                            for (let i = 0; i < mppt.length; i++) {
                                                                                                                corrente.push(document.querySelectorAll(`.corrente${index}${indexMppt}${i}`)[0].innerHTML)
                                                                                                            }
                                                                                                            let contCorrente = corrente.reduce((acc, item) => acc + parseFloat(item), 0)
                                                                                                            document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente(contCorrente.toFixed(2), inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)

                                                                                                        } else {

                                                                                                            if (indexStr !== 0) {


                                                                                                                let cont = parseFloat(document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML) - parseFloat(document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML)
                                                                                                                document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = cont.toFixed(3)

                                                                                                                let c_mppt = document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).textContent

                                                                                                                let contCorrente = parseFloat(c_mppt.split('/')[0]) - parseFloat(document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML)
                                                                                                                document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente(contCorrente.toFixed(2), inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)


                                                                                                                document.querySelector(`.p-unidade${index}${indexMppt}${indexStr}`).innerHTML = 0.000
                                                                                                                document.querySelector(`.p-azimute${index}${indexMppt}${indexStr}`).innerHTML = 0
                                                                                                                document.querySelector(`.p-inclinacao${index}${indexMppt}${indexStr}`).innerHTML = 0
                                                                                                                //  document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = '0.000'
                                                                                                                document.querySelector(`.corrente${index}${indexMppt}${indexStr}`).innerHTML = 0
                                                                                                                document.querySelector(`.tensao${index}${indexMppt}${indexStr}`).innerHTML = '0V'
                                                                                                                document.querySelector(`.p-secao_nominal${index}${indexMppt}${indexStr}`).innerHTML = 0
                                                                                                                document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`).disabled = true
                                                                                                                document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`).classList.add('disabled')
                                                                                                                document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`).value = ''


                                                                                                            }
                                                                                                        }


                                                                                                        if (e.target.checked && indexStr === 0) {
                                                                                                            handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt - 1}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value), false)
                                                                                                        } else {
                                                                                                            if (!e.target.checked && indexStr === 0) {
                                                                                                                handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt - 1}`).value), parseInt(document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value), true)
                                                                                                            }
                                                                                                        }


                                                                                                        if (e.target.checked && indexStr === 0) {
                                                                                                            /*     document.querySelector(`.unidade${index}${indexMppt}${indexStr}`).innerHTML = `
                                                                                                       <option value="${document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value}">${document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value}</option>
                                                                                                       `*/
                                                                                                            if (props.array_modules.length === 1) {
                                                                                                                handleMaxModules(inv.mppt_voltage_range, inv.maximum_supported_voltage, inv.start_up_voltage, props.array_modules[0].id_modules, index, indexMppt, indexStr, e.target.value)
                                                                                                                document.querySelector(`.unidade${index}${indexMppt}${indexStr}`).value = document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value
                                                                                                            }
                                                                                                            if (props.array_modules.length > 1) {
                                                                                                                if (parseInt(document.querySelector(`.select_modules${index}${indexMppt - 1}`).value) !== 0) {

                                                                                                                    handleMaxModules(inv.mppt_voltage_range, inv.maximum_supported_voltage, inv.start_up_voltage, parseInt(document.querySelector(`.select_modules${index}${indexMppt - 1}`).value), index, indexMppt, indexStr, e.target.value)
                                                                                                                }
                                                                                                                document.querySelector(`.select_modules${index}${indexMppt}`).value = document.querySelector(`.select_modules${index}${indexMppt - 1}`).value
                                                                                                            }
                                                                                                            document.querySelector(`.unidade${index}${indexMppt}${indexStr}`).value = document.querySelector(`.unidade${index}${indexMppt - 1}${0}`).value
                                                                                                            document.querySelector(`.azimute${index}${indexMppt}${indexStr}`).value = document.querySelector(`.azimute${index}${indexMppt - 1}${0}`).value
                                                                                                            document.querySelector(`.inclinacao${index}${indexMppt}${indexStr}`).value = document.querySelector(`.inclinacao${index}${indexMppt - 1}${0}`).value
                                                                                                            document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.potenciaM${index}${indexMppt - 1}${0}`).textContent
                                                                                                            document.querySelector(`.corrente${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.corrente${index}${indexMppt - 1}${0}`).textContent
                                                                                                            document.querySelector(`.tensao${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.tensao${index}${indexMppt - 1}${0}`).textContent
                                                                                                            document.querySelector(`.secao_nominal${index}${indexMppt}${indexStr}`).value = document.querySelector(`.secao_nominal${index}${indexMppt - 1}${0}`).value
                                                                                                            document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = document.querySelector(`.potenciaM${index}${indexMppt - 1}${0}`).textContent
                                                                                                            document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML
                                                                                                            document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente(parseFloat(document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML).toFixed(2), inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)

                                                                                                        } else {
                                                                                                            if (indexStr === 0) {
                                                                                                                setWork_around(e.target.value)
                                                                                                                let check = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                                for (let i = 1; i < check.length; i++) {
                                                                                                                    if (check[i].checked) {
                                                                                                                        document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                    }
                                                                                                                }
                                                                                                                if (props.array_modules.length === 1) {
                                                                                                                    document.querySelector(`.unidade${index}${indexMppt}${indexStr}`).value = 0
                                                                                                                }
                                                                                                                if (props.array_modules.length > 1) {
                                                                                                                    document.querySelector(`.select_modules${index}${indexMppt}`).value = 0
                                                                                                                }

                                                                                                                document.querySelector(`.unidade${index}${indexMppt}${indexStr}`).innerHTML = `<option value="0">0</option>`
                                                                                                                document.querySelector(`.azimute${index}${indexMppt}${indexStr}`).value = ''
                                                                                                                document.querySelector(`.inclinacao${index}${indexMppt}${indexStr}`).value = ''
                                                                                                                document.querySelector(`.potenciaM${index}${indexMppt}${indexStr}`).innerHTML = '0.000'
                                                                                                                document.querySelector(`.corrente${index}${indexMppt}${indexStr}`).innerHTML = 0
                                                                                                                document.querySelector(`.tensao${index}${indexMppt}${indexStr}`).innerHTML = '0V'
                                                                                                                document.querySelector(`.secao_nominal${index}${indexMppt}${indexStr}`).value = 6
                                                                                                                document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                                document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                <p style={{ textAlign: 'right', width: '50px', paddingRight: '5px', fontSize: '8pt' }}>{str.string}</p>
                                                                                            </div>}
                                                                                        {str.string === 1 && indexMppt === 0 ? <p style={{ textAlign: 'right', width: '50px', paddingRight: '5px', fontSize: '8pt' }}>{str.string}</p> : ''}
                                                                                    </td>
                                                                                    {
                                                                                        //Checkbox para pegar valores do mppt anterior
                                                                                    }
                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }} >
                                                                                        {str.string === 1 ?
                                                                                            <select
                                                                                                style={{ maxWidth: '80px', fontSize: '8pt' }}
                                                                                                disabled={
                                                                                                    indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? true : false
                                                                                                        )
                                                                                                }
                                                                                                onClick={e => {
                                                                                                    setWork_around(e.target.value)

                                                                                                    setTimeout(()=>{
                                                                                                    valoresPorModulos(index)
                                                                                                    }, 100)
                                                                                                    if (props.array_modules.length === 1) handleMaxModules(inv.mppt_voltage_range, inv.maximum_supported_voltage, inv.start_up_voltage, props.array_modules[0].id_modules, index, indexMppt, indexStr, e.target.value)
                                                                                                }
                                                                                                }

                                                                                                id={`unidade${index}`}
                                                                                                className={`
                                                unidade${index}${indexMppt}${indexStr} 
                                                unidade${index}${indexMppt} 
                                                
                                                unidade${index} 
                                                unidadesAll${index}
                                                uni_idMod${document.querySelector(`#select_modules${index}`)?.value}
                                                unidade
                                                ${str.string !== 1 ? 'disabled' : ''} unidades_mppt${document.querySelector(`.select_modules${index}${indexMppt}`) ? parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value) : 0} `}
                                                                                                onChange={(e) => {
                                                                                                    setWork_around(e.target.value)
                                                                                                    e.preventDefault()
                                                                                                    var modulo = props.array_modules.filter((data) => { return data.id_modules === parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value) })
                                                                                                    handleCountModules(parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value))

                                                                                                    document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = onlyNumbers(modulo[0].maximum_operating_current)
                                                                                                    document.querySelector(`.tensao${index}${indexMppt}${indexStr}`).innerHTML = `${(onlyNumbers(modulo[0].maximum_operating_voltage) * parseInt(e.target.value)).toFixed(0)}V`
                                                                                                    document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = (parseInt(e.target.value) * (parseInt(modulo[0].maximum_power) / 1000)).toFixed(3)
                                                                                                    document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = (parseInt(e.target.value) * (parseInt(modulo[0].maximum_power) / 1000)).toFixed(3)
                                                                                                    document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente(parseFloat(document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML).toFixed(2), inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)

                                                                                                    if (parseInt(e.target.value) === 0) {

                                                                                                        document.querySelector(`.azimute${index}${indexMppt}${0}`).value = 0
                                                                                                        document.querySelector(`.inclinacao${index}${indexMppt}${0}`).value = 0
                                                                                                        document.querySelector(`.corrente${index}${indexMppt}${0}`).innerHTML = '0'
                                                                                                        document.querySelector(`.potenciaM${index}${indexMppt}${0}`).innerHTML = '0.000'
                                                                                                        document.querySelector(`.tensao${index}${indexMppt}${0}`).innerHTML = `0V`
                                                                                                        document.querySelector(`.potenciaMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = '0.000'
                                                                                                        document.querySelector(`.correnteMppt${index}${indexMppt}${Math.round((mppt.length / 2) - 1)}`).innerHTML = limiteDeCorrente('', inv.maximum_input_current, inv.maximum_short_circuit_current, indexMppt)
                                                                                                    }
                                                                                                    var checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                    if (indexMppt === 0) {

                                                                                                        document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`).checked = false
                                                                                                        for (let i = 0; i < checkStr.length; i++) {
                                                                                                            if (checkStr[i].checked) {

                                                                                                                document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i].checked = false
                                                                                                                if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0

                                                                                                                if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    } else {
                                                                                                        if (indexMppt !== 0) {
                                                                                                            for (let i = 0; i < checkStr.length - 1; i++) {
                                                                                                                if (document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt + 1}`)[0].checked = false
                                                                                                                if (document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0]) document.querySelectorAll(`#checkStr${index}${indexMppt}`)[0].checked = false
                                                                                                                document.querySelectorAll(`#checkStr${index}${indexMppt}`)[i + 1].checked = false
                                                                                                                if (document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-azimute${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`)) document.querySelector(`.p-inclinacao${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.corrente${index}${indexMppt}${i + 1}`)) document.querySelector(`.corrente${index}${indexMppt}${i + 1}`).innerHTML = 0
                                                                                                                if (document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`)) {
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).disabled = true
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).classList.add('disabled')
                                                                                                                    document.querySelector(`.comprimento${index}${indexMppt}${i + 1}`).value = ''
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                {props.array_modules.length === 0 && document.querySelector(`.unidade${index}${indexMppt}${0}`) ? document.querySelector(`.unidade${index}${indexMppt}${0}`).innerHTML = `<option value='0'>0</option>` : 0}

                                                                                            </select> :
                                                                                            <p
                                                                                                style={{ width: '80px', fontSize: '8pt' }}
                                                                                                className={`
                                              p-unidade${index}${indexMppt}${indexStr} 
                                              p-unidade${index}${indexMppt} 
                                              p-unidade${index} 
                                        
                                              unidadesAll${index}
                                              p-unidade
                                              p_uni_idMod${document.querySelector(`.select_modules${index}${indexMppt}`)?.value}
                                              p-unidades_mppt${document.querySelector(`.select_modules${index}${indexMppt}
                                                `)
                                                                                                        ? parseInt(document.querySelector(`.select_modules${index}${indexMppt}`).value) : 0} p-unidade${index}`}>

                                                                                                {document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                                    && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked
                                                                                                    ? document.querySelector(`.unidade${index}${indexMppt}${0}`)?.value : '0'}

                                                                                            </p>
                                                                                        }
                                                                                    </td>
                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }}>
                                                                                        {str.string === 1 ?
                                                                                            <input
                                                                                                style={{ maxWidth: '50px', textAlign: 'center', fontSize: '8pt' }}
                                                                                                type='text'
                                                                                                min={1}
                                                                                                max={360}
                                                                                                step={0.1}
                                                                                                disabled={
                                                                                                    indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? true : false
                                                                                                        )
                                                                                                }

                                                                                                onChange={e => {
                                                                                                    setTimeout(()=>{
                                                                                                        valoresPorModulos(index)
                                                                                                        }, 100)
                                                                                                    if (e.target.value === '') {
                                                                                                        e.target.value = 0
                                                                                                    }
                                                                                                    e.target.value = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                                                                                                    setWork_around(e.target.value)
                                                                                                    if (e.target.value < 0) {
                                                                                                        e.target.value = 0
                                                                                                    }
                                                                                                    if (e.target.value > 360) {
                                                                                                        e.target.value = 360
                                                                                                    }
                                                                                                    let p_a = document.querySelectorAll(`#p-azimute${index}${indexMppt}`)
                                                                                                    let checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                    if (p_a) {
                                                                                                        for (let i = 0; i < p_a.length; i++) {
                                                                                                            if (checkStr[i].checked) {
                                                                                                                p_a[i].innerHTML = e.target.value

                                                                                                            }
                                                                                                            if (checkStr[p_a.length - 1].checked) {
                                                                                                                if (document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`)) document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`).checked = false
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                placeholder='0°'
                                                                                                className={`azimuteAll${index} azimute${index}${indexMppt}${indexStr} ${str.string !== 1 ? 'disabled' : ''} 
                                                                                                    ${indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? 'disabled'
                                                                                                                : ''
                                                                                                        )
                                                                                                    }
                                                                                                     `}
                                                                                                id='azimute' />
                                                                                            : <p style={{ fontSize: '8pt' }} id={`p-azimute${index}${indexMppt}`} className={`azimuteAll${index} p-azimute${index}${indexMppt}${indexStr} p-azimute`}>0</p>
                                                                                        }</td>

                                                                                    <td style={{ minWidth: '70px', textAlign: 'center' }}>
                                                                                        {str.string === 1
                                                                                            ?
                                                                                            <input
                                                                                                style={{ maxWidth: '50px', textAlign: 'center', fontSize: '8pt' }}
                                                                                                type='text'

                                                                                                disabled={
                                                                                                    indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? true : false
                                                                                                        )
                                                                                                }
                                                                                                onChange={
                                                                                                    e => {
                                                                                                        setTimeout(()=>{
                                                                                                            valoresPorModulos(index)
                                                                                                            }, 100)
                                                                                                        if (e.target.value === '') {
                                                                                                            e.target.value = 0
                                                                                                        }

                                                                                                        e.target.value = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                                                                                                        setWork_around(e.target.value)
                                                                                                        
                                                                                                        if (e.target.value < 0) {
                                                                                                            e.target.value = 0
                                                                                                        }
                                                                                                        if (e.target.value > 40) {
                                                                                                            e.target.value = 40
                                                                                                        }
                                                                                                        let p_i = document.querySelectorAll(`#p-inclinacao${index}${indexMppt}`)
                                                                                                        let checkStr = document.querySelectorAll(`#checkStr${index}${indexMppt}`)
                                                                                                        if (p_i) {
                                                                                                            for (let i = 0; i < p_i.length; i++) {
                                                                                                                if (checkStr[i].checked) {
                                                                                                                    p_i[i].innerHTML = e.target.value

                                                                                                                }
                                                                                                                if (checkStr[p_i.length - 1].checked) {
                                                                                                                    if (document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`)) document.querySelector(`.checkStr${index}${indexMppt + 1}${0}`).checked = false
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                placeholder='0°'
                                                                                                className={`inclinacaoAll${index} inclinacao inclinacao${index}${indexMppt}${indexStr} ${str.string !== 1 ? 'disabled' : ''}
                                               ${indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? 'disabled'
                                                                                                                : ''
                                                                                                        )
                                                                                                    }
                                             `} id='inclinacao' />

                                                                                            : <p style={{ fontSize: '8pt' }} id={`p-inclinacao${index}${indexMppt}`} className={`inclinacaoAll${index} p-inclinacao${index}${indexMppt}${indexStr} p-inclinacao`}>0</p>

                                                                                        }</td>

                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt', paddingLeft: '10px' }} id={`potenciaM${indexMppt}${indexStr}`} className={`potencia${index} potenciaM${index}${indexMppt}${indexStr}  potenciaM`}>
                                                                                        {document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                            && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked

                                                                                            ? document.querySelector(`.potenciaM${index}${indexMppt}${0}`)?.innerHTML : '0.000'}
                                                                                    </td>

                                                                                    <td style={{ minWidth: '60px', textAlign: 'center', fontSize: '8pt', paddingLeft: '10px' }} id={`potenciaMppt${indexMppt}`} className={`potenciaMppt${index}${indexMppt}${indexStr}  potenciaMppt${index} potenciaMppt`}>
                                                                                        {str.string !== Math.round(mppt.length / 2) ? '' : '0.000'}
                                                                                        {str.string !== Math.round(mppt.length / 2) ? '' : <span style={{ display: 'none' }} className='number'>2</span>}
                                                                                    </td>

                                                                                    <td style={{ minWidth: '50px', textAlign: 'center', fontSize: '8pt' }}
                                                                                        id={`corrente${indexMppt}`}
                                                                                        className={`corrente${index} corrente${index}${indexMppt}${indexStr} corrente`}>
                                                                                        0
                                                                                    </td>

                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }} className={` correnteMppt${index}${indexMppt}${indexStr} correnteMppt`}>
                                                                                        {str.string !== Math.round(mppt.length / 2) ? '' : '0.00'}
                                                                                    </td>

                                                                                    <td style={{ minWidth: '80px', textAlign: 'center', fontSize: '8pt' }} className={`tensao${index} tensao${index}${indexMppt}${indexStr} tensao`}>
                                                                                        {document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                            && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked

                                                                                            ? document.querySelector(`.tensao${index}${indexMppt}${0}`)?.textContent : '0V'
                                                                                        }
                                                                                    </td>

                                                                                    <td style={{ minWidth: '60px', textAlign: 'center', fontSize: '8pt' }}>
                                                                                        {str.string === 1 ?
                                                                                            <select
                                                                                                style={{ fontSize: '8pt' }}
                                                                                                disabled={
                                                                                                    indexMppt === 0 ? ''
                                                                                                        :
                                                                                                        (
                                                                                                            document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                                && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                                ? true : false
                                                                                                        )
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    setTimeout(()=>{
                                                                                                        valoresPorModulos(index)
                                                                                                        }, 100)
                                                                                                    setWork_around(e.target.value)
                                                                                                }} id='secao_nominal' className={`secao_nominalAll${index} secao_nominal${index}${indexMppt}${indexStr} secaoN `}>

                                                                                                <option value="6">6</option>
                                                                                                <option value="4">4</option>

                                                                                            </select>

                                                                                            :

                                                                                            <p style={{ fontSize: '8pt' }} className={`secao_nominalAll${index} p-secao_nominal${index}${indexMppt}${indexStr} p-secaoN`}>

                                                                                                {
                                                                                                    document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                                        && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked

                                                                                                        ? document.querySelector(`.secao_nominal${index}${indexMppt}${0}`)?.value : '0'
                                                                                                }

                                                                                            </p>
                                                                                        }
                                                                                    </td>
                                                                                    <td style={{ minWidth: '80px', textAlign: 'center' }}>
                                                                                        <input
                                                                                            style={{ textAlign: 'center', width: '50px', fontSize: '6.5pt' }}
                                                                                            disabled={
                                                                                                indexMppt === 0 ? '' : (
                                                                                                    document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                        && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                        ? true : false)
                                                                                            }

                                                                                            id='comprimento'
                                                                                            className={`comprimento${index} comprimento${index}${indexMppt}${indexStr}
                                               
                                                                                              ${indexMppt === 0 ? '' : (
                                                                                                    str.string === 1 &&
                                                                                                        document.querySelector(`.checkMppt${index}${indexMppt}${0}`)
                                                                                                        && document.querySelector(`.checkMppt${index}${indexMppt}${0}`).checked === false
                                                                                                        ? 'disabled' : '')
                                                                                                }
                                               
                                                                                                ${indexMppt === 0 ? '' : (
                                                                                                    str.string !== 1 &&
                                                                                                        document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                                        && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked === false
                                                                                                        ? 'disabled' : '')
                                                                                                }
                                                                                                ${indexMppt !== 0 ? '' : (
                                                                                                    str.string !== 1 &&
                                                                                                        document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`)
                                                                                                        && document.querySelector(`.checkStr${index}${indexMppt}${indexStr}`).checked === false
                                                                                                        ? 'disabled' : '')
                                                                                                }
                                               
                                               
                                                                                                `}
                                                                                            type="text"


                                                                                            placeholder='0'
                                                                                            onChange={(e) => {
                                                                                                setTimeout(()=>{
                                                                                                valoresPorModulos(index)
                                                                                                }, 100)
                                                                                                if (e.target.value === '') {
                                                                                                    e.target.value = 0
                                                                                                }
                                                                                                e.target.value = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                                                                                                if (e.target.value < 0) {
                                                                                                    e.target.value = 0
                                                                                                }

                                                                                                setWork_around(e.target.value)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ minWidth: '50px', textAlign: 'center', fontSize: '8pt' }} className={`tensaoPorce${index} tensaoPorce${index}${indexMppt}${0} tensaoPorce`}   >

                                                                                        {

                                                                                            tensaoPorce(document.querySelector(`.secao_nominal${index}${indexMppt}${0}`)?.value, document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`)?.value, document.querySelector(`.unidade${index}${indexMppt}${0}`)?.value, document.querySelector(`.corrente${index}${indexMppt}${0}`)?.textContent, document.querySelector(`.select_modules${index}${indexMppt}`)?.value, inv.type_of_network_connection)

                                                                                        }

                                                                                    </td>
                                                                                    <td id='perdas_cabos' className={`perdasCabos${index}`} style={{ minWidth: '50px', fontSize: '8pt', textAlign: 'center' }}>
                                                                                        {perdasCabos(document.querySelector(`.secao_nominal${index}${indexMppt}${0}`)?.value, document.querySelector(`.comprimento${index}${indexMppt}${indexStr}`)?.value, document.querySelector(`.corrente${index}${indexMppt}${0}`)?.textContent, inv.type_of_network_connection)}
                                                                                    </td>




                                                                                    {indexMppt === inverter.mppt.length - 1 && indexStr === mppt.length - 1 ?
                                                                                        <div className='table' style={{ height: 'auto', marginLeft: '30px', minWidth: '98%', marginBottom: '10px' }}>

                                                                                            <div style={{ minWidth: '150px', maxWidth: '150px', fontSize: '8pt', marginLeft: '-150px', marginBottom: '-45px' }}>
                                                                                                <h4 style={{ fontSize: '9pt' }}>Total</h4>
                                                                                                <p style={{ fontSize: '8pt' }}>Potência (kW): <span id={`somaDePotencia${index}`}>{somaDePotencia(index, parseInt(inv.maximum_photovoltaic_power))}</span> / {parseInt(inv.maximum_photovoltaic_power)}.000</p>
                                                                                                <div className='structure-type form-div'>
                                                                                                    <p>Tipo de Estrura:</p>
                                                                                                    <select id={`structure-type${index}`} className='structure-type'>
                                                                                                        <option value="Telhado">Telhado</option>
                                                                                                        <option value="Laje">Laje</option>
                                                                                                        <option value="Solo">Solo</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <table>
                                                                                                <thead>
                                                                                                    <tr >
                                                                                                        <th style={{ fontSize: '7pt' }}>Origem</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Destino</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Potência Máx. (kW)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Corrente (Ah)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Tensão (V)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Seção Nominal (mm&#x00B2;)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Comprim. (m)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Queda Tensão (%)</th>
                                                                                                        <th style={{ fontSize: '7pt' }}>Perdas Cabos (W)</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <tr style={{ maxHeight: '15px' }}>
                                                                                                        <td style={{ maxHeight: '15px' }} >
                                                                                                            <p style={{ fontSize: '7pt', maxHeigth: '15px' }}>{inv.brand} - {inv.model}&nbsp;</p>
                                                                                                        </td>

                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }}>
                                                                                                            &nbsp;Q.G.B.T.&nbsp;
                                                                                                        </td>

                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`potenciaFinal${index} potenciaFinalInversoresQuadro potenciaFinalInversoresQuadroCont`}>
                                                                                                            {potenciaFinalInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent)}
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`correnteFinal${index}`}>
                                                                                                            {correnteInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent)}
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`tensaoFinal${index}`}>
                                                                                                            {tipoDeConexaoInversor(inv)}
                                                                                                        </td>



                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`secao_nominalFinal${index} secaoNominalInversoresQuadro${index} `}>
                                                                                                            {/*secaoNominalInversoresQuadro(inv, document.querySelector(`.comprimentoInversoresQuadro${index}`)?.value)*/}
                                                                                                            { secaoNominalInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent)}
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }}>
                                                                                                            <input
                                                                                                                style={{ textAlign: 'center', width: '50px', fontSize: '7pt', maxHeigth: '15px' }}
                                                                                                                type='number'
                                                                                                                min={0}
                                                                                                                step={1}
                                                                                                                placeholder='0'
                                                                                                                className={`comprimentoFinal${index} comprimentoInversoresQuadro${index} comprimentoFinal`}
                                                                                                                onChange={(e) => {
                                                                                                                    setTimeout(()=>{
                                                                                                                        valoresPorModulos(index)
                                                                                                                        }, 100)
                                                                                                                    setWork_around(e.target.value)
                                                                                                                }} />
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`tensaoPorceFinal${index}`}>
                                                                                                            {quedaPorcentagemInversoresQuadro(inv, parseInt(document.querySelector(`.comprimentoInversoresQuadro${index}`)?.value), secaoNominalInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent), parseFloat(correnteInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent)))}
                                                                                                        </td>
                                                                                                        <td style={{ fontSize: '7pt', maxHeigth: '15px' }} className={`perdasCabosFinal${index} perdasDeCabosInversoresQuadro${index} perdasDeCabosInversoresQuadroCont`}>
                                                                                                            {perdasDeCabosInversoresQuadro(inv, parseInt(document.querySelector(`.comprimentoInversoresQuadro${index}`)?.value), parseInt(document.querySelector(`.secaoNominalInversoresQuadro${index}`)?.textContent), parseFloat(correnteInversoresQuadro(inv, document.getElementById(`somaDePotencia${index}`)?.textContent)))}
                                                                                                        </td>
                                                                                                    </tr  >





                                                                                                </tbody>

                                                                                            </table>


                                                                                        </div> : ''}
                                                                                    {/*indexMppt === inverter.mppt.length - 1 && indexStr === mppt.length - 1 ?
                                            <div style={{ minWidth: '300px', maxWidth: '300px',fontSize:'8pt' }}>
                                              <h4 style={{fontSize:'9pt'}}>Total</h4>
                                              <p style={{fontSize:'8pt'}}>Potência (kW): <span id='somaDePotencia'>{document.querySelector(`.comprimentoInversoresQuadro${index}`)?.value}</span> / {parseInt(inv.maximum_photovoltaic_power)}.000</p>
                                              <br />
                                              <br />
                                            </div>
                                            : ''*/}

                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>

                                </tr >

                            )
                        })
                    }
                </div>

            </div>
            <p style={{ textAlign: 'center' }}>
                <div className='table' style={{ height: 'auto', marginBottom: '0px' }}>
                    <div style={{ display: 'flex', gap: '10px', minWidth: '1190px', maxWidth: '1190px' }}>

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '7pt' }}>Origem</th>
                                    <th style={{ fontSize: '7pt' }}>Destino</th>
                                    <th style={{ fontSize: '7pt' }}>Potência Máx. (kW)</th>
                                    <th style={{ fontSize: '7pt' }}>Corrente (Ah)</th>
                                    <th style={{ fontSize: '7pt' }}>Tensão (V)</th>
                                    <th style={{ fontSize: '7pt' }}>Seção Nominal (mm&#x00B2;)</th>
                                    <th style={{ fontSize: '7pt' }}>Comprim. (m)</th>
                                    <th style={{ fontSize: '7pt' }}>Queda Tensão (%)</th>
                                    <th style={{ fontSize: '7pt' }}>Perdas Cabos (W)</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>

                                    <td >
                                        <p style={{ fontSize: '7pt' }}>Q.G.B.T.</p>
                                    </td>
                                    <td style={{ fontSize: '7pt' }}>
                                        PADRÃO ENTRADA
                                    </td>


                                    <td style={{ fontSize: '7pt' }}>
                                        <p style={{ fontSize: '7pt' }} className='potencia-maxima-quadro'>{potenciaMaximaQuadrosQuadro()}</p>

                                    </td>
                                    <td style={{ fontSize: '7pt' }}  >
                                        <p style={{ fontSize: '7pt' }} className='corrente-maxima-quadro'>{correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters)}</p>

                                    </td>
                                    <td style={{ fontSize: '7pt' }} className='connection-quadro'>
                                        {tipoDeConexaoQuadrosQuadroGeral(props.array_inverters)}
                                    </td>

                                    <td style={{ fontSize: '7pt' }} className='secao-nominal-quadro'>
                                        {secaoNominalQuadrosQuadroGeral(props.array_inverters, parseFloat(correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters)))}
                                    </td>
                                    <td style={{ fontSize: '7pt' }}>
                                        <input
                                            className='comprimento-quadro'
                                            type="number"
                                            style={{ textAlign: 'center', width: '50px', fontSize: '7pt' }}
                                            min={1}

                                            step={1}

                                            placeholder='0'
                                            onChange={(e) => {


                                                setWork_around(e.target.value)
                                            }
                                            }
                                        />

                                    </td>
                                    <td style={{ fontSize: '7pt' }} className='tensao-porce-quadro'>
                                        {quedaPorcentagemQuadro(correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters), parseInt(document.querySelector('.comprimento-quadro')?.value), parseInt(secaoNominalQuadrosQuadroGeral(props.array_inverters, parseFloat(correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters)))), tipoDeConexaoQuadrosQuadroGeral(props.array_inverters))}
                                    </td>
                                    <td style={{ fontSize: '7pt' }} className='perdas-cabos-quadro'>
                                        {perdasDeCabosQuadro(parseInt(document.querySelector('.comprimento-quadro')?.value), parseInt(secaoNominalQuadrosQuadroGeral(props.array_inverters, parseFloat(correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters)))), correnteQuadrosQuadroGeral(potenciaMaximaQuadrosQuadro(), props.array_inverters))}
                                    </td>
                                </tr  >





                            </tbody>
                            <tfoot>

                            </tfoot>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '7pt' }}>Origem</th>
                                    <th style={{ fontSize: '7pt' }}></th>
                                    <th style={{ fontSize: '7pt' }}>Tipo de Sistema</th>
                                    <th style={{ fontSize: '7pt' }}>Tensão (V)</th>
                                    <th style={{ fontSize: '7pt' }}>Corrente Disjuntor (A)</th>
                                    <th style={{ fontSize: '7pt' }}>Potência Instalada <br />  (kVA)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(kW)</th>
                                    <th style={{ fontSize: '7pt' }}>Seção Nominal (mm&#x00B2;)</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: '7pt' }} colSpan='2'>PADRÃO DE ENTRADA</td>
                                    <td>
                                        <select style={{ fontSize: '7pt' }} disabled className='sistema-padrao' name="" id=""
                                            onChange={
                                                (e) => {
                                                    setWork_around(e.target.value)
                                                }
                                            }
                                            value={props.data.service_type_demand}
                                        >
                                            <option value="trifasico">Trifásico</option>
                                            <option value="bifasico">Bifásico</option>
                                            <option value="monofasico">Monofásico</option>
                                        </select>
                                    </td>
                                    <td style={{ fontSize: '7pt' }} className='conexao-instalada'>{
                                        props.data.service_type_demand === 'trifasico' ? '220V/380V' : '' ||
                                            props.data.service_type_demand === 'bifasico' ? '220V/380V' : '' ||
                                                props.data.service_type_demand === 'monofasico' ? '220V' : ''
                                    }</td>



                                    <td style={{ fontSize: '7pt' }} className='tensao-padrao'>
                                        {
                                            props.data.service_type_demand === 'trifasico' ?
                                                <select disabled className='corrente-instalada-trifasicoFALSE' name="" id=""
                                                    onChange={
                                                        (e) => {
                                                            setWork_around(e.target.value)
                                                        }}
                                                    value={props.data.disjuntor_demand}
                                                >
                                                    <option value="0">0</option>
                                                    <option value="25">25</option>
                                                    <option value="32">32</option>
                                                    <option value="40">40</option>
                                                    <option value="50">50</option>
                                                    <option value="63">63</option>
                                                    <option value="80">80</option>
                                                    <option value="100">100</option>


                                                </select>
                                                :
                                                ''
                                                    ||
                                                    props.data.service_type_demand === 'bifasico' ?
                                                    <select style={{ fontSize: '7pt' }} disabled className='corrente-instalada-bifasicoFALSE' name="" id=""
                                                        onChange={
                                                            (e) => {
                                                                setWork_around(e.target.value)
                                                            }}
                                                        value={props.data.disjuntor_demand}
                                                    >
                                                        <option value="0">0</option>
                                                        <option value="20">20</option>
                                                        <option value="25">25</option>
                                                        <option value="32">32</option>
                                                        <option value="40">40</option>


                                                    </select>
                                                    :
                                                    ''
                                                        ||
                                                        props.data.service_type_demand === 'monofasico' ?
                                                        <select style={{ fontSize: '7pt' }} disabled className='corrente-instalada-monofasicoFALSE' name="" id=""
                                                            onChange={
                                                                (e) => {
                                                                    setWork_around(e.target.value)
                                                                }}
                                                            value={props.data.disjuntor_demand}
                                                        >
                                                            <option value="0">0</option>
                                                            <option value="16">16</option>
                                                            <option value="20">20</option>
                                                            <option value="25">25</option>
                                                            <option value="32">32</option>
                                                            <option value="40">40</option>
                                                            

                                                        </select>
                                                        :
                                                        ''
                                        }
                                    </td>
                                    <td
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '55px',
                                            fontSize: '7pt'
                                        }}>
                                        <span style={{ fontSize: '7pt' }} className="potencia-kva">0.00</span>
                                        <span style={{ fontSize: '7pt' }} className="potencia-kw">0.00</span>
                                    </td>

                                    <td style={{ fontSize: '7pt' }} className='secao-nominal-padrao'></td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className='limit-body-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '7pt' }}>ID</th>
                                    <th style={{ fontSize: '7pt' }} >Modulos</th>
                                    <th style={{ fontSize: '7pt' }} >Quantidade</th>
                                    <th style={{ fontSize: '7pt' }} >Selecionados</th>
                                    <th style={{ fontSize: '7pt' }} >Restantes</th>
                                </tr>
                            </thead>
                            <tbody >

                                {props.array_count_modules?.map((mod, index) => (
                                    <tr key={index} className={`listCountMod${index}`}>
                                        <td style={{ fontSize: '7pt' }} >{index + 1}</td>
                                        <td style={{ fontSize: '7pt' }} >{mod.brand_modules}&nbsp;&nbsp;-&nbsp;&nbsp;  {mod.model_modules}</td>
                                        <td style={{ fontSize: '7pt' }} >{mod.qtd}</td>
                                        <td style={{ fontSize: '7pt' }}>{mod.qtd_selecionada ? mod.qtd_selecionada : 0} </td>
                                        <td className={`countModResult${index}`} style={{ fontSize: '7pt' }} >{mod.qtd - mod.qtd_selecionada}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </p>


        </div>

    )
}

export default EditConfigSystemProject
