
import React, { useState } from 'react'
import html2pdf from 'html2pdf.js'
import jsPDF from 'jspdf';
import { Avatar, Badge, Box, boxClasses, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Paper, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditConfigSystemProject from './configSystem/index';
import EdiDescriptiveMemorialProject from './descriptiveMemorial/index';
import Loading from '../loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Edit_System_Project_Dialog(props) {
    const token = JSON.parse(localStorage.getItem('token'));

    const [isLoading, setIsLoading] = useState(false);



    const steps = ['Configuração do Sistema', 'Download do Projeto'];
    
    const [activeStep, setActiveStep] = React.useState(0);


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if(selectedCountModules.map((item) => item.qtd !== item.qtd_selecionada).includes(true)){
                    toast.error('Atenção, preencha todos os campos destacados e selecione a quantidade de módulos corretamente')
                    return
                }
                if (!validFormConfigSystem) return toast.error('Atenção, preencha todos os campos destacados e selecione a quantidade de módulos corretamente')
                    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 1:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                break;
        }
    };












    const array_inverters = props.data?.array_inverters_project ? JSON.parse(props.data?.array_inverters_project) : [];
    const array_modules = props.data?.array_modules_project ? JSON.parse(props.data?.array_modules_project) : [];
    const array_mppt = props.data?.array_mppt_project ? JSON.parse(props.data?.array_mppt_project) : [];
    const array_count_modules = props.data?.array_count_modules_project ? JSON.parse(props.data?.array_count_modules_project) : [];




    // Pag 1 : Estados para armazenar os dados dos módulos e inversores
    const [selectedInvertersArray, setSelectedInvertersArray] = useState(array_inverters);
    const [selectedArrayMppt, setSelectedArrayMppt] = useState(array_mppt);
    const [selectedModulesArray, setSelectedModulesArray] = useState(array_modules);
    const [selectedCountModules, setSelectedCountModules] = useState(array_count_modules);

    const [inforTest, setInforTest] = useState(false);
    const handleTestInformation = (e) => {
        setInforTest(e);
    }
    const [stateSelectedInverters, setStateSelectedInverters] = useState('');
    const [stateSelectedModules, setStateSelectedModules] = useState('');
    const [stateSelectedCountModules, setStateSelectedCountModules] = useState('');


    // Pag 2 : Estados html para salvar dados do sistema
    const array_check_mppt = props.data?.array_check_mppt ? JSON.parse(props.data?.array_check_mppt) : '';
    const array_select_modules = props.data?.array_select_modules ? JSON.parse(props.data?.array_select_modules) : '';
    const array_check_string = props.data?.array_check_string ? JSON.parse(props.data?.array_check_string) : '';
    const array_unidades = props.data?.array_unidades ? JSON.parse(props.data?.array_unidades) : '';
    const array_p_unidades = props.data?.array_p_unidades ? JSON.parse(props.data?.array_p_unidades) : '';
    const array_azimute = props.data?.array_azimute ? JSON.parse(props.data?.array_azimute) : '';
    const array_p_azimute = props.data?.array_p_azimute ? JSON.parse(props.data?.array_p_azimute) : '';
    const array_inclincao = props.data?.array_inclinacao ? JSON.parse(props.data?.array_inclinacao) : '';
    const array_p_inclincao = props.data?.array_p_inclinacao ? JSON.parse(props.data?.array_p_inclinacao) : '';
    const array_potenciaM = props.data?.array_potencia_m ? JSON.parse(props.data?.array_potencia_m) : '';
    const array_potenciaMppt = props.data?.array_potencia_mppt ? JSON.parse(props.data?.array_potencia_mppt) : '';
    const array_correnteStr = props.data?.array_corrente_str ? JSON.parse(props.data?.array_corrente_str) : '';
    const array_correnteMppt = props.data?.array_corrente_mppt ? JSON.parse(props.data?.array_corrente_mppt) : '';
    const array_tensao = props.data?.array_tensao ? JSON.parse(props.data?.array_tensao) : '';
    const array_secaoN = props.data?.array_secao_n ? JSON.parse(props.data?.array_secao_n) : '';
    const array_secaoP = props.data?.array_secao_p ? JSON.parse(props.data?.array_secao_p) : '';
    const array_comprimento = props.data?.array_comprimento ? JSON.parse(props.data?.array_comprimento) : '';
    const array_tensaoPorce = props.data?.array_tensao_porce ? JSON.parse(props.data?.array_tensao_porce) : '';
    const array_perdasCabos = props.data?.array_perdas_cabos ? JSON.parse(props.data?.array_perdas_cabos) : '';
    const array_comprimentoFinal = props.data?.array_comprimento_final ? JSON.parse(props.data?.array_comprimento_final) : '';
    const array_comprimentoQuadro = props.data?.array_comprimento_quadro ? JSON.parse(props.data?.array_comprimento_quadro) : '';
    const array_structureType = props.data?.array_structure_type ? JSON.parse(props.data?.array_structure_type) : '';

    const [checkMppt, setCheckMppt] = useState(array_check_mppt);
    const [selectModules, setSelectModules] = useState(array_select_modules);
    const [checkString, setCheckString] = useState(array_check_string);
    const [unidades, setUnidades] = useState(array_unidades);
    const [p_unidades, setP_unidades] = useState(array_p_unidades);
    const [azimute, setAzimute] = useState(array_azimute);
    const [p_azimute, setP_azimute] = useState(array_p_azimute);
    const [inclincao, setInclincao] = useState(array_inclincao);
    const [p_inclincao, setP_inclincao] = useState(array_p_inclincao);
    const [potenciaM, setPotenciaM] = useState(array_potenciaM);
    const [potenciaMppt, setPotenciaMppt] = useState(array_potenciaMppt);
    const [correnteStr, setCorrenteStr] = useState(array_correnteStr);
    const [correnteMppt, setCorrenteMppt] = useState(array_correnteMppt);
    const [tensao, setTensao] = useState(array_tensao);
    const [secaoN, setSecaoN] = useState(array_secaoN);
    const [secaoP, setSecaoP] = useState(array_secaoP);
    const [comprimento, setComprimento] = useState(array_comprimento);
    const [tensaoPorce, setTensaoPorce] = useState(array_tensaoPorce);
    const [PerdasCabos, setPerdasCabos] = useState(array_perdasCabos);
    const [comprimentoFinal, setComprimentoFinal] = useState(array_comprimentoFinal);
    const [comprimentoQuadro, setComprimentoQuadro] = useState(array_comprimentoQuadro);
    const [structureType, setStructureType] = useState(array_structureType);

    //Elemento salvará todas as informações do sistema para leitura e gera o memorial descritivo
    const [elementConfigSystem, setElementConfigSystem] = useState('');


    //Função para validação de formulário da configuração do sistema
    const [validFormConfigSystem, setValidFormConfigSystem] = useState(false);
    const handleTestDataConfigSystem = (e) => {
        setValidFormConfigSystem(e);
    }







    //Meio para salvar valores das variáveis de estado para não perder os valores
    const [saveInverters, setSaveInverters] = useState('');
    const [saveModules, setSaveModules] = useState('');
    const [saveMppt, setSaveMppt] = useState('');
    const [saveCountModules, setSaveCountModules] = useState('');
    const [saveCheckMppt, setSaveCheckMppt] = useState('');
    const [saveSelectModules, setSaveSelectModules] = useState('');
    const [saveCheckString, setSaveCheckString] = useState('');
    const [saveUnidades, setSaveUnidades] = useState('');
    const [saveP_unidades, setSaveP_unidades] = useState('');
    const [saveAzimute, setSaveAzimute] = useState('');
    const [saveP_azimute, setSaveP_azimute] = useState('');
    const [saveInclincao, setSaveInclincao] = useState('');
    const [saveP_inclincao, setSaveP_inclincao] = useState('');
    const [savePotenciaM, setSavePotenciaM] = useState('');
    const [savePotenciaMppt, setSavePotenciaMppt] = useState('');
    const [saveCorrenteStr, setSaveCorrenteStr] = useState('');
    const [saveCorrenteMppt, setSaveCorrenteMppt] = useState('');
    const [saveTensao, setSaveTensao] = useState('');
    const [saveSecaoN, setSaveSecaoN] = useState('');
    const [saveSecaoP, setSaveSecaoP] = useState('');
    const [saveComprimento, setSaveComprimento] = useState('');
    const [saveTensaoPorce, setSaveTensaoPorce] = useState('');
    const [savePerdasCabos, setSavePerdasCabos] = useState('');
    const [saveComprimentoFinal, setSaveComprimentoFinal] = useState('');
    const [saveComprimentoQuadro, setSaveComprimentoQuadro] = useState('');
    const [saveStructureType, setSaveStructureType] = useState('');

    //Função para salvar valores das variáveis de estado para não perder os valores
    const handleSaveData = (e) => {
        setSaveInverters(selectedInvertersArray);
        setSaveModules(selectedModulesArray);
        setSaveMppt(selectedArrayMppt);
        setSaveCountModules(selectedCountModules);
        setSaveCheckMppt(checkMppt);
        setSaveSelectModules(selectModules);
        setSaveCheckString(checkString);
        setSaveUnidades(unidades);
        setSaveP_unidades(p_unidades);
        setSaveAzimute(azimute);
        setSaveP_azimute(p_azimute);
        setSaveInclincao(inclincao);
        setSaveP_inclincao(p_inclincao);
        setSavePotenciaM(potenciaM);
        setSavePotenciaMppt(potenciaMppt);
        setSaveCorrenteStr(correnteStr);
        setSaveCorrenteMppt(correnteMppt);
        setSaveTensao(tensao);
        setSaveSecaoN(secaoN);
        setSaveSecaoP(secaoP);
        setSaveComprimento(comprimento);
        setSaveTensaoPorce(tensaoPorce);
        setSavePerdasCabos(PerdasCabos);
        setSaveComprimentoFinal(comprimentoFinal);
        setSaveComprimentoQuadro(comprimentoQuadro);
        setSaveStructureType(structureType);
    }

    //Função para retornar valores das variáveis de estado para não perder os valores
    const handleReturnData = (e) => {
        setSelectedInvertersArray(saveInverters);
        setSelectedModulesArray(saveModules);
        setSelectedArrayMppt(saveMppt);
        setSelectedCountModules(saveCountModules);
        setCheckMppt(saveCheckMppt);
        setSelectModules(saveSelectModules);
        setCheckString(saveCheckString);
        setUnidades(saveUnidades);
        setP_unidades(saveP_unidades);
        setAzimute(saveAzimute);
        setP_azimute(saveP_azimute);
        setInclincao(saveInclincao);
        setP_inclincao(saveP_inclincao);
        setPotenciaM(savePotenciaM);
        setPotenciaMppt(savePotenciaMppt);
        setCorrenteStr(saveCorrenteStr);
        setCorrenteMppt(saveCorrenteMppt);
        setTensao(saveTensao);
        setSecaoN(saveSecaoN);
        setSecaoP(saveSecaoP);
        setComprimento(saveComprimento);
        setTensaoPorce(saveTensaoPorce);
        setPerdasCabos(savePerdasCabos);
        setComprimentoFinal(saveComprimentoFinal);
        setComprimentoQuadro(saveComprimentoQuadro);
        setStructureType(saveStructureType);
    }

    //Função para converter base64 em blob
    const base64ToBlob = (base64, type) => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type: type });
    }
    const save_memorial = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const array_inverters = JSON.stringify(selectedInvertersArray);
        const array_modules = JSON.stringify(selectedModulesArray);
        const array_mppt = JSON.stringify(selectedArrayMppt);
        const array_count_modules = JSON.stringify(selectedCountModules);

        const array_check_mppt = JSON.stringify(saveCheckMppt);
        const array_select_modules = JSON.stringify(saveSelectModules);
        const array_check_string = JSON.stringify(saveCheckString);
        const array_unidades = JSON.stringify(saveUnidades);
        const array_p_unidades = JSON.stringify(saveP_unidades);
        const array_azimute = JSON.stringify(saveAzimute);
        const array_p_azimute = JSON.stringify(saveP_azimute);
        const array_inclincao = JSON.stringify(saveInclincao);
        const array_p_inclincao = JSON.stringify(saveP_inclincao);
        const array_potenciaM = JSON.stringify(savePotenciaM);
        const array_potenciaMppt = JSON.stringify(savePotenciaMppt);
        const array_correnteStr = JSON.stringify(saveCorrenteStr);
        const array_correnteMppt = JSON.stringify(saveCorrenteMppt);
        const array_tensao = JSON.stringify(saveTensao);
        const array_secaoN = JSON.stringify(saveSecaoN);
        const array_secaoP = JSON.stringify(saveSecaoP);
        const array_comprimento = JSON.stringify(saveComprimento);
        const array_tensaoPorce = JSON.stringify(saveTensaoPorce);
        const array_perdasCabos = JSON.stringify(savePerdasCabos);
        const array_comprimentoFinal = JSON.stringify(saveComprimentoFinal);
        const array_comprimentoQuadro = JSON.stringify(saveComprimentoQuadro);
        const array_structureType = JSON.stringify(saveStructureType);




        await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-project-descriptive-memorial/${props.data.id_demand}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ array_inverters_project: array_inverters, array_modules_project: array_modules, array_mppt_project: array_mppt, array_count_modules_project: array_count_modules, array_check_mppt: array_check_mppt, array_select_modules: array_select_modules, array_check_string: array_check_string, array_unidades: array_unidades, array_p_unidades: array_p_unidades, array_azimute: array_azimute, array_p_azimute: array_p_azimute, array_inclinacao: array_inclincao, array_p_inclinacao: array_p_inclincao, array_potencia_m: array_potenciaM, array_potencia_mppt: array_potenciaMppt, array_corrente_str: array_correnteStr, array_corrente_mppt: array_correnteMppt, array_tensao: array_tensao, array_secao_n: array_secaoN, array_secao_p: array_secaoP, array_comprimento: array_comprimento, array_tensao_porce: array_tensaoPorce, array_perdas_cabos: array_perdasCabos, array_comprimento_final: array_comprimentoFinal, array_comprimento_quadro: array_comprimentoQuadro, array_structure_type: array_structureType })
        })
            .then(response => response.json())
        
            .catch(error => console.log('error', error))




        const element = document.getElementById('memorial-descritivo');
        const opt = {
            margin: 0,
            filename: `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };



        

        //salvando o memorial descritivo no banco de dados

        const dadosPDF = await html2pdf().from(element).set(opt).output('datauristring').then(function (data) {
            const pdf = data.split('base64,')[1];
            const blob = base64ToBlob(pdf, 'application/pdf');
            const file = new File([blob], `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`, { type: 'application/pdf' });
            return file;
        });

        const formData = new FormData();
        formData.append('descriptive_memorial', dadosPDF);
    

        const data_descriptive_memorial = formData
        const requestOptions = {
            method: 'POST',
            body: data_descriptive_memorial
        }


        await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-data-descriptive-memorial/${props.data.id_demand}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                
                        event: 'Memorial Descritivo',
                        details: 'Memorial Descritivo Editado', 
                        demand_id: props.data?.id_demand,
                        name_user: token.name
                    })
                })
                document.getElementById('bt-search').click();
                setTimeout(() => {
                    setIsLoading(false);
                    document.getElementById(`${props.data.id_demand}`).click()
                    props.close();
                }, 100)
            })
            .catch(error =>{
                setIsLoading(false);
                console.log('error', error)})
    }

    const download_memorial = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const array_inverters = JSON.stringify(selectedInvertersArray);
        const array_modules = JSON.stringify(selectedModulesArray);
        const array_mppt = JSON.stringify(selectedArrayMppt);
        const array_count_modules = JSON.stringify(selectedCountModules);

        const array_check_mppt = JSON.stringify(saveCheckMppt);
        const array_select_modules = JSON.stringify(saveSelectModules);
        const array_check_string = JSON.stringify(saveCheckString);
        const array_unidades = JSON.stringify(saveUnidades);
        const array_p_unidades = JSON.stringify(saveP_unidades);
        const array_azimute = JSON.stringify(saveAzimute);
        const array_p_azimute = JSON.stringify(saveP_azimute);
        const array_inclincao = JSON.stringify(saveInclincao);
        const array_p_inclincao = JSON.stringify(saveP_inclincao);
        const array_potenciaM = JSON.stringify(savePotenciaM);
        const array_potenciaMppt = JSON.stringify(savePotenciaMppt);
        const array_correnteStr = JSON.stringify(saveCorrenteStr);
        const array_correnteMppt = JSON.stringify(saveCorrenteMppt);
        const array_tensao = JSON.stringify(saveTensao);
        const array_secaoN = JSON.stringify(saveSecaoN);
        const array_secaoP = JSON.stringify(saveSecaoP);
        const array_comprimento = JSON.stringify(saveComprimento);
        const array_tensaoPorce = JSON.stringify(saveTensaoPorce);
        const array_perdasCabos = JSON.stringify(savePerdasCabos);
        const array_comprimentoFinal = JSON.stringify(saveComprimentoFinal);
        const array_comprimentoQuadro = JSON.stringify(saveComprimentoQuadro);
        const array_structureType = JSON.stringify(saveStructureType);




        await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-project-descriptive-memorial/${props.data.id_demand}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ array_inverters_project: array_inverters, array_modules_project: array_modules, array_mppt_project: array_mppt, array_count_modules_project: array_count_modules, array_check_mppt: array_check_mppt, array_select_modules: array_select_modules, array_check_string: array_check_string, array_unidades: array_unidades, array_p_unidades: array_p_unidades, array_azimute: array_azimute, array_p_azimute: array_p_azimute, array_inclinacao: array_inclincao, array_p_inclinacao: array_p_inclincao, array_potencia_m: array_potenciaM, array_potencia_mppt: array_potenciaMppt, array_corrente_str: array_correnteStr, array_corrente_mppt: array_correnteMppt, array_tensao: array_tensao, array_secao_n: array_secaoN, array_secao_p: array_secaoP, array_comprimento: array_comprimento, array_tensao_porce: array_tensaoPorce, array_perdas_cabos: array_perdasCabos, array_comprimento_final: array_comprimentoFinal, array_comprimento_quadro: array_comprimentoQuadro, array_structure_type: array_structureType })
        })
            .then(response => response.json())
        
            .catch(error => console.log('error', error))




        const element = document.getElementById('memorial-descritivo');
        const opt = {
            margin: 0,
            filename: `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };



        html2pdf().from(element).set(opt).save();

        //salvando o memorial descritivo no banco de dados

        const dadosPDF = await html2pdf().from(element).set(opt).output('datauristring').then(function (data) {
            const pdf = data.split('base64,')[1];
            const blob = base64ToBlob(pdf, 'application/pdf');
            const file = new File([blob], `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`, { type: 'application/pdf' });
            return file;
        });

        const formData = new FormData();
        formData.append('descriptive_memorial', dadosPDF);
    

        const data_descriptive_memorial = formData
        const requestOptions = {
            method: 'POST',
            body: data_descriptive_memorial
        }


        await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-data-descriptive-memorial/${props.data.id_demand}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                
                        event: 'Memorial Descritivo',
                        details: 'Memorial Descritivo Editado', 
                        demand_id: props.data?.id_demand,
                        name_user: token.name
                    })
                })
                document.getElementById('bt-search').click();
                setTimeout(() => {
                    setIsLoading(false);
                    document.getElementById(`${props.data.id_demand}`).click()
                    props.close();
                }, 100)
            })
            .catch(error =>{
                setIsLoading(false);
                console.log('error', error)})
    }



    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth='100%'

        >
            {isLoading && <Loading/>}
            <DialogContent>
                <dialogTitle>
                    <Typography variant='h5'>Editar Projeto</Typography>
                </dialogTitle>

                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Stepper activeStep={activeStep} >


                    {activeStep === 0 && <EditConfigSystemProject
                        setElementConfigSystem={setElementConfigSystem}

                        array_inverters={selectedInvertersArray}
                        array_modules={selectedModulesArray}
                        array_mppt={selectedArrayMppt}
                        array_count_modules={selectedCountModules}


                        P_setSelectedInverter={setSelectedInvertersArray}
                        P_setSelectedModules={setSelectedModulesArray}
                        P_setArrayMppt={setSelectedArrayMppt}
                        P_setCountMod={setSelectedCountModules}

                        P_checkMppt={checkMppt}
                        P_selectModules={selectModules}
                        P_checkString={checkString}
                        P_unidades={unidades}
                        P_p_unidades={p_unidades}
                        P_azimute={azimute}
                        P_p_azimute={p_azimute}
                        P_inclincao={inclincao}
                        P_p_inclincao={p_inclincao}
                        P_potenciaM={potenciaM}
                        P_potenciaMppt={potenciaMppt}
                        P_correnteStr={correnteStr}
                        P_correnteMppt={correnteMppt}
                        P_tensao={tensao}
                        P_secaoN={secaoN}
                        P_secaoP={secaoP}
                        P_comprimento={comprimento}
                        P_tensaoPorce={tensaoPorce}
                        P_perdasCabos={PerdasCabos}
                        P_comprimentoFinal={comprimentoFinal}
                        P_comprimentoQuadro={comprimentoQuadro}
                        P_structureType={structureType}

                        P_setCheckMppt={setCheckMppt}
                        P_setSelectModules={setSelectModules}
                        P_setCheckString={setCheckString}
                        P_setUnidades={setUnidades}
                        P_setP_unidades={setP_unidades}
                        P_setAzimute={setAzimute}
                        P_setP_azimute={setP_azimute}
                        P_setInclincao={setInclincao}
                        P_setP_inclincao={setP_inclincao}
                        P_setPotenciaM={setPotenciaM}
                        P_setPotenciaMppt={setPotenciaMppt}
                        P_setCorrenteStr={setCorrenteStr}
                        P_setCorrenteMppt={setCorrenteMppt}
                        P_setTensao={setTensao}
                        P_setSecaoN={setSecaoN}
                        P_setSecaoP={setSecaoP}
                        P_setComprimento={setComprimento}
                        P_setTensaoPorce={setTensaoPorce}
                        P_setPerdasCabos={setPerdasCabos}
                        P_setComprimentoFinal={setComprimentoFinal}
                        P_setComprimentoQuadro={setComprimentoQuadro}
                        P_setStructureType={setStructureType}

                        //Variáveis comparar se os estados são iguais

                        //Variavel para definir informações do padrão de entrada




                        //Função para validação de formulário da configuração do sistema
                        handleTestDataConfigSystem={handleTestDataConfigSystem}

                        data={props.data}
                    />}
                    {activeStep === 1 && <EdiDescriptiveMemorialProject
                        data={props.data}
                        elementConfigSystem={elementConfigSystem}

                        inverters={selectedInvertersArray}
                        setInverters={setSelectedInvertersArray}
                        arrayMppt={selectedArrayMppt}
                        modules={selectedModulesArray}

                    />}

                </Stepper>


            </DialogContent>
            <DialogActions>
                {activeStep === 1 ? <Button
                    id='btn-back'
                    disabled={activeStep === 0}
                    onClick={() => {
                        handleReturnData();
                        handleBack();
                    }}
                >
                    Voltar
                </Button>
                    :
                    <Button
                        id=''
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Voltar
                    </Button>}
                {activeStep === 0 ? <Button
                    id='btn-next'
                    disabled={activeStep === steps.length - 1}
                    onClick={() => {
                        handleSaveData();
                        handleNext();
                    }}
                >
                    Próximo
                </Button>
                    :
                    (activeStep === 1 ?         
                    <>
                        <Button
                            id='btn-download'
                            variant='contained'
                            color='success'
                            onClick={e => save_memorial(e)}
                        >
                            SALVAR
                        </Button>
                        
                        <Button
                            id='btn-download'
                            variant='contained'
                            onClick={e => download_memorial(e)}
                        >
                            Download&nbsp;<CloudDownloadIcon />
                        </Button>
                        </>
                        :

                        <Button
                            id=''
                            disabled={activeStep === steps.length - 1}
                            onClick={handleNext}
                        >
                            Próximo
                        </Button>)}
                <Button
                    onClick={props.close}
                >
                    Fechar
                </Button>

            </DialogActions>

        </Dialog>

    )
}
export default Edit_System_Project_Dialog