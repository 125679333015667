
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import './style.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StartIcon from '@mui/icons-material/Start';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderSolProjetos from '../headeSolProjetos';
import Start_and_Follow from '../start_and_follow';


function ListSolarServiceSystem() {





  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchDemand = async () => {
      setLoading(true);
      try {
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
          //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            setDemand(data);
            setTotalPages(data[1]);
          })

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDemand();
  }, [currentPage, itemsPerPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação


  const formatDate = (date) => {
    if(date === '---'){
      return '---'
    }
    const dateFormated = new Date(date)
    return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
  };


  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#004e64';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#004e64';
      item.style.border = '2px solid #004e64';
    }

  })
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openClose = () => {
    setOpen(false);
  };

  const [openStart, setOpenStart] = React.useState(false);

  const handleClickOpenStart = () => {
    setOpenStart(true);
  };

  const openCloseStart = () => {
    setOpenStart(false);
  };




  const [handleData, setHandleData] = useState('');


  const searchDemand = () => {

    let value = document.querySelector('.search-demand').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`https://api.fortesol.com.br/api/demand_project_solar/search-system/?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setDemand(data);
          setTotalPages(data[1]);

        })
      return
    }
    else {
      fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
        //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((data) => {
          setDemand(data);
          setTotalPages(data[1]);
        })
    }
  }


  const [doc_proxy, setDoc_proxy] = useState(null)

 




  return (

    <Sidebar>
      <div id='list-project-solar-system'>
        <div className='project-solar'>
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
          />
       <HeaderSolProjetos/>
          <br />
          <div style={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', paddingLeft: '50px' }}>
              <div style={{ display: 'flex' }}>
                <input type="text" className='search-demand' placeholder='Pesquisar'
                onChange={(e) => {
            

                  if (e.target.value === '') {
                    fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
                      //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }).then((response) => response.json())
                      .then((data) => {
                        setDemand(data);
                        setTotalPages(data[1]);
                      })
                  }
                }} 
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchDemand();
                  }
                }
                }                
                />
                <button id='bt-search' onClick={searchDemand}>Pesquisar</button>
              </div>
              <button onClick={() => {

setLoading(true);
try {
  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system/?page=${currentPage}&limit=${itemsPerPage}`, {
    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
    .then((data) => {
      setDemand(data);
      setTotalPages(data[1]);
    })

} catch (error) {
  console.error(error);
} finally {
  setLoading(false);
}


}} id='bt-all' >Todos</button>
         
              <button onClick={() => {


                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Concluído'}&page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-concluded'>Concluídos</button>
              <button onClick={() => {

setLoading(true);
try {
  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
    .then((data) => {
      
      setDemand(data);
      setTotalPages(data[1]);
    })

} catch (error) {
  console.error(error);
} finally {
  setLoading(false);
}
}} id='bt-in-progress'> Solicitado</button>
   
            </div>
            <Link to='/services/new-project-solar'><button style={{ background: '#218380' }} > Novo Projeto</button></Link>
          </div>

          <div id='tableDemands' style={{
            padding: '0 0px 0 50px',
          }}>
            {

              (
                <>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Numero UC</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Nome</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">CPF/CNPJ</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Status</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data da Solicitação</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data de Conclusão</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Cliente Integrador</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Detalhes</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Iniciar Projeto</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {demand[0]?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.number_uc_demand}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.name_customers_of_customers ? item.name_customers_of_customers : item.corporate_reason_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.cpf_customers_of_customers ? item.cpf_customers_of_customers : item.cnpj_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12, gap: 1 }} >
                            <div style={{display:'flex',alignItems:'center', justifyContent:'center',flexDirection:'row',gap:5}}>

                              {item.status === 'Solicitado' ? 'Solicitado' : ''}
                              {item.status === 'Concluído' ? 'Concluído' : ''}
                              <Box
                                sx={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                  backgroundColor: item.status === 'Solicitado' ? '#ffc337' : '#228b22df',
                                  animation: 'pulse 1s infinite',
                                }}
                                />
                                </div>
                              {/*<span className='span_check' style={{fontWeight:1000}}>&#10003;</span>*/}
                            </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{formatDate(item.date_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.date_conclusion_demand === '---' ? '---' : formatDate(item.date_conclusion_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                              {
                             item.name ? item.name : item.corporate_reason
                             }
                               </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                           {/*   <button className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }}>Detalhes</button>
                                 */}
                              <IconButton sx={{margin:'0px!important'}} className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }
                              }><ArticleIcon sx={{ fontSize: 15 }}/></IconButton>
                              
                            </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                              <button id={`${item.id_demand}`} style={{margin:'0px!important'}} className='bt-start' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpenStart();
                                setHandleData(item);
                              }}>
                                  {
                                    item.data_descriptive_memorial ? 'Acompanhar' : 'Iniciar'
                                  }
                                </button>      
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Start_and_Follow open={openStart} close={openCloseStart} data={handleData} />
                    <Popup_demand
                      data={handleData}
                      open={open}
                      handleClose={openClose}
                    />

                  </Paper>

                  <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
                </>
              )}


          </div>


        </div>
      </div>
    </Sidebar>
  )
}

export default ListSolarServiceSystem
