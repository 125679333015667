
import React, { useEffect, useState } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import New_Edit_Inverter from '../new_edit_inverter/index.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Box,
    TextField,
    Button,
    IconButton,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogContentText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DropDialog from '../dropDialog/index.jsx';


function ListInverters(props) {
    const [data, setData] = useState('')
    const [demand, setDemand] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 4;


    useEffect(() => {
        const fetchInverters = async () => {
            setLoading(true);
            try {
                fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((response) => response.json())
                    .then((data) => {
                       
                        setDemand(data.result);
                        setTotalPages(data.total);
                    })

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchInverters();
    }, [currentPage, itemsPerPage]);
 

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // ... renderizar a lista de produtos e a paginação


    const formatDate = (date) => {
        const dateFormatted = new Date(date);
        return dateFormatted.toLocaleDateString('pt-BR');
    };


    document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
        item.style.backgroundColor = '#004e64';
        item.style.color = '#fff';
        if (item.ariaCurrent === 'true') {
            item.style.backgroundColor = '#ffffff';
            item.style.color = '#004e64';
            item.style.border = '2px solid #004e64';
        }

    })

    const [handleData, setHandleData] = useState('');


    const searchInverter = () => {

        let value = document.querySelector('.search-inverter').value;
        value = value.toLowerCase();
        value = value.replace('/', '-');
        if (value !== '') {
            fetch(`https://api.fortesol.com.br/api/inverters/search?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
                method: 'GET',
            }).then(response => response.json())
                .then(data => {
                    setDemand(data.result);
                    setTotalPages(data.total);
                    props.setWork_around('list-inverters')

                })
            return
        }
        else {
            fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                //fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((data) => {
                    setDemand(data.result);
                    setTotalPages(data.total);
                    props.setWork_around('list-inverters')

                })
        }
    }


    const [doc_proxy, setDoc_proxy] = useState(null)



    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const handleClickOpenNewInverter = () => {
        setData('')
        setTitle('new')
        setOpen(true)
    }
    const handleClickOpenEditInverter = (item) => {
        setData(item)
        setOpen(true)
        setTitle('edit')
    }
    const handleCloseInverters = () => {
        setOpen(false)
    }

    const [openDrop, setOpenDrop] = useState(false)
    const [titleDrop, setTitleDrop] = useState('')
    const [msgDrop, setMsgDrop] = useState('')
    const [idDrop, setIdDrop] = useState('')
    const handleClickOpenDrop = () => {
        setOpenDrop(true)
    }
    const handleCloseDrop = () => {
        setOpenDrop(false)
    }
    const deleteModule = () => {
        fetch(`https://api.fortesol.com.br/api/inverters/delete/${idDrop}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                

                fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((response) => response.json())
                    .then((data) => {
                        setDemand(data.result);
                        setTotalPages(data.total);
                    })

            })
        setOpenDrop(false)
        handleCloseDrop()
        props.setWork_around('list-inverters')

    }

    return (


            <Dialog
                open={props.open}
                onClose={props.handleClose}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='1200px'
                sx={{ ml: 5 }}
            >
                <DialogTitle id="alert-dialog-title">{"Lista de Inversores"}</DialogTitle>
                <DialogContent>
                    <div id='list-inverters'>
                        <div className='project-solar'>

                            <div style={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', paddingLeft: '0px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <input type="text" className='search-inverter' placeholder='Pesquisar'
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                searchInverter()
                                            }}
                                        }
                                            onChange={(e) => {


                                                if (e.target.value === '') {
                                                    fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                                                        //fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                                                        method: 'GET',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                    }).then((response) => response.json())
                                                        .then((data) => {
                                                            setDemand(data.result);
                                                            setTotalPages(data.total);
                                                            props.setWork_around('list-inverters')

                                                        })
                                                }
                                            }} />
                                        <button id='bt-search' onClick={searchInverter}>Pesquisar</button>
                                    </div>


                                </div>
                                <button onClick={handleClickOpenNewInverter} style={{ background: '#218380' }} > Cadatrar Inversor</button>
                            </div>

                            <div id='tableDemands' style={{
                                padding: '0 0px 0 0px',
                            }}>
                                {

                                    (
                                        <>
                                            <Paper>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>

                                                            
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Marca </TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Modelo</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Potência Fotovoltaica Máxima</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tensão Máxima Suportada</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Faixa de Tensão do MPPT</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Corrente Máxima de Entrada</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Corrente Máxima de Curto-Circuito</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">Tipo de Conexão com a Rede</TableCell>
                                                            <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">opções</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {demand?.map((item, index) => (
                                                            <TableRow key={index}>

                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.brand}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.model}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_photovoltaic_power}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_supported_voltage}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.mppt_voltage_range}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_input_current}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.maximum_short_circuit_current}</TableCell>
                                                                <TableCell sx={{ fontSize: 11, minWidth: 100 }} align="center">{item.type_of_network_connection}</TableCell>

                                                                <TableCell sx={{ fontSize: 11, minWidth: 100, display: 'flex' }} align="center">
                                                                    <IconButton onClick={() => handleClickOpenEditInverter(item)} title='EDITAR' style={{ color: '#218380' }}>
                                                                        <EditIcon />
                                                                    </IconButton>

                                                                    <IconButton title='DELETAR' style={{ color: 'red' }} onClick={() => {
                                                                        setTitleDrop('Deletar Inversor')
                                                                        setMsgDrop('Deseja realmente deletar o inversor?')
                                                                        setIdDrop(item.id_inverters)
                                                                        handleClickOpenDrop()


                                                                        /*
                                                                          fetch(`https://api.fortesol.com.br/api/inverters/delete/${item.id_modules}`, {
                                                                         method: 'DELETE',
                                                                         headers: {
                                                                           'Content-Type': 'application/json',
                                                                         },
                                                                       }).then((response) => response.json())
                                                                         .then((data) => {
                                                                           
                                                                           if (data.message === 'ok') {
                                                                             fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                                                                               //fetch(`https://api.fortesol.com.br/api/inverters/list-inverters?page=${currentPage}&limit=${itemsPerPage}`, {
                                                                               method: 'GET',
                                                                               headers: {
                                                                                 'Content-Type': 'application/json',
                                                                               },
                                                                             }).then((response) => response.json())
                                                                             .then((data) => {
                                                                               setDemand(data.result);
                                                                               setTotalPages(data.total);
                                                                             })
                                                                           }
                                                                         })
                                                                        */
                                                                    }
                                                                    }>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>


                                            </Paper>
                                            <Pagination style={{ marginTop: '10px' }}
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={(event, page) => handlePageChange(page)}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={1} // Número de páginas antes e depois das ellipses
                                                siblingCount={1} // Número de páginas antes e depois da página atual
                                            />
                                        </>
                                    )}


                            </div>


                        </div>
                    </div>
                    <DropDialog openDrop={openDrop} handleCloseDrop={handleCloseDrop} title={titleDrop} msg={msgDrop} delete={deleteModule} />

                    <New_Edit_Inverter open={open} handleClose={handleCloseInverters} title={title} data={data} setData={setData} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

    )
}

export default ListInverters
